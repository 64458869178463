import React, { useEffect, useState, useRef } from 'react';
import Modal from "./../Modal/Modal";
import './Navigation.scss';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import dummyProfile from './../../images/dummy-dp.png';
import { ProvideAuth, useAuth } from './../../services/use-auth';
import logo from './../../images/mlogo.png';
import Logout from './../Logout/Logout';
import Resource from '../../services/resource.service';

const Navigation = ({role}) => {

    const auth = useAuth();
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showOptionsBox, setShowOptionsBox] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [showNavItems, setShowNavItems] =useState(false);

    const optionRef = useRef();
    let navigate = useNavigate();

    let setRightEqualsZero = '';
    if(screenSize <= 1070){
        setRightEqualsZero ='select-option-right';
    }else{
        setRightEqualsZero = ''
    }
    
    const showDropdown = ()=> {
        setShowOptionsBox(!showOptionsBox);
    }

    const logout = () => {
        setShowNavItems(false);
        setShowLogoutModal(true);
        setShowOptionsBox(false);
    }

    const closeLogoutModal = () => {
        setShowNavItems(false);
        setShowLogoutModal(false);
    }

    const goToMyAccount = () => {
        setShowOptionsBox(!showOptionsBox);
        setShowNavItems(false);
        navigate(`/account`);
    }

    const myWallet = () => {
        setShowOptionsBox(!showOptionsBox);
        setShowNavItems(false);
        navigate(`/wallet`);
    }

    const goToSupport = () => {
        setShowOptionsBox(!showOptionsBox);
        setShowNavItems(false);
        navigate(`/support`);
    }

    const setDimension = () => {
        setScreenSize(window.innerWidth);
    }

    const handleClickOutside = (e) => {
        if(showOptionsBox) {
            if (optionRef.current && !optionRef.current.contains(e.target)) {
                setShowOptionsBox(false);
            }
        }
    }

    const handleHamburgerMenu = () => {
        setShowNavItems(true);
        console.log("clicked");
    }

    const closeModal = () => {
        setShowNavItems(false);
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return (() => {
            document.removeEventListener('click', handleClickOutside);
            console.log('unsubscribed')
        });
    },[showOptionsBox]);

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    return (
        <div className="navigation">
            <div className="navigation-bar">
                <div className='logo_container' onClick={()=>navigate('/')}>
                    <img className="logo" src={logo} alt="image" style={{width: '50px' }}/> 
                </div>
                <div className='nav-item'>
                    {
                        screenSize <= 500 ? 
                        <div className='hamburger' onClick={handleHamburgerMenu}>
                            <svg className="hamburger_menu"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6H20" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                <path d="M4 12H20" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                <path d="M4 18H20" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                            </svg>
                            {
                                showNavItems && 
                                <>
                                   <Modal closeModal={closeModal} mobileModal={true}>
                                        <div className='navItems_div'>
                                            <div className='navitems_closeBtn_container' onClick={closeModal} >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 6L6 18" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                    <path d="M6 6L18 18" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                            <div className='navItems'>
                                                <ul className='navigation_navItems'>
                                                    <h1 className="navigation_navItems_Title" >Navigation</h1>
                                                    {
                                                        (role === Resource.ROLES.ASSISTANT || role === Resource.ROLES.CARE_MANAGER )&&
                                                        <>
                                                            <li className="nav-items" onClick={closeModal}>
                                                                <NavLink to="/home" className={({ isActive }) => isActive ? " active-item" : ""} >Patients</NavLink>
                                                            </li>
                                                        </>
                                                    }
                                                    {
                                                        role === Resource.ROLES.DOCTOR &&
                                                        <>
                                                            <li className="nav-items" onClick={closeModal}>
                                                                <NavLink to="/home" className={({ isActive }) => isActive ? " active-item" : ""}  >
                                                                    Patients
                                                                </NavLink>
                                                            </li>
                                                            <li className="nav-items" onClick={closeModal}>
                                                                <NavLink to="/programs" className={({ isActive }) => isActive ? " active-item" : ""} >
                                                                    Programs
                                                                </NavLink>
                                                            </li>
                                                            <li className="nav-items" onClick={closeModal}>
                                                                <NavLink to="/assistant" className={({ isActive }) => isActive ? " active-item" : ""}>
                                                                    Assistant
                                                                </NavLink>
                                                            </li>
                                                        </>
                                                    }
                                                    {
                                                        (role === Resource.ROLES.ADMIN || role === Resource.ROLES.MR_SALES_MANAGER) &&
                                                        <>
                                                            <li className="nav-items" onClick={closeModal}>
                                                                <NavLink to="/home" className={({ isActive }) => isActive ? " active-item" : ""}  >
                                                                    Home
                                                                </NavLink>
                                                            </li>
                                                            <li className="nav-items" onClick={closeModal}>
                                                                <NavLink to="/programs" className={({ isActive }) => isActive ? " active-item" : ""} >
                                                                    Programs
                                                                </NavLink>
                                                            </li>
                                                            {/*<li className="nav-items" onClick={closeModal}>
                                                                <NavLink to="/chat" className={({ isActive }) => isActive ? " active-item" : ""}>
                                                                    Chat
                                                                </NavLink>
                                                            </li>*/}
                                                        </>
                                                    }
                                                </ul>
                                                <ul className='account_navItems'>
                                                    <h1 className="account_navItems_Title" >Account</h1>
                                                    {
                                                        (role === Resource.ROLES.ASSISTANT || role === Resource.ROLES.CARE_MANAGER || role === Resource.ROLES.DOCTOR || role === Resource.ROLES.ADMIN || role === Resource.ROLES.MR_SALES_MANAGER )&&
                                                        <>
                                                            <li onClick={goToMyAccount}>My Account</li>
                                                            {
                                                                role === Resource.ROLES.DOCTOR &&
                                                                    <li onClick={myWallet}>My Wallet</li>
                                                            }
                                                            <li onClick={goToSupport}>Support</li>
                                                            <li onClick={logout}>Logout</li>
                                                           
                                                        </>
                                                    }
                                                </ul>
                                            </div>
                                            <div className="app-version">App version: <strong>{auth.getAppVersion()}</strong></div>
                                        </div>
                                    </Modal>
                                  
                                </>
                            }
                            {
                            showLogoutModal && 
                            <Logout setShowLogoutModal={setShowLogoutModal} closeModal={closeLogoutModal} ></Logout>
                            }
                        </div>
                        :
                        <ul className="nav-links">
                            {
                                (role === Resource.ROLES.ASSISTANT || role === Resource.ROLES.CARE_MANAGER )&&
                                <>
                                    <li className="nav-items">
                                        <NavLink to="/home" className={({ isActive }) => isActive ? " active-item" : ""} >Patients</NavLink>
                                    </li>
                                </>
                            }
                            {
                                role === Resource.ROLES.DOCTOR &&
                                <>
                                    <li className="nav-items">
                                        <NavLink to="/home" className={({ isActive }) => isActive ? " active-item" : ""}  >
                                            Patients
                                        </NavLink>
                                    </li>
                                    <li className="nav-items">
                                        <NavLink to="/programs" className={({ isActive }) => isActive ? " active-item" : ""} >
                                            Programs
                                        </NavLink>
                                    </li>
                                    <li className="nav-items">
                                        <NavLink to="/assistant" className={({ isActive }) => isActive ? " active-item" : ""}>
                                            Assistant
                                        </NavLink>
                                    </li>
                                </>
                            }
                            {
                                (role === Resource.ROLES.ADMIN || role === Resource.ROLES.MR_SALES_MANAGER) &&
                                <>
                                    <li className="nav-items">
                                        <NavLink to="/home" className={({ isActive }) => isActive ? " active-item" : ""}  >
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className="nav-items">
                                        <NavLink to="/programs" className={({ isActive }) => isActive ? " active-item" : ""} >
                                            Programs
                                        </NavLink>
                                    </li>
                                    {/*<li className="nav-items">
                                        <NavLink to="/chat" className={({ isActive }) => isActive ? " active-item" : ""}>
                                            Chat
                                        </NavLink>
                                    </li>*/}
                                </>
                            }
                            {
                                (role === Resource.ROLES.ASSISTANT || role === Resource.ROLES.CARE_MANAGER || role === Resource.ROLES.DOCTOR || role === Resource.ROLES.ADMIN || role === Resource.ROLES.MR_SALES_MANAGER )&&
                                <>
                                    <li className="nav-items profilepic">
                                        <div className='profile' onClick={showDropdown}>
                                            <img src={dummyProfile} alt="dummy-image" />
                                        </div>
                                        {
                                            showOptionsBox && 
                                            <div className={`select-option ${setRightEqualsZero !=='' && setRightEqualsZero}`}>
                                                <ul className="options-list" ref={optionRef} >
                                                    <li onClick={goToMyAccount}>My Account</li>
                                                    {
                                                        role === Resource.ROLES.DOCTOR &&
                                                            <li onClick={myWallet}>My Wallet</li>
                                                    }
                                                    <li onClick={goToSupport}>Support</li>
                                                    <li onClick={logout}>Logout</li>
                                                </ul>
                                            </div> 
                                        }
                                        {
                                            showLogoutModal && 
                                            <Logout setShowLogoutModal={setShowLogoutModal} closeModal={closeLogoutModal} ></Logout>
                                        }
                                    </li>    
                                </>
                            }
                        </ul>
                    }
                </div>  
            </div>
        </div>
    )

}

export default Navigation;
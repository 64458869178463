import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => (
  <div className='notFoundComponent'>
    <h1 className='notFoundHeading'>404 - Not Found!</h1>
    <h5 className='notFoundDesc'>We can't seem to find the page you are looking for</h5>
    <Link to="/">Go to Home</Link>
  </div>
);

export default NotFound;
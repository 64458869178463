import React, { useEffect, useState,useRef } from "react";
import { useAuth } from "../../services/use-auth";
import { Navigate, useNavigate } from "react-router-dom";
import Resource from "../../services/resource.service";
import AssistantHome from "../../components/AssistantComponents/AssistantHome/AssistantHome";
import AdminHome from "../../components/AdminComponents/Home/Home";
import MRSalesManagerHome from "./../../components/MRSalesManagerComponents/Home/Home";
import CaremanagerHome from './../../components/CareManagerComponents/CaremanagerHome/CaremanagerHome'
import DoctorHome from './../../components/DoctorComponents/DoctorHome/DoctorHome'

const Home = () => {
    var auth = useAuth();
    var navigate = useNavigate();

    if(auth && auth.user) {
        if(!auth.user.role || auth.user.role == 'default') {
            return <Navigate to='/select-role' />
        }
        else if(auth.user.role == Resource.ROLES.ADMIN) {
            return <AdminHome />;
        }
        else if(auth.user.role == Resource.ROLES.DOCTOR) {
            return  <DoctorHome />;
        }
        else if(auth.user.role == Resource.ROLES.CARE_MANAGER) {
            return <CaremanagerHome />;
        }
        else if(auth.user.role == Resource.ROLES.ASSISTANT) {
            return <AssistantHome />;
        }
        else if(auth.user.role == Resource.ROLES.MR_SALES_MANAGER) {
            return <MRSalesManagerHome />;
        }
        else {
            return <Navigate to='/select-role' />
        }
    }
    else {
        return <Navigate to='/' />
    }
    
}    

export default Home;
import React from "react";
import { useNavigate } from "react-router-dom";
import './Programs.scss';

const Programs = ({programData}) => {

    let navigate = useNavigate();

    function goToProgramDetails(e) {
        let url = window.location.pathname;
        let currentProgram = e.target.textContent;
        navigate(`${url}/${currentProgram}`);
    }

    return (
        <>
           <div className="program_tab_content">
               <h1 className="program_tab_heading">Patient enrolled programs</h1>
               <div className="program_data">
                    {
                        programData.map((programRowData) => {
                           return <h1 className="programName" onClick={goToProgramDetails} >{programRowData.programName}</h1>
                        })
                    }
               </div>
           </div>
        </>
    )
}

export default Programs;
import React, { useState } from 'react';

import './Tabcontent.scss';

const TabContent = ({children}) => {
    return (
        <div className='tab_content'>
            {children}
        </div>
    )
}

export default TabContent;
import React from "react";
import './DocPatientDetails.scss';
import Header from './../../../components/Header/Header';
import UpcomingAppointmentWidget from "./../../UpcomingAppointmentWidget/UpcomingAppointmentWidget";
import PatientProfile from "../../PatientProfile/PatientProfile";
import PatientTabContainer from "./../../../components/PatientTabContainer/PatientTabContainer";
import { useEffect, useState } from "react";
import { useLocation, useParams,Link } from "react-router-dom";
import Resource from "../../../services/resource.service";
import { useAuth } from "../../../services/use-auth";
import { formatPatientData } from "../../../helpers/utils";

const DocPatientDetails = () => {

    let linkToUrl = '/';
    const [patientInfo, setPatientInfo] =  useState();
    const auth = useAuth();
    const params = useParams();
    let location = useLocation();
    const patientsData = location.state;

    useEffect(() => {
        var auth_id = params.id;
        if(!patientsData) {
            Resource.getUserById(Resource.ROLES.PATIENT, auth_id).then((response) => {
                if(response.success)
                    setPatientInfo(formatPatientData(response.data));
            });
        }
        else {
            setPatientInfo(patientsData);
        }

    }, [auth.user]);

    let DoctortabLabels = [
        'Timeline','Information','Progress'
    ];

    return (
        <div className="doctor_patient_details_component">
                <div className='patient_details_container_header'>
                    <Link to={linkToUrl}>
                        <svg className='back-image' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 16H23" stroke="#5F6368" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M9 16L15 22" stroke="#5F6368" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M9 16L15 10" stroke="#5F6368" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        </svg>
                    </Link>
                </div>
                <div className="patient_details">
                    <div className="patient_profile">
                        {patientInfo && <PatientProfile patientInfo={patientInfo}/> }
                    </div>
                    <div className="patient_appointments">
                        <UpcomingAppointmentWidget />
                    </div>
                </div>
                <div className="patient_details_tab">
                    <PatientTabContainer tabLabels={DoctortabLabels}/>
                </div>
        </div>
    )
}

export default DocPatientDetails;
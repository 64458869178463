import React, { useState, useEffect, useContext, createContext } from "react";
import authenticationService from './authentication.service';
import axios from 'axios';
import authHeader, { apiHeader } from '../helpers/auth-header';
import ReactDOM from 'react-dom';
import App from './../App';
import { Link } from 'react-router-dom';
import BusyLoading from './../helpers/loading';

var apiHost = authenticationService.apiHost;
const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('currentUser')));
    const MAX_RETRY_REFRESH = 20;
    var retryRefresh = 0;
    var refreshTimer;

    function startCronJob() {

        return setInterval(() => {
            let currentTime = parseInt(new Date().getTime() / 1000, 10);
            let localUser = JSON.parse(localStorage.getItem("currentUser"));
            if (localUser && localUser.access_token_expiry) {
                let accessTokenExpiryTime = localUser.access_token_expiry;

                let timeToRefresh = accessTokenExpiryTime - currentTime;

                if (timeToRefresh <= 0 || timeToRefresh < (50 - Math.pow(2, retryRefresh))) {
                    renewAccessToken().then((u) => {
                        console.log(u);
                    });
                }
                console.log("Time to refresh:", timeToRefresh, "\nRetried refresh:", retryRefresh);
            }
        }, 5000);
    }

    const signinUsingOtp = (phone_number, otp, otp_session_id) => {
        BusyLoading.show();
        return authenticationService.verifyOTP(phone_number, otp, otp_session_id)
            .then((user) => {
                BusyLoading.hide();
                if (user) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('currentUserRefreshToken', user.refresh_token);
                    localStorage.setItem('currentUserAccessToken', user.access_token);
                }
                checkVersionUpdate({'app_info': {'current_version': '1.0.0'}}, user['app_info']);
                setUser(user);
                return user;
            });
    };

    const signout = () => {
        // remove user from local storage to log user out
        clearInterval(refreshTimer)
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUserRefreshToken');
        localStorage.removeItem('currentUserAccessToken');
        setUser(false);
        retryRefresh = 0;
        return (
            ()=>{
                return <Link to="/"></Link>
            }
        )
    };

    const checkVersionUpdate = (local_app_info, new_app_info) => {
        
        function versionCompare(a, b) {
            function prep(t) {
                return ("" + t)
                //treat non-numerical characters as lower version
                //replacing them with a negative number based on charcode of first character
                .replace(/[^0-9\.]+/g, function(c){return "." + ((c = c.replace(/[\W_]+/, "")) ? c.toLowerCase().charCodeAt(0) - 65536 : "") + "."})
                //remove trailing "." and "0" if followed by non-numerical characters (1.0.0b);
                .replace(/(?:\.0+)*(\.-[0-9]+)(\.[0-9]+)?\.*$/g, "$1$2")
                .split('.');
            }
            a = prep(a);
            b = prep(b);
            for (var i = 0; i < Math.max(a.length, b.length); i++) {
                //convert to integer the most efficient way
                a[i] = ~~a[i];
                b[i] = ~~b[i];
                if (a[i] > b[i])
                    return 1;
                else if (a[i] < b[i])
                    return -1;
            }
            return 0;
        }

        let localCurrentVersion = (local_app_info && local_app_info['current_version']) || '1.0.0';
        let localMinVersion = (local_app_info && local_app_info['min_version']) || '1.0.0';

        let newCurrentVersion = (new_app_info && new_app_info['current_version']) || '1.0.0';
        let newMinVersion = (new_app_info && new_app_info['min_version']) || '1.0.0';

        if(versionCompare(newCurrentVersion, localCurrentVersion) == 1) {
            if(navigator.serviceWorker.controller)
                navigator.serviceWorker.controller.postMessage({ type: 'SW_RELOAD', version: newCurrentVersion });
        }
    };

    const renewAccessToken = () => {
        let headers = authHeader(true);
        if (headers) {
            headers['Content-Type'] = 'application/json';
        }
        else {
            signout();
        }
        
        var role;
        if(user && user.role) {
            role = user.role;
        }
        else {
            let localUser = JSON.parse(localStorage.getItem('currentUser'));
            if(localUser && localUser.role)
                role = localUser.role;
            else
                role = 'doctor';
        }


        return axios.post(apiHost + '/v1/refresh', { 'role': role }, { headers: headers })
            .then((response) => {
                let data = response.data;
                if (data.access_token) {
                    let updatedConfig = data;
                    localStorage.setItem('currentUserAccessToken', data.access_token);
                    let localUser = JSON.parse(localStorage.getItem('currentUser'));

                    checkVersionUpdate(localUser['app_info'], updatedConfig['app_info']);
                    
                    updatedConfig.refresh_token = localUser.refresh_token;
                    updatedConfig.refresh_token_expiry = localUser.refresh_token_expiry;
                    localStorage.setItem('currentUser', JSON.stringify(updatedConfig));
                    retryRefresh = 0;
                    setUser(updatedConfig);
                    return updatedConfig;
                }
                else {
                    if (retryRefresh < MAX_RETRY_REFRESH) {
                        retryRefresh += 1;
                    }
                    else {
                        signout();
                    }

                }
            }, (error) => {
                if ([401, 403].indexOf(error.response.status) !== -1) {
                    signout();
                }
                else if (retryRefresh < MAX_RETRY_REFRESH) {
                    retryRefresh += 1;
                }
                else {
                    signout();
                }
            });
    };

    const invalidate = () => {
        console.log("invalidate");
        let user_config = JSON.parse(localStorage.getItem('currentUser'));
        setUser(user_config);
    };

    const getConfig = (props) => {
        if (user) {
            let role = (user.role && user.role != 'default') ? user.role : props.role;
            if (role) {
                BusyLoading.show();
                return axios.get(apiHost + '/v1/get_config?app_name=' + role, { headers: apiHeader() })
                    .then((response) => {
                        BusyLoading.hide();
                        let user_config = response.data;
                        if (user_config && user_config.access_token) {
                            localStorage.setItem('currentUserAccessToken', user_config.access_token)
                            let localUser = JSON.parse(localStorage.getItem('currentUser'));

                            checkVersionUpdate(localUser['app_info'], user_config['app_info']);

                            let refreshToken = localUser.refresh_token;
                            let refreshTokenExpiry = localUser.refresh_token_expiry;
                            user_config.refresh_token = refreshToken;
                            user_config.refresh_token_expiry = refreshTokenExpiry;
                            localStorage.setItem('currentUser', JSON.stringify(user_config));
                            setUser(user_config);
                            return user_config;
                        }
                        else {
                            return JSON.parse(localStorage.getItem('currentUser'));
                        }
                    });
            }
        }
        else {
            return null;
        }
    };

    const getAppVersion = () => {
        return user.app_info.current_version || '1.0.0';
    }

    useEffect(() => {
        refreshTimer = startCronJob();
        return () => clearInterval(refreshTimer);
    }, []);

    return {
        user,
        signinUsingOtp,
        signout,
        renewAccessToken,
        getConfig,
        invalidate,
        getAppVersion
    };
}
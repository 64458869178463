import React from 'react';
import Header from '../../../components/Header/Header';
import ModulesBlock from '../../../components/ModulesBlock/ModulesBlock';
import Button from '../../../components/Button/Button';
import { useLocation } from 'react-router-dom';
import './AddModule.scss';

const AddModule = () => {
    let headerTitle = "Add Module";
    let buttonText = "Save";
    let showButton = true;

    let location = useLocation();
    const {pageData ,backTo} = location.state;
    console.log(pageData)

    return (
        <>
            <Header 
                headerTitle={headerTitle}
                linkToUrl={backTo}
                backgroundColor={"headerBackground"}
                pageData={pageData} />
            <ModulesBlock ModuleData={pageData}/>
        </>
    )
}

export default AddModule;
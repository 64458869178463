import React from 'react';
import './MeasurementsAndVitals.scss';

const MeasurementsAndVitals = () => {

    return (
        <div className="Measurements_and_vitals_Container">
            <div className='MeasurementsAndVitals'>MeasurementsAndVitals</div>
        </div>
    )

}

export default MeasurementsAndVitals;
import React from 'react';
import './Tasks.scss';

const Tasks = () => {

    return (
        <div className="tasks_Container">
            <div className='Tasks'>Tasks</div>
        </div>
    )

}

export default Tasks;
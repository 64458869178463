import {useState} from 'react';
import './AssistantContent.scss';
import Toast from './../../../Toaster/Toast';
import Resource from './../../../../services/resource.service';

const AssistantContent = ({assistantData, index}) => {


    const [renderItem, toggleRenderItem] = useState(true);

    console.log(assistantData,'data in assistant content');

    // assistantData.sort((a, b) => (a.status == 'revoked' ? 1 : (b.status == 'revoked' ? -1 : (a.status == 'pending' ? -1 : 1))));

    function cancelSchedule(e) {
        console.log(e.target.value);
    }

        function approveAssistant (e) {
            let id = e.target.id.replace('approve', '');
            id = parseInt(id, 10);
            let index = e.target.getAttribute('index');
            let data = {
                auth_id: id,
                type: Resource.ROLES.ASSISTANT,
                parent_id: e.target.getAttribute('parent_id'),
                status: 'approved'
            }
            
            Resource.updateStatus(data).then((response) => {
                if(response.success) {
                    Toast.success("Success: Approved Assistant access successfully!!");
                    assistantData.status = data.status;
                    // assistantData.sort((a, b) => (a.status == 'revoked' ? 1 : (b.status == 'revoked' ? -1 : (a.status == 'pending' ? -1 : 1))));
                    toggleRenderItem(!renderItem);
                }
                else {
                    Toast.error("Error updating the status");
                }
            });
        }


        function revokeAssistant (e) {
            let id = e.target.id.replace('revoke', '');
            id = parseInt(id, 10);
            let index = e.target.getAttribute('index');
            let data = {
                auth_id: id,
                type: Resource.ROLES.ASSISTANT,
                parent_id: e.target.getAttribute('parent_id'),
                status: 'revoked'
            }
            
            Resource.updateStatus(data).then((response) => {
                if(response.success) {
                    Toast.success("Success: Revoked Assistant access successfully!!");
                    assistantData.status = data.status;
                    // assistantData.sort((a, b) => (a.status == 'revoked' ? 1 : (b.status == 'revoked' ? -1 : (a.status == 'pending' ? -1 : 1))));
                    toggleRenderItem(!renderItem);
                }
                else {
                    Toast.error("Error updating the status");
                }
            });
        }


    return (
        <div className='AssistantCard' >
            <div className='cardHeader'>
                <h3 className='Title'>{assistantData.first_name + ' ' + assistantData.last_name}</h3>
                <div className={`field-value ${assistantData.status}`}>{assistantData.status.slice(0,1).toUpperCase() + assistantData.status.slice(1)}</div>
                {/* <span className={`activation_status ${assistantData.status === 'approved'? ' status_color ' : ' '}`}>{assistantData.status}</span> */}
            </div>
            <div className='AssistantDetails'>
                <div className='AssistantDetails_content_box'>
                    <h5 className='AssistantDetails_content_title'>Contact</h5>
                    <h5 className='AssistantDetails_content_value'>{assistantData.contact_number || assistantData.username}</h5>
                </div>
                <div className='AssistantDetails_content_box'>
                    <h5 className='AssistantDetails_content_title'>Clinic Name</h5>
                    <h5 className='AssistantDetails_content_value'>{assistantData.clinic_name ? assistantData.clinic_name : '---'}</h5>
                </div>
                <div className='AssistantDetails_content_box'>
                    <h5 className='AssistantDetails_content_title'>Email</h5>
                    <h5 className='AssistantDetails_content_value'>{assistantData.Assistant_Email ? assistantData.Assistant_Email : '---'}</h5>
                </div>
            </div>
            <div className='btn-wrap'>
            {
                assistantData.status === "approved" || assistantData.status === "pending" ?
                    <button className='revoke-btn' id={'revoke' + assistantData.id} parent_id={assistantData.doctor_id} index={index} onClick={revokeAssistant}>Revoke access</button> 
                    :''
            }
            {
                assistantData.status !== "approved" &&  <button className='approve-btn' id={'approve' + assistantData.id} parent_id={assistantData.doctor_id} index={index} onClick={approveAssistant} >Approve access</button>
            }
            </div>
        </div>
    );

};
export default AssistantContent;


import React from 'react';
import { useState, useEffect } from 'react';
import './ThankYouMessage.scss';

const ThankYouMessage = ({ hideSuccessMessage }) => {
    const [counter,setCounter] = useState(5);
    const [timerId,setTimerId] = useState(0);

    const decreaseTimer = () => {
        setCounter(counter => counter-1);
    }

    useEffect(() => {
        let id= setInterval(decreaseTimer,1000);
        setTimerId(id)
    },[]);

    useEffect(()=>{
        if(counter === 0){
            clearInterval(timerId);
            hideSuccessMessage(false);
        }
    },[counter]);

    return (
        <div className='successMsgDiv'>
            <h2 className="successMsg">Thank you for Registering!</h2>
            <p>Ask patient to register on Whatsapp for the token number.<br></br>
                <span>Redirecting in <span className="counter">{counter}...</span> </span>
            </p>
        </div>
    )
}

export default ThankYouMessage;
import React from 'react';

const Chat = () => {

    return (
        <div className="chat">
            <h1>I am chat page</h1>
        </div>
    );

};

export default Chat;
import './Toast.scss';

function toaster() {

    function loading(ellipsis) {
        if(ellipsis === true) {
            return '<span class="dots"><span>.</span><span>.</span><span>.</span></span>';
        }
        else if(ellipsis === false) {
            return '<span><span>.</span><span>.</span><span>.</span></span>';
        }
        else {
            return '';
        }
    }

    function successElement(msg, ellipsis) {
        if(!msg)
            msg = "Request submitted successfully!!";
    
        return '<div class="success-msg">\
                    <div class="msg">' + msg + loading(ellipsis) + '</div>\
                </div>';
    };
    
    function errorElement(msg, ellipsis) {
        if(!msg)
            msg = "Oops! Error. Please try again.";
    
        return '<div class="error-msg" >\
                    <div class="msg">' + msg + loading(ellipsis) + '</div>\
                </div>';
    };
    
    function warningElement(msg, ellipsis) {
        if(!msg)
            msg = "Some error occurred but no need to worry.";
    
        return '<div class="warning-msg" >\
                    <div class="msg">' + msg + loading(ellipsis) + '</div>\
                </div>';
    };
    
    function toastTimer(delay) {
        if(!delay)
            delay = 3000;
    
        setTimeout(function(){
            document.getElementById("response").innerHTML = '';
        }, delay);
    };
    
    const success = (message, ellipsis) => {
        document.getElementById("response").innerHTML = successElement(message, ellipsis);
        toastTimer();
    }
    
    const error = (message, ellipsis) => {
        document.getElementById("response").innerHTML = errorElement(message, ellipsis);
        toastTimer();
    }
    
    const warning = (message, ellipsis) => {
        document.getElementById("response").innerHTML = warningElement(message, ellipsis);
        toastTimer();
    }

    return {
        success,
        error,
        warning
    }
}

const Toast = toaster()
export default Toast;


import { useNavigate } from "react-router-dom";
import { useEffect, useState,useRef } from "react";
import Resource from "../../../services/resource.service";
import { useAuth } from "../../../services/use-auth";
import Button from "./../../Button/Button";
import Modal from "../../Modal/Modal";
import "./MyWallet.scss";
import BankForm from "./BankForm/BankForm";
import Toast from "../../Toaster/Toast";

const Wallet = () => {
  const [walletInfo, setWalletInfo] = useState({
    paid_patients: 0,
    unpaid_patients: 0,
    total_earnings: 0,
    withdrew_amount: 0,
  });
  const [showWidthDrawModal, setShowWidthDrawModal] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] =useState("upiTransfer");
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const auth = useAuth();
  const navigate = useNavigate();
  const withdrawAmountRef = useRef();
  const [amountToWithdraw, setAmountToWithdraw] = useState(0);
  const handleWithdraw = () => {
    setShowWidthDrawModal(true);
  };

  const closeModal = () => {
    setShowWidthDrawModal(false);
    setShowBankForm(false);
  };

  const handleRadioButton = (e) => {
    let currentValue = e.target.value;
    setSelectedPaymentOption(currentValue);
  };

  const handleContinueBtnClick = () => {
    let withdrawAmountInput = withdrawAmountRef.current;
    let amount = withdrawAmountInput.value;
    let withdrawAmountError = document.querySelector('#withdrawAmountError');

    let amountEmptyError = 'This field cannot be empty';
    let totalEarning = parseInt(walletInfo.total_earnings);
    let withdrewAmount = parseInt(walletInfo.withdrew_amount);
    let amountThatCanBeWithdrawn = totalEarning - withdrewAmount;
    let withdrawExceedError = 'The amount can not exceed your wallet balance (= Rs.' + amountThatCanBeWithdrawn + ')';

    if(amount !== ''){
      if(amount <= amountThatCanBeWithdrawn){
        withdrawAmountError.style.display = "none";
        withdrawAmountInput.classList.remove("invalid");
        setAmountToWithdraw(amount);
        setShowBankForm(true);
      }else{
        withdrawAmountError.style.display = "inline";
        withdrawAmountInput.classList.add("invalid");
        withdrawAmountError.innerHTML = withdrawExceedError;
      }
    }
    else{
      withdrawAmountError.style.display = "inline";
      withdrawAmountInput.classList.add("invalid");
      withdrawAmountError.innerHTML = amountEmptyError;
    }
  };

  const handleWithdrawAmount = (e) =>{
    e.preventDefault();
    let Error = "This field cannot be empty";
    let upiIdTransfer = document.getElementById('upiIdTransfer');
    let upiIdTransferError = document.getElementById('upiIdTransferError');
    
    if(upiIdTransfer.value !== ''){
      upiIdTransfer.classList.remove('invalid');
      upiIdTransferError.style.display = "none";
      Resource.requestWithdraw({
        amount: amountToWithdraw,
        type: 'withdrawal',
        mode: 'upi',
        upi_id: upiIdTransfer.value
      }).then((response) => {
        if(response.success) {
            Toast.success("SUCCESS: Your request to withdraw INR " + amountToWithdraw + " has been received!!" );
        }
        else {
            Toast.error("ERROR: Server error. Please try again in sometime." );
        }
        setShowWidthDrawModal(false);
        setShowBankForm(false);
    });
      
    }else{
      upiIdTransferError.style.display = "inline";
      upiIdTransfer.classList.add("invalid");
      upiIdTransferError.innerHTML = Error;  
    }
   
  }

  const setDimension = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    Resource.getWallet("v2").then((response) => {
      if (response.success) {
        setWalletInfo(response.data);
      }
    });
  }, [auth.user, showWidthDrawModal]);

  return (
    <div>
      <div className="my-wallet container">
        <div className="wallet-container">
          <div className="topic">
            <Button
              buttonType={"icon_btn"}
              onclick={() => {
                navigate("/");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-arrow-narrow-left"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#070708"
                fill="none"
                strokeLinecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="5" y1="12" x2="9" y2="16" />
                <line x1="5" y1="12" x2="9" y2="8" />
              </svg>
            </Button>
            <h1 className="title">Wallet</h1>
          </div>
          <div className="wallet-card-container">
            <div className="wallet-card ">
              <h1 className="wallet-card-heading">
                Rs {walletInfo.total_earnings}
              </h1>
              <p className="wallet-card-caption">Your total earnings</p>
            </div>
            <div className="wallet-card">
              <h1 className="wallet-card-heading ">Rs {walletInfo.withdrew_amount}</h1>
              <p className="wallet-card-caption">Withdrawn</p>
            </div>
            <div className=" wallet-card  margin-top-40">
              <h1 className="wallet-card-heading ">
                {walletInfo.paid_patients + walletInfo.unpaid_patients}
              </h1>
              <p className="wallet-card-caption ">
                Number of patients added
              </p>
            </div>
            <div className="wallet-card margin-top-40">
              <h1 className="wallet-card-heading ">
                {walletInfo.paid_patients}
              </h1>
              <p className="wallet-card-caption">Paid patients</p>
            </div>
          </div>
          <div className="btn-holder">
            {
              screenSize > 480 ?
              <Button
                text={"Withdraw"}
                buttonType={"primary_btn"}
                onclick={handleWithdraw}
              />
              :
              ''
            }
          </div>
          {showWidthDrawModal ? (
            <Modal
              closeModal={closeModal}
              {...(screenSize <= 480 ? {position:'bottom'} : "")}
            >
              <div className="withdrawModalContainer">
                {showBankForm ? (
                  <>
                    <div className="header">
                          <Button
                            buttonType={"icon_btn"}
                            onclick={() => {
                              setShowBankForm(false);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              classname="icon icon-tabler icon-tabler-arrow-narrow-left"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#070708"
                              fill="none"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <line x1="5" y1="12" x2="19" y2="12" />
                              <line x1="5" y1="12" x2="9" y2="16" />
                              <line x1="5" y1="12" x2="9" y2="8" />
                            </svg>
                          </Button>
                          <Button buttonType={"icon_btn"} onclick={closeModal}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              classname="icon icon-tabler icon-tabler-x"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#000000"
                              fill="none"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <line x1="18" y1="6" x2="6" y2="18" />
                              <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                          </Button>
                        </div>
                    {
                      selectedPaymentOption === "upiTransfer" ? (
                      <>
                        <div className="form-element">
                          <label>UPI ID</label>
                          <input
                            type="text"
                            id="upiIdTransfer"
                            className="inputField"
                            placeholder="Enter UPI ID"
                            required
                          />
                          <span
                            className="field-error"
                            id="upiIdTransferError"
                          ></span>
                        </div>
                        <div className="continue_btn">
                          <Button
                            buttonType={"primary_btn"}
                            text={"Withdraw Amount"}
                            onclick={handleWithdrawAmount}
                          />
                        </div>
                      </>
                    ) : (
                      <div className='bank_form'>
                        <BankForm amount={withdrawAmountRef.current.value} closeModal={closeModal}/>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="header">
                         <div className='collect_payment_title'>Collect Payment</div>
                          <Button buttonType={"icon_btn"} onclick={closeModal}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              classname="icon icon-tabler icon-tabler-x"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#000000"
                              fill="none"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <line x1="18" y1="6" x2="6" y2="18" />
                              <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                          </Button>
                        </div>
                    <div className="form-element">
                      <label>Withdraw Amount</label>
                      <input
                        ref={withdrawAmountRef}
                        type="number"
                        id="withdrawAmount"
                        className="inputField"
                        placeholder="Enter amount to withdraw"
                        required
                      />
                      <span className="field-error" id="withdrawAmountError"></span>
                    </div>
                    <div className="form-element">
                      <h3>Choose Payment Type</h3>
                      <div className="radioButtonGroup">
                        <label>
                          <input
                            type="radio"
                            id="upiTransfer"
                            name="paymentOption"
                            value="upiTransfer"
                            onChange={(e) => handleRadioButton(e)}
                            checked={selectedPaymentOption === "upiTransfer"}
                          />
                          UPI Transfer
                        </label>
                      </div>
                      <div className="radioButtonGroup">
                        <label>
                          <input
                            type="radio"
                            id="neftTransfer"
                            name="paymentOption"
                            value="neftTransfer"
                            onChange={(e) => handleRadioButton(e)}
                            checked={selectedPaymentOption === "neftTransfer"}
                          />
                          NEFT Transfer
                        </label>
                      </div>
                    </div>
                    <div className="continue_btn">
                      <Button
                        buttonType={"primary_btn"}
                        text={"Continue"}
                        onclick={handleContinueBtnClick}
                      />
                    </div>
                  </>
                )}
              </div>
            </Modal>
          ) : (
            ""
          )}
          {
            screenSize <= 480 ? 
            <div className="bottomElement">
              <Button
                text={"Withdraw"}
                buttonType={"primary_btn"}
                onclick={handleWithdraw}
              />
            </div>
            :
            ''
          }
        </div>
      </div>
    </div>
  );
};

export default Wallet;

import { useLocation } from "react-router-dom";
import { useState } from "react";
import Resource from "../../services/resource.service";
import { useAuth } from "../../services/use-auth";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import Toast from "../Toaster/Toast";
import useHandleEnter from "../../hooks/useHandleEnter";
import './Upload.scss';

const MedicalCertificateUpload = ({assigned_to, callback, fileRole}) => {

    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const auth = useAuth();
    const location = useLocation();
    const {handleKeyDown} = useHandleEnter();
    const role = location.state?.role || fileRole;
    
    console.log(role)
    function fileTypeSelector(e) {
        setFile(e.target.files[0]);
        setShowModal(true);
    }
    function handleFileInput() {
        let fileType = document.getElementById('f-type');
        let medicalCertificateUploadInput = document.getElementById("medicalCertificateUploadInput")
        if(!fileType.value) {
            fileType.style.border = "1px solid red";
        }
        else {
            fileType.style.border = "1px solid #000";
            let uploadObj = {
                type: 'medical_certificate',
                assigned_to: assigned_to,
                file_name: fileType.value.replaceAll(' ', '_'),
                file_extension: (file.name).split('.').pop()
            }

            if(auth.user && (!auth.user.role || auth.user.role == 'default') && role)
                uploadObj.new_role = role;

            Resource.mediaUpload(uploadObj, file).then((resp) => {
                if(resp.success) {
                    if(callback) {
                        callback(resp.data);
                    }
                    setShowModal(false);
                    medicalCertificateUploadInput.focus();
                }
                else
                {
                    Toast.error(resp.error);
                }
            });
        }
    }

    return(
        <>
            <input type="file" id="medicalCertificateUploadInput" className="inputField" accept="image/*, .pdf, .doc, .docx"  onChange={fileTypeSelector} ></input>
            {
                showModal ? 
                <Modal closeModal={() => {setShowModal(false)}}>
                    <div className='popup-container'>
                        <label>{file.name}</label>
                        <div className="form-element">
                            <select id="f-type" className="inputField" required>
                                <option value="">-- Select File Type --</option>
                                <option value="license">Practice license</option>
                                <option value="certificate">Medical Degree / Certificate</option>
                            </select>
                        </div>
                        <Button buttonType={'primary_btn'} text={'Upload'} onclick={handleFileInput}/>
                    </div>
                </Modal> : <></> 
            } 
        </>
    )
}

export default MedicalCertificateUpload;
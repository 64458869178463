import React, { useMemo, useContext } from "react";
import Input from "./Input";
import useOTP from "./Hooks/useOTP";
import './../GetOtp.scss';
import { handleContext } from "../GetOtp";
import useHandleEnter from "../../../hooks/useHandleEnter";

const OtpInput = ({
  otplength,
  disabled,
  autoFocus,
  value = "",
  onChange,
  otpType,
  secure,
  
  inputStyles,
  style,
  placeholder,
}) => {
  const {
    activeInput,
    getOtpValue,
    handleOnChange,
    handleOnKeyDown,
    handelOnInput,
    handleOnPaste,
    onInputFocus,
  } = useOTP({
    autoFocus,
    value,
    otpType,
    onChange,
    otplength,
  });

  const handleMethod = useContext(handleContext);
  const { handleKeyDown } = useHandleEnter();
  let lastIndex = otplength - 1;

  const handleForLastIndex = (e) => {
    handleKeyDown(e,handleMethod)
  }

  const renderInputs = useMemo(() => {
    const otp = getOtpValue();
    const inputs = [];

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < otplength; index++) {
      inputs.push(
        <Input
          inputStyles={inputStyles}
          key={index}
          focus={activeInput === index}
          value={otp[index]}
          onChange={handleOnChange}
          onKeyDown={index === lastIndex ? handleForLastIndex : handleOnKeyDown }
          onInput={handelOnInput}
          onPaste={handleOnPaste}
          onInputFocus={onInputFocus}
          index={index}
          // onBlur={() => setActiveInput(-1)}
          disabled={disabled}
          autoFocus={autoFocus}
          secure={secure}
          data-testid="input"
          otpType={otpType}
          placeholder={placeholder && placeholder[index]}
        />
      );
    }

    return inputs;
  }, [
    getOtpValue,
    otplength,
    inputStyles,
    activeInput,
    handleOnChange,
    handleOnKeyDown,
    handelOnInput,
    handleOnPaste,
    onInputFocus,
    disabled,
    autoFocus,
    secure,
    otpType,
    placeholder,
  ]);

  // // useEffect(()=>{
  // //   console.log(otplength,'otp length')
  // // })

  return (
    <div
      style={{
        display: "flex",
        ...style,
      }}
      className={`otp_fields`}
      data-testid="otp-input-root"
    >
      {renderInputs}
    </div>
  );
};

export default OtpInput;
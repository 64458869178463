import './ClinicAssociateCard.scss';

const ClinicAssociateCard = ({data,handleClick}) =>{
    return (
        <div className="clinicAssociateCard" onClick={handleClick}>
            <div className='clinicAssociateCardDetails'>
                <div className='rowDetails1'>
                    <h1 className='patientName'>{data.first_name} {data.last_name} </h1>
                </div>
                <div className='rowDetails2'>
                    <div className='data_content'>
                        <h5 className='associate_key'>Phone Number</h5>
                        <h2 className='associate_value'>{data.contact_number}</h2>
                    </div>
                    <div className='data_content'>
                        <h5 className='associate_key'>Schedule</h5>
                        <h2 className='associate_value'>{data.visitingSchedule}</h2>
                    </div>
                    {
                        data.practice &&
                        <div className='data_content'>
                           <h5 className='associate_key'>Practice</h5>
                           <h2 className='associate_value'>{data.practice}</h2>
                        </div> 
                    } 
                    
                </div>
            </div>
        </div>
    )

}
export default ClinicAssociateCard;
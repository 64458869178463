import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProgramCard from './../../components/ProgramCard/ProgramCard';
import addIcon from './../../images/add.png';
import './../Programs/Programs.scss';
import Resource from '../../services/resource.service';
import ProgramDetails from '../../components/CareManagerComponents/ProgramDetails/ProgramDetails';
import { useAuth } from '../../services/use-auth';

const Programs = () => {

    const [programs, setPrograms] = useState([]);
    const auth = useAuth();

    function fetchedCompleted(programs) {
        setPrograms(programs);
    }

    useEffect(()=> {
        Resource.getPrograms().then((response) => {
            if(response.success) {
                fetchedCompleted(response.data);
            }
            else {
                console.log("Error getting the list of programs");
            }
        });
    },[]);

    return (
        <div className="program">
            <div className='programHeader'>
                <h1 className='programHeading'>
                    Program Cohort
                </h1>
                {
                auth.user && auth.user.role == Resource.ROLES.ADMIN &&
                <Link to='/programs/addProgram' className="addProgramButton" >Add Program</Link>
                }
            </div>
            <div className='programsCardContainer'>
            {
                programs.map((programData, index) => {
                    return <ProgramCard key={programData.id} programData={programData} />
                })
            }
            </div>
        </div>
    );

};
export default Programs;
import React, { useState, useEffect, useRef } from 'react';
import Resource from '../../../services/resource.service';
import Toast from '../../Toaster/Toast';
import ProfilePicUpload from '../../Upload/ProfilePicUpload';
import './DoctorEditform.scss';
import Button from '../../Button/Button';

const DoctorEditForm = ({rowDetail,showVisitingSchedule}) => {
    
    const [practices, setPractices] = useState([]);
    const [schedules,setSchedules] = useState([]);

    const dayRef = useRef();
    const dayErrorRef = useRef();
    const startTimeRef = useRef();
    const startTimeErrorRef = useRef();
    const startTimePeriodRef = useRef();
    const endTimeRef = useRef();
    const endTimeErrorRef = useRef();
    const endTimePeriodRef = useRef();

    const practiceRef = useRef(rowDetail?.metainfo?.practice_id);
    
    const Time = [
        '-',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12'
    ];

    function updateDoctor() {
        console.log(rowDetail.editFormData);
        return Resource.updateUser('doctor', rowDetail.id, rowDetail.editFormData).then((response) => {
            if(response.success) {
                return response.data;
            }
            else {
                //Toast.error("ERROR: Profile could not be updated!!")
                return false;
            }
        })
    };

    const addSchedule = (e)=>{
        e.preventDefault();
        let day = dayRef.current.value;
        let startTime = startTimeRef.current.value;
        let endTime = endTimeRef.current.value;
        let startTimewithPeriod = startTime + startTimePeriodRef.current.value;
        let endTimewithPeriod = endTime + endTimePeriodRef.current.value;

        let dayError = dayErrorRef.current;
        let startTimeError = startTimeErrorRef.current;
        let endTimeError = endTimeErrorRef.current;

        
        if(day !== 'Select day' || startTime !=='-' || endTime !== '-' ){
            if(day !== 'Select day'){
                dayErrorRef.current.style.display = 'none';
                if(startTime !=='-'){
                    startTimeErrorRef.current.style.display = 'none';

                    if(endTime !== '-'){
                        endTimeErrorRef.current.style.display = 'none';
                        let schedule = {
                            'day': day,
                            'startTime': startTimewithPeriod,
                            'endTime': endTimewithPeriod,
                        };
                
                        setSchedules([...schedules,schedule]);
                        dayRef.current.value = 'Select day';
                        startTimeRef.current.value = '-';
                        endTimeRef.current.value = '-';
                        startTimePeriodRef.current.value = 'AM';
                        endTimePeriodRef.current.value = 'AM'; 
                        dayErrorRef.current.style.display = 'none';
                        startTimeErrorRef.current.style.display = 'none';
                        endTimeErrorRef.current.style.display = 'none';
                    }
                    else{
                        endTimeErrorRef.current.style.display = 'block';
                        endTimeError.innerHTML = 'select end Time';
                    }
                }
                else{
                    startTimeErrorRef.current.style.display = 'block';
                    startTimeError.innerHTML = 'select Start Time';
                }
            }else{
                dayErrorRef.current.style.display = 'block';
                dayError.innerHTML = 'select day';
            }
         
        }else{
            
            dayErrorRef.current.style.display = 'block';
            startTimeErrorRef.current.style.display = 'block';
            endTimeErrorRef.current.style.display = 'block';
            dayError.innerHTML = 'select day';
            startTimeError.innerHTML = 'select Start Time';
            endTimeError.innerHTML = 'select end Time';
        }
    }

    const deleteSchedule = (index)=>{
        let duplicateSchedules = [...schedules];
        duplicateSchedules.splice(index,1);
        setSchedules(duplicateSchedules);
    }

    useEffect(() => {
        practiceRef.current.value = rowDetail && rowDetail.metainfo?.practice_id;
        console.log(practiceRef.current.value);
    }, [practices]);

    useEffect(() => {
        rowDetail.editFormData = {
            'first_name': rowDetail.first_name,
            'last_name': rowDetail.last_name,
            'email': rowDetail.email,
            'contact_number': rowDetail.contact_number || rowDetail.username,
            'metainfo': {
                'practice_id': practiceRef.current.value,
                'medical_council': rowDetail.metainfo?.medical_council,
                'medical_registration_number': rowDetail.metainfo?.medical_registration_number
            }
        };

        console.log(practiceRef.current.value);
        Resource.getPractices().then((response) => {
            if(response.success) {
                setPractices(response.data);
            }
        });

        rowDetail.updateUser = updateDoctor;
        
        return () => {
            delete rowDetail.updateUser;
            delete rowDetail.editFormData;
        };

    }, []);

    return (
        
            <div className="doctor_modal_container">
                <form autoComplete="disabled"  className="editDoctor-form registerForm">
                    <div className='form-element'>
                        <label>Profile Photo</label>
                        <ProfilePicUpload id="d-image" assigned_to={rowDetail.id}/>
                        <span className="field-error" id="imageerror"></span>
                    </div>
                     
                    <div className='form-element'>
                        <label>First Name</label>
                        <input type="text" id="d-fname" className="inputField"  defaultValue={rowDetail.first_name} onChange={(e) => {rowDetail.editFormData.first_name = e.target.value}} required />
                        <span className="field-error" id="fnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Last Name</label>
                        <input type="text" id="d-lname" className="inputField"  defaultValue={rowDetail.last_name} onChange={(e) => {rowDetail.editFormData.last_name = e.target.value}} required />
                        <span className="field-error" id="lnameerror"></span>
                    </div>

                    {
                        showVisitingSchedule ?
                        ''
                        :
                        <div className='form-element'>
                            <label>10-digit Phone Number</label>
                            <input autoComplete="disabled"  disabled type="tel" id="d-phonenumber" className="inputField"  defaultValue={rowDetail.username} required />
                            <span className="field-error" id="phonenumerror"></span>
                        </div>
                    }

                    {
                        showVisitingSchedule ? 
                        <div className='form-element'>
                            <label>Contact Number</label>
                            <input autoComplete="disabled"  type="tel" id="d-contactnumber" className="inputField"  defaultValue={rowDetail.contact_number} />
                            <span className="field-error" id="contactnumerror"></span>
                        </div>
                        :
                        <div className='form-element'>
                            <label>Alternate Contact Number</label>
                            <input autoComplete="disabled"  type="tel" id="d-contactnumber" className="inputField"  defaultValue={rowDetail.contact_number} />
                            <span className="field-error" id="contactnumerror"></span>
                        </div>
                    }
                     
                    {/*<div className='form-element'>
                        <label>Clinic Name</label>
                        <input autoComplete="disabled" type="text" id="d-clinic" className="inputField"  defaultValue={rowDetail.clinic_name} required />
                        <span className="field-error" id="clinicerror"></span>
                    </div>*/}

                    <div className='form-element'>
                        <label>Practicing as</label>
                        <select id="d-practice" className="inputField" ref={practiceRef} onChange={(e) => {rowDetail.editFormData.metainfo.practice_id = e.target.value}} required>
                            <option value="">-- Select Practice --</option>
                            {
                                practices.map((practice) => {
                                    return <option key={practice.id} value={practice.id}>{practice.name}</option>
                                })
                            }
                            <option value="other">Other</option>
                        </select>
                        <span className="field-error" id="specialityerror"></span>
                    </div>
                     
                    <div className='form-element'>
                        <label>Medical Council</label>
                        <select id="d-medicalCouncil" className="inputField" defaultValue={rowDetail.metainfo?.medical_council} onChange={(e) => {rowDetail.editFormData.metainfo.medical_council = e.target.value}} required>
                            <option value="NMC - National Medical Commission">NMC - National Medical Commission</option>
                            <option value="IMR - Indian Medical Register">IMR - Indian Medical Register</option>
                            <option value="State Medical Council">State Medical Council</option>
                            <option value="FOGSI">FOGSI</option>
                            <option value="other">Other</option>
                        </select>
                        <span className="field-error" id="specialityerror"></span>
                    </div>
                     
                    <div className='form-element'>
                        <label>Medical Registration Number</label>
                        <input type="text" id="d-registerNumber" className="inputField" defaultValue={rowDetail.metainfo?.medical_registration_number} onChange={(e) => {rowDetail.editFormData.metainfo.medical_registration_number = e.target.value}} required />
                        <span className="field-error" id="registernumterror"></span>
                    </div>
                    {
                        showVisitingSchedule &&
                        <div className='visiting_schedule'>
                            <h3 className='visitingSchedule_title'>Visiting Schedule</h3>
                            <div className='scheduled_time'>
                                {
                                   schedules.length > 0 && schedules.map((schedule,index)=>{
                                        return( 
                                            <div className='schedule_component'>
                                                <div className='schedule_day'>
                                                    { schedule.day }
                                                </div>
                                                <div className='schedule_time'>
                                                    {schedule.startTime} - {schedule.endTime}
                                                </div>
                                                <svg onClick={()=>deleteSchedule(index)} className='close-btn' width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 6L6 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                    <path d="M6 6L18 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='visiting_day_and_time'>
                                <div className='form-element visiting_day'>
                                    <label>Day</label>
                                    <select ref={dayRef} id="d-visitingDay" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                        <option value="Select day">Select day</option>
                                        <option value="Sunday">Sunday</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                    </select>
                                    <span className="field-error" ref={dayErrorRef} id="visitindDayerror"></span>
                                </div>
                                <div className='time_schedular'>
                                    <div className='form-element time_schedular_container'>
                                        <label>Start Time</label>
                                        <div className='start_time_div'>
                                            <select ref={startTimeRef} id="d-visitingStartTime" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                                {
                                                    Time.map((time) => {
                                                        return <option value={time}>{time}</option>
                                                    })
                                                }
                                            </select>
                                            <select ref={startTimePeriodRef} id="d-visiting" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                        </div>
                                        <span className="field-error" ref={startTimeErrorRef} id="visitindDayerror"></span>
                                    </div>
                                    <div className='form-element time_schedular_container'>
                                        <label>End Time</label>
                                        <div className='end_time_div'>
                                            <select ref={endTimeRef} id="d-visitingEndTime" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                            {
                                                Time.map((time) => {
                                                    return <option value={time}>{time}</option>
                                                })
                                            }
                                            </select>
                                            <select ref={endTimePeriodRef} id="d-visiting" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                        </div>
                                        <span className="field-error" ref={endTimeErrorRef} id="visitindDayerror"></span>
                                    </div>
                                </div>
                                <Button text={'Add Schedule'} onclick={addSchedule} buttonType={'secondary_btn'} size={'fluid'} />
                            </div>
                        </div>
                        
                    }
                </form>
            </div>
            
        
    );
 
};
 
export default DoctorEditForm;
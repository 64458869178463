import './AddClinicForm.scss';
import { Link } from 'react-router-dom';
import Button from './../../Button/Button';
import Resource from '../../../services/resource.service';
import Toast from '../../Toaster/Toast';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../services/use-auth';

const AddClinicForm = ({closeModal}) => {

    const auth = useAuth();
    const [states, setStates] = useState([]);


    useEffect(()  => {
        Resource.fetchStates().then((response) => {
            console.log(response);
            if(response.success)
                setStates(response.data);
        });
    }, [auth.user]);

    function addClinic(e) {
        e.preventDefault();
        var Error = "This field cannot be empty";
        // var emailvalueError = "Please provide a valid email-id";
        var namevalueError = "Please provide a name";
        var phonevalueError = "Please provide a 10 digit valid phone number";
        // var agevalueError = "Please provide a age between 18 and 99";
        let phoneformat = /^([6-9])(\d{9})$/;
        
        var clinicname = document.getElementById('c-clinicname');
        var address = document.getElementById('c-address');
        var phonenumber = document.getElementById('c-phonenumber');
        var city = document.getElementById('c-city');
        var state = document.getElementById('c-state');

        var clinicnameerror = document.getElementById('clinicnameerror');
        var addresserror = document.getElementById('addresserror');
        var phoneerror = document.getElementById('phoneerror');
        var cityerror = document.getElementById('cityerror');
        var stateerror = document.getElementById('stateerror');

        if (clinicname.value || address.value || phonenumber.value || city.value || state.value ) {
            if (clinicname.value.length > 0) {
                clinicname.classList.remove('invalid');
                clinicnameerror.style.display = "none";
                
                if (address.value.length > 0) {
                    address.classList.remove('invalid');
                    addresserror.style.display = "none";

                    if (phonenumber.value.length > 0 && phonenumber.value.match(phoneformat) ) {
                        phoneerror.style.display = "none";
                        phonenumber.classList.remove("invalid");

                        if (city.value.length > 0) {
                            city.classList.remove('invalid');
                            cityerror.style.display = "none";

                            if (state.value.length >0) {
                                stateerror.style.display = "none";
                                state.classList.remove("invalid");
                                var obj = {
                                    name:'',
                                    address:'',
                                    contact_number:'',
                                    metainfo: {}
                                };
                                console.log(obj,'obj');
                                obj.name = clinicname.value;
                                obj.address = address.value;
                                obj.contact_number = '+91' + phonenumber.value.replace(/\D/g, "");
                                obj.metainfo.city = city.value;
                                obj.metainfo.state = state.value;
                                console.log(obj,'obj');
                                Resource.addClinics(obj).then((resp) => {
                                    if(resp.success) {
                                        Toast.success("Clinic added successfully");
                                    }
                                });
                                closeModal();
                            }
                            else {
                                state.classList.add('invalid');
                                stateerror.style.display = "inline";
                                stateerror.innerHTML = Error;
                            }
                        }
                        else {
                            city.classList.add('invalid');
                            cityerror.style.display = "inline";
                            cityerror.innerHTML = Error;
                        }
                    }
                    else {
                        phoneerror.style.display = "inline";
                        phonenumber.classList.add("invalid");
                        phoneerror.innerHTML = phonevalueError;
                    }
                }
                else {
                    addresserror.style.display = "inline";
                    address.classList.add("invalid");
                    addresserror.innerHTML = Error;
                }
            }
            else {
                clinicnameerror.style.display = "inline";
                clinicname.classList.add("invalid");
                clinicnameerror.innerHTML = namevalueError;
            }
        }
        else {
            clinicnameerror.style.display = "inline";
            clinicname.classList.add("invalid");
            clinicnameerror.innerHTML = namevalueError;

            addresserror.style.display = "inline";
            address.classList.add("invalid");
            addresserror.innerHTML = Error;

            phoneerror.style.display = "inline";
            phonenumber.classList.add("invalid");
            phoneerror.innerHTML = Error;

            city.classList.add('invalid');
            cityerror.style.display = "inline";
            cityerror.innerHTML = Error;

            state.classList.add('invalid');
            stateerror.style.display = "inline";
            stateerror.innerHTML = Error;
        }     
    }

    return(
        <>
        <div className='clinic-modal'>
           <div className="add-clinic container">
                <div className='addClinicContainer'>
                    <h1 className="title">Clinic Form</h1>
                    <div className='closeBtn_container'onClick={closeModal} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                            <path d="M6 6L18 18" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                
                <form autoComplete="disabled"  className="registerForm Clinic-form">
                
                    <div className='form-element'>
                        <label>Clinic Name</label>
                        <input autoComplete="disabled" type="text" id="c-clinicname" className="inputField" placeholder="Enter clinic name" required />
                        <span className="field-error" id="clinicnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Address</label>
                        <input type="text" id="c-address" className="inputField" placeholder="Enter Clinic address" required />
                        <span className="field-error" id="addresserror"></span>
                    </div>

                    <div className='form-element'>
                        <label>10-digit Phone Number</label>
                        <input autoComplete="disabled"  type="tel" id="c-phonenumber" className="inputField" placeholder="Enter Phone Number" required/>
                        <span className="field-error" id="phoneerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>City</label>
                        <input type="text" id="c-city" className="inputField" placeholder="Enter City" required />
                        <span className="field-error" id="cityerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>State / Province</label>
                        <select id="c-state" className="inputField" placeholder='-- Select State --' required>
                            <option value=''>-- Please Select --</option>
                            {
                                states && states.map((s, idx) => {
                                    return <option key={idx} value={s.state}>{s.state}</option>
                                })
                            
                            }
                        </select>
                        {/*
                        <input type="text" id="c-state" className="inputField" placeholder="Enter state" required />*/}
                        <span className="field-error" id="stateerror"></span>
                    </div>
                    <Button text={'Add Clinic'} buttonType={'primary_btn'} onclick={addClinic} ></Button>
                </form>
            </div>
        </div>
        </>
    )
}

export default AddClinicForm;
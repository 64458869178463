import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import './../ProgramCard/ProgramCard.scss';
import { useAuth } from '../../services/use-auth';
import Resource from '../../services/resource.service';
import Toast from '../Toaster/Toast';

const ProgramCard = ({programData}) => {

    let navigate = useNavigate();
    const auth = useAuth();

    function goToProgramDetails() {
        navigate('/programs/programdetails', {state: programData} );
    }

    function goToUpdateDetails() {
        navigate('/programs/editProgram', {
        state: programData
      }
      )
      console.log(programData)
    }
    return (
        <div className='programCard' onClick={goToProgramDetails}>
            <div className='programCardContent'>
                <div className='cardHeader'>
                    <h2 className='programTitle'>{programData.title}</h2>
                    {
                    auth.user && auth.user.role == Resource.ROLES.ADMIN &&
                    <Link to='/programs/editProgram' state={programData} onClick={(e)=>{e.stopPropagation()}} className="editProgramButton" >Edit</Link>
                    }
                </div>
                {<p className='programDescription'>{programData.subtitle}</p>}
                {/* <div className='Count'>
                    <div className='modules'>
                        <h3 className='modulesTitle'>Modules</h3>
                        <h4 className='modulesCount'>{programData.ModulesCount}</h4>
                    </div>
                    <div className='clients'>
                        <h3 className='clientsTitle'>Clients Registered</h3>
                        <h4 className='clientsCount'>{programData.ClientCount}</h4>
                    </div>
                </div> */}
            </div>
        </div>
    );

};
export default ProgramCard;
import React, { useState, useRef, useEffect } from 'react';
import './../../../Modal/Doctormodal/Doctormodal.scss';
import { useAuth } from '../../../../services/use-auth';
import Resource from '../../../../services/resource.service';
import Toast from '../../../Toaster/Toast';
import Button from '../../../Button/Button';
import './AddDoctorModal.scss'

const AddDoctorModal = ({closeModal, clinicId}) => {

    const [practices, setPractices] = useState([]);
    const auth = useAuth();

    const [showClinicSuggestion ,setShowClinicSuggestion] = useState(false);
    const [matchedClinicData,setMatchedClinicData] = useState([]);
    const [selectedDoctor,setSelectedDoctor] = useState(null);
    const [showSuggestionBox , setShowSuggestionBox] = useState(false);
    const [matchedData,setMatchedData] = useState([]);
    const [reload, setReload] = useState(false);
    const [doctorId, setDoctorId] = useState(null);
    
    const phoneInputRef = useRef();
    const firstNameInputRef = useRef();
    const medicalCouncilInputRef = useRef();
    const medicalRegisterNumberInputRef = useRef();
    const practiceInputRef = useRef();
    const suggestionRef = useRef();
    const lastNameInputRef = useRef();
    const emailInputRef = useRef();

    let searchClinicRef = useRef();
    let clinicSuggestionRef = useRef();

    function getSelectedClinicSearchValue(item){
        setShowClinicSuggestion(false);
        searchClinicRef.current.value = item.name ;
        searchClinicRef.current.item = item;
        setMatchedClinicData([]);
    }

    function onChangeClinicSearchInput(e) {
        let value = e.target.value;
        if(value.length > 0){
            searchClinicRef.current.item = null;
            setShowClinicSuggestion(true);
            Resource.autocomplete({q: value, otype: 'clinic'}).then((response) => {
                if(response.success) {
                    setMatchedClinicData(response.data.matches);
                }
            });
        } else{
            setShowClinicSuggestion(false);
        }
    }

    const addDoctor = (e) => {
        e.preventDefault();
        var Error = "This field cannot be empty";
        // var emailvalueError = "Please provide a valid email-id";
        var namevalueError = Error;
        var phonevalueError = "Please provide a 10 digit valid phone number";
        // var agevalueError = "Please provide a age between 18 and 99";
        let phoneformat = /^\+(91)([6-9])(\d{9})$|^([6-9])(\d{9})$/;

        var firstName = document.getElementById('d-fname');
        var lastName = document.getElementById('d-lname');
        var email = document.getElementById('d-email');
        var practice = document.getElementById('d-practice');
        var medicalcouncil = document.getElementById('d-council');
        //var clinic = document.getElementById('d-clinic');
        var registernumber = document.getElementById('d-registernumber');
        var phonenumber = document.getElementById('assistant-ph');

        var firstnameerror = document.getElementById('firstnameerror');
        var lastnameerror = document.getElementById('lastnameerror');
        var practiceerror = document.getElementById('practiceerror');
        //var clinicerror = document.getElementById('clinicerror');
        var councilerror = document.getElementById('councilerror');
        var registernumbererror = document.getElementById('registernumbererror');
        var phoneerror = document.getElementById('phoneerror');
        // var emailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (firstName.value || lastName.value || phonenumber.value || practice.value || clinicId || medicalcouncil.value || registernumber.value ) {

            if (phonenumber.value !== '' && phonenumber.value.match(phoneformat)) {
                phoneerror.classList.remove('invalid');
                phoneerror.style.display = "none";
                if (firstName.value.length > 0) {
                    firstName.classList.remove('invalid');
                    firstnameerror.style.display = "none";

                    if (lastName.value.length > 0) {
                        lastName.classList.remove('invalid');
                        lastnameerror.style.display = "none";

                            if (practice.value !== '' ) {
                            practiceerror.style.display = "none";
                            practice.classList.remove("invalid");

                                if ( clinicId ) {
                                    //clinic.classList.remove('invalid');
                                    //clinicerror.style.display = "none";

                                    if (medicalcouncil.value !== '' ) {
                                        councilerror.style.display = "none";
                                        medicalcouncil.classList.remove("invalid");

                                        if (registernumber.value !== '') {
                                            registernumbererror.style.display = "none";
                                            registernumber.classList.remove("invalid");
                                            var obj = {
                                                first_name:'',
                                                last_name:'',
                                                email:'',
                                                phone_number:'',
                                                role: Resource.ROLES.DOCTOR,
                                                metainfo: {
                                                    medical_council: '',
                                                    medical_registration_number: '',
                                                    practice_id: ''
                                                },
                                                clinics: [
                                                    clinicId
                                                ]
                                            };
                                            obj.id = doctorId;
                                            obj.first_name = firstName.value;
                                            obj.last_name = lastName.value;
                                            obj.email = email.value;
                                            obj.phone_number = '+91' + phonenumber.value.replace(/\D/g, "");
                                            obj.metainfo.practice_id = practice.value;
                                            obj.metainfo.medical_council = medicalcouncil.value;
                                            obj.metainfo.medical_registration_number = registernumber.value;
                                            obj.contact_number = '+91' + phonenumber.value.replace(/\D/g, "");
                                            
                                            /*if(searchClinicRef.current.item) {
                                                obj.clinics = [searchClinicRef.current.item.id];
                                            }
                                            else {
                                                obj.metainfo.clinic_name = clinic.value;
                                            }*/

                                            console.log(obj);

                                            Resource.createUser(Resource.ROLES.DOCTOR, obj).then((resp) => {
                                                if(resp.success) {
                                                    Toast.success("Doctor added successfully");
                                                }
                                            });
                                            // Resource.updateSelf(obj).then((resp) => {
                                            //     if(resp.success) {
                                            //         auth.getConfig({role: role}).then((resp) => {
                                            //             Toast.success("Registration form submitted successfully!!");  
                                            //         });
                                            //     }
                                            // });

                                        }
                                        else {
                                            registernumber.classList.add('invalid');
                                            registernumbererror.style.display = "inline";
                                            registernumbererror.innerHTML = Error;
                                        }
                                    }    
                                    else {
                                        councilerror.style.display = "inline";
                                        medicalcouncil.classList.add("invalid");
                                        councilerror.innerHTML = Error;
                                    }    
                                }
                                /*else {
                                    clinic.classList.add('invalid');
                                    clinicerror.style.display = "inline";
                                    clinicerror.innerHTML = Error;
                                }*/
                            }
                            else {
                                practiceerror.style.display = "inline";
                                practice.classList.add("invalid");
                                practiceerror.innerHTML = Error;
                            }
                        }
                    else {
                        lastnameerror.style.display = "inline";
                        lastName.classList.add("invalid");
                        lastnameerror.innerHTML = namevalueError;
                    }     
                }
                else {
                    firstnameerror.style.display = "inline";
                    firstName.classList.add("invalid");
                    firstnameerror.innerHTML = namevalueError;
                }
            }
                else {
                    phonenumber.classList.add('invalid');
                    phoneerror.style.display = "inline";
                    phoneerror.innerHTML = phonevalueError; 
                }   
            }
        else {
            firstnameerror.style.display = "inline";
            firstName.classList.add("invalid");
            firstnameerror.innerHTML = namevalueError;

            lastnameerror.style.display = "inline";
            lastName.classList.add("invalid");
            lastnameerror.innerHTML = namevalueError;

            phoneerror.style.display = "inline";
            phonenumber.classList.add('invalid');
            phoneerror.innerHTML = Error;

            practiceerror.style.display = "inline";
            practice.classList.add("invalid");
            practiceerror.innerHTML = Error;

            /*clinic.classList.add('invalid');
            clinicerror.style.display = "inline";
            clinicerror.innerHTML = Error;*/

            medicalcouncil.classList.add('invalid');
            councilerror.style.display = "inline";
            councilerror.innerHTML = Error;

            registernumbererror.style.display = "inline";
            registernumber.classList.add("invalid");
            registernumbererror.innerHTML = Error;
        }       
        
    }
    
    function suggestionLookup(query) {
        let otype = 'doctor'
        let params = {
            q: query,
            otype: otype
        }
        return Resource.autocomplete(params);
    }

    function callbackLookup(item) {
        if(item) {
            Resource.getUserById(item.type, item.id).then((response) => {
                if(response.success) {
                    console.log(response.data, "data response");
                    // tabs[activeTabRef].data = [resposne.data];
                    setSelectedDoctor(response.data);
                    setDoctorId(response.data.id);
                    setReload(!reload);
                }
                else {
                    Toast.error("ERROR: User not found");
                }
            });
        }
    }

    const onChangePhoneInput = (e) => {
        let value = e.target.value;
        if(value.length > 0){
            setShowSuggestionBox(true);
            suggestionLookup(value).then((response) => {
                if(response.success) {
                    setMatchedData(response.data.matches.slice(0, 10));
                }
            })
        } else{
            setShowSuggestionBox(false);
            if(callbackLookup)
                callbackLookup(null);
        }
    }

    function handleClickOutside(e) {
        if (suggestionRef.current && !suggestionRef.current.contains(e.target)) { 
            setShowSuggestionBox(false);
        }
    }

    function getSelectedSearchValue(item) {
        setShowSuggestionBox(false);
        if(callbackLookup) {
            callbackLookup(item);
        }
    }
 
    useEffect(()=>{
        if(selectedDoctor !== null){
            phoneInputRef.current.value = selectedDoctor.contact_number;
            firstNameInputRef.current.value = selectedDoctor.first_name;
            lastNameInputRef.current.value = selectedDoctor.last_name;
            emailInputRef.current.value = selectedDoctor.email;
            //searchClinicRef.current.value = selectedDoctor.metainfo.clinic_name;
            practiceInputRef.current.value = selectedDoctor.metainfo.practice_id;
            medicalCouncilInputRef.current.value = selectedDoctor.metainfo.medical_council;
            medicalRegisterNumberInputRef.current.value = selectedDoctor.metainfo.medical_registration_number;
        }
        return ()=>{

        }
    },[selectedDoctor]);

    useEffect(() => {
        Resource.getPractices().then((response) => {
            if(response.success)
                setPractices(response.data);
        });
    }, [auth.user]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        });
    },[showSuggestionBox]);

    return (
       <div className='doctor-modal'>
            <div className="add-doctor container">
                <form autoComplete="disabled"  className="registerForm addDoctor-form">
                    <div className='form-element phonenumber_input'>
                        <label>10-digit Phone Number</label>
                        <input autoComplete="disabled"  type="tel" id="assistant-ph" ref={phoneInputRef} className="inputField" placeholder="Enter phone number" onChange={(e)=>onChangePhoneInput(e)}/>
                        <span className="field-error" id="phoneerror"></span>
                        {
                            showSuggestionBox ? 
                            <div className="suggestionBox" ref={suggestionRef}>
                                {
                                    matchedData.length > 0 && matchedData.map((item)=>{
                                        return  (
                                            <>
                                                <div className="searchSuggestion" onClick={()=>getSelectedSearchValue(item)}>
                                                    <span className="searchValue">
                                                        {item.name}
                                                    </span>
                                                    <span className="searchType">
                                                        {item.username} 
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    })    
                                }
                            </div>
                            :
                            ' '
                        }
                    </div>
                    <div className='form-element'>
                        <label>First Name</label>
                        <input type="text" id="d-fname" ref={firstNameInputRef} className="inputField" placeholder="Enter first name" required />
                        <span className="field-error" id="firstnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Last Name</label>
                        <input type="text" id="d-lname" ref={lastNameInputRef} className="inputField" placeholder="Enter last name" required />
                        <span className="field-error" id="lastnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Email</label>
                        <input autoComplete="disabled"  type="email" ref={emailInputRef} id="d-email" className="inputField" placeholder="Enter email address" />
                        <span className="field-error" id="emailerror"></span>
                    </div>

                    {/*<div className='form-element clinicSearchGroup' >
                        <label>Clinic Name</label>
                        <input autoComplete="disabled" type="text" id="d-clinic" ref={searchClinicRef} className="inputField" placeholder="Enter clinic name" onChange={(e)=>onChangeClinicSearchInput(e)} required />
                        <span className="field-error" id="clinicerror"></span>
                        {
                            showClinicSuggestion ? 
                            <div className="suggestionBox" ref={clinicSuggestionRef}>
                                {
                                matchedClinicData.length > 0 ? matchedClinicData.map((item)=>{
                                        return  (
                                            <>
                                                <div className="searchSuggestion" onClick={()=>getSelectedClinicSearchValue(item)}>
                                                    <span className="searchValue">
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    }) :
                                    ''
                                }
                            </div>
                            :
                            ' '
                        }
                    </div>*/}
                    
                    <div className='form-element'>
                        <label>Practicing As</label>
                        <select id="d-practice" ref={practiceInputRef} className="inputField" placeholder='Select practice' required>
                            <option value="">-- Select Practice --</option>
                            {
                                practices.map((p) => {
                                    return <option value={p.id}>{p.name}</option>
                                })
                            }
                            <option value="other">Other</option>
                        </select>
                        <span className="field-error" id="practiceerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>Medical Council</label>
                        <select id="d-council" ref={medicalCouncilInputRef} className="inputField" placeholder='Select medical council' required>
                            <option value="">-- Select Medical Council --</option>
                            <option value="NMC - National Medical Commission">NMC - National Medical Commission</option>
                            <option value="IMR - Indian Medical Register">IMR - Indian Medical Register</option>
                            <option value="State Medical Council">State Medical Council</option>
                            <option value="FOGSI">FOGSI</option>
                            <option value="other">Other</option>
                        </select>
                        <span className="field-error" id="councilerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>Medical Registration Number</label>
                        <input type="text" id="d-registernumber" ref={medicalRegisterNumberInputRef} className="inputField" placeholder="Enter registration number" required />
                        <span className="field-error" id="registernumbererror"></span>
                    </div>
                    <Button text={'Add Doctor'} buttonType={'primary_btn'} onclick={addDoctor} ></Button>
                </form>
            </div>
        </div>
    );

};

export default AddDoctorModal;
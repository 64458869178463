import axios from 'axios';
import { apiHeader } from '../helpers/auth-header';
import authenticationService from './authentication.service';
import BusyLoading from '../helpers/loading';
import Toast from '../components/Toaster/Toast';

var apiHost = authenticationService.apiHost;
var ROLES = authenticationService.config.ROLES;
var PATIENT_QR_HOST = authenticationService.config.PATIENT_QR_HOST;

const Resource = {
    PATIENT_QR_HOST,
    ROLES,
    createProfile,
    getPatients,
    getWallet,
    updateProfile,
    getProfile,
    referPatient,
    cashout,
    raiseTicket,
    addUpdateSelectedProgram,
    removeSelectedProgram,
    getUsers,
    getUsersCount,
    getUserById,
    updateSelf,
    updateStatus,
    getPrograms,
    getProgramById,
    mediaUpload,
    autocomplete,
    getPractices,
    updateProgram,
    addModule,
    updateModule,
    createUser,
    updateUser,
    generatePayment,
    requestWithdraw,
    getClinics,
    addClinics,
    updateClinic,
    getDiagnosis,
    queueRegister,
    SelfRegister,
    updateQueueRegister,
    fetchPrognosisReport,
    fetchStates
};

function createProfile(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/create_auth_profile", data, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            return { "success": true, "data": response.data };
        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.data, "data": null };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { "error": err, "data": null };
        });
}

function updateProfile(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/update_auth_profile", data, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            return { "success": true, "data": response.data };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { "error": err, "data": null };
        });
}

function getProfile(refresh) {
    
    var urlParam = '';
    if(refresh)
        urlParam += '?refresh=true';
    
    BusyLoading.show();
    return axios.get(apiHost + "/v1/me" + urlParam, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            return { "success": true, "data": response.data };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { "error": err, "data": null };
        });
}

function referPatient(patientData, version) {
    
    var url = apiHost + "/v1/refer_patient";
    if(version == 'v2')
        url = apiHost + "/v2/refer_patient";

    BusyLoading.show();
    return axios.post(url, patientData, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            if (response.data.success)
                return { "success": true, "data": response.data };
            else if (response.data.warning)
                return { "warning": response.data.warning, "data": null }
            else
                return { "error": true, "data": null };

        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.data, "data": null };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { 'error': "We are facing some issue. Please try again in sometime.", "data": null };
        });
}

function getPatients() {
    BusyLoading.show();
    return axios.get(apiHost + "/v1/patients", { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            if (response.data.referred_patients)
                return { 'success': true, "data": response.data.referred_patients };
            else
                return { 'success': true, "data": [] };

        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function getWallet(version) {
    var url = apiHost + "/v1/wallet_info";
    if(version == 'v2')
        url = apiHost + "/v2/wallet_info";

    BusyLoading.show();
    return axios.get(url, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            return { 'success': true, "data": response.data };
        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function cashout(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/support_request", {
        type: 'cashout',
        amount: data.amount
    },
        { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            return { 'success': true, "data": response.data }
        }).catch((err) => {
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function raiseTicket(data, version) {
    var url = apiHost + "/v1/support_request";
    if(version == 'v2')
        url = apiHost + "/v2/support_request";

    console.log(data);
    BusyLoading.show();
    return axios.post(url, {
        type: 'query',
        message: data.query
    },
    { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        return { 'success': true, "data": response.data }
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    });
}

function removeSelectedProgram(program) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/remove_selected_program/" + program.id, {},
        { headers: apiHeader() })
        .then((response) => {
            let userInfo = localStorage.getItem("currentUser");
            if (userInfo) {
                let userInfoJson = JSON.parse(userInfo);
                userInfoJson.metainfo.selected_programs = response.data;
                localStorage.setItem("currentUser", JSON.stringify(userInfoJson));
            }
            BusyLoading.hide();
            return { 'success': true, "data": response.data }
        }).catch((err) => {
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function addUpdateSelectedProgram(program) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/add_update_selected_program",
        program,
        { headers: apiHeader() })
        .then((response) => {
            let userInfo = localStorage.getItem("currentUser");
            if (userInfo) {
                let userInfoJson = JSON.parse(userInfo);
                userInfoJson.metainfo.selected_programs = response.data;
                localStorage.setItem("currentUser", JSON.stringify(userInfoJson));
            }
            BusyLoading.hide();
            return { 'success': true, "data": response.data }
        }).catch((err) => {
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

//MD+ users

function requestWithdraw(data) {
    var url = apiHost + "/v2/support_request";
    BusyLoading.show();
    return axios.post(url, data, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            return { 'success': true, "data": response.data }
        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((err) => {
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function getUsers(role, auth_id, filter) {

    let filterStr = '';
    if (filter)
        filterStr = '?' + new URLSearchParams(filter).toString();

    console.log(filterStr);

    if (role == ROLES.DOCTOR) {
        BusyLoading.show();
        return axios.get(apiHost + "/v2/doctor" + filterStr, { headers: apiHeader() })
            .then((response) => {
                BusyLoading.hide();
                return { 'success': true, "data": response.data.doctors_list };
            }, (error) => {
                BusyLoading.hide();
                if ([401, 403].indexOf(error.response.status) !== -1) {
                    Toast.warning("Loading", true);
                }
                return { "error": error.response.data, "data": null };
            }).catch((err) => {
                BusyLoading.hide();
                return { 'error': err, "data": null };
            });
    }
    else if (role == ROLES.CARE_MANAGER) {
        BusyLoading.show();
        return axios.get(apiHost + "/v2/care_manager" + filterStr, { headers: apiHeader() })
            .then((response) => {
                BusyLoading.hide();
                return { 'success': true, "data": response.data.care_managers_list };
            }, (error) => {
                BusyLoading.hide();
                if ([401, 403].indexOf(error.response.status) !== -1) {
                    Toast.warning("Loading", true);
                }
                return { "error": error.response.data, "data": null };
            }).catch((err) => {
                BusyLoading.hide();
                return { 'error': err, "data": null };
            });
    }
    else if (role == ROLES.ASSISTANT) {
        BusyLoading.show();
        var query_param = '';
        if(auth_id) {
            query_param = '?doctor_aid=' + auth_id;
        }
        return axios.get(apiHost + "/v2/assistant" + query_param + filterStr, { headers: apiHeader() })
            .then((response) => {
                BusyLoading.hide();
                return { 'success': true, "data": response.data.assistants_list };
            }, (error) => {
                BusyLoading.hide();
                if ([401, 403].indexOf(error.response.status) !== -1) {
                    Toast.warning("Loading", true);
                }
                return { "error": error.response.data, "data": null };
            }).catch((err) => {
                BusyLoading.hide();
                return { 'error': err, "data": null };
            });
    }
    else if (role == 'visitor') {
        BusyLoading.show();
        
        return axios.get(apiHost + "/v1/patient_queue" + filterStr, { headers: apiHeader() })
            .then((response) => {
                BusyLoading.hide();
                return { 'success': true, "data": response.data.qms_list};
            }, (error) => {
                BusyLoading.hide();
                if ([401, 403].indexOf(error.response.status) !== -1) {
                    Toast.warning("Loading", true);
                }
                return { "error": error.response.data, "data": null };
            }).catch((err) => {
                BusyLoading.hide();
                return { 'error': err, "data": null };
            });
    }
    else {
        BusyLoading.show();
        return axios.get(apiHost + "/v2/patient" + filterStr, { headers: apiHeader() })
            .then((response) => {
                BusyLoading.hide();
                return { 'success': true, "data": response.data.patients_list };
            }, (error) => {
                BusyLoading.hide();
                if ([401, 403].indexOf(error.response.status) !== -1) {
                    Toast.warning("Loading", true);
                }
                return { "error": error.response.data, "data": null };
            }).catch((err) => {
                BusyLoading.hide();
                return { 'error': err, "data": null };
            });
    }
}

function getUsersCount(type, filter) {
    let filterStr = '';
    if (filter)
        filterStr = '?' + new URLSearchParams(filter).toString();

    if(type == ROLES.PATIENT) {
        let url = apiHost + '/v2/patient/count' + filterStr;
        BusyLoading.show();
        return axios.get(url, {headers: apiHeader() })
        .then( (response) => {
            BusyLoading.hide();
            return {'success': true, "data": response.data};
        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch( (err) => {
            BusyLoading.hide();
            return {'error': err, "data": null};
        });
    }
    else {
        BusyLoading.hide();
        return {'error': 'Invalid request', "data": null};
    }
}

function getUserById(type, id) {
    var url = '';
    if(type == ROLES.DOCTOR) {
        url = apiHost + "/v2/doctor/" + id;
    }
    else if(type == ROLES.CARE_MANAGER) {
        url = apiHost + "/v2/care_manager/" + id;
    }
    else if(type == ROLES.PATIENT){
        url = apiHost + "/v2/patient/" + id;
    }
    else if(type == 'lead') {
        url = apiHost + "/v2/lead/" + id;
    }
    else if(type == ROLES.ASSISTANT) {
        url = apiHost + "/v2/assistant/" + id;
    }

    if(url) {
        BusyLoading.show();
        return axios.get(url, { headers: apiHeader() })
        .then( (response) => {
            BusyLoading.hide();
            return {'success': true, "data": response.data};
        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.data, "data": null };
        }).catch( (err) => {
            BusyLoading.hide();
            return {'error': err, "data": null};
        });
    }
    else {
        return {'error': 'Invalid User Type', "data": null};
    }
}

function updateSelf(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/self",
        data,
        { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            return { 'success': true, "data": response.data }
        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((err) => {
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function updateStatus(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/update_status",
        data,
        { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            return { 'success': true, "data": {} }
        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((err) => {
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function createUser(userType, data) {
    BusyLoading.show();
    var api;
    if(userType == ROLES.ASSISTANT) {
        api = apiHost + "/v2/assistant";
    }
    else if(userType == ROLES.CARE_MANAGER){
        api = apiHost + "/v2/care_manager";
    }
    else if(userType == ROLES.DOCTOR) {
        api = apiHost + "/v2/doctor";
    }

    return axios.post(api, data, { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        return { 'success': true, "data": response.data };
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    })
}

function updateUser(type, id, data) {
    var url = '';
    if(type == ROLES.DOCTOR) {
        url = apiHost + "/v2/doctor/" + id;
    }
    else if(type == ROLES.CARE_MANAGER) {
        url = apiHost + "/v2/care_manager/" + id;
    }
    else if(type == ROLES.PATIENT){
        url = apiHost + "/v2/patient/" + id;
    }
    else if(type == 'lead') {
        url = apiHost + "/v2/lead/" + id;
    }

    if(url) {
        BusyLoading.show();
        return axios.post(url, data, { headers: apiHeader() })
        .then( (response) => {
            BusyLoading.hide();
            return {'success': true, "data": response.data};
        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch( (err) => {
            BusyLoading.hide();
            return {'error': err, "data": null};
        });
    }
    else {
        return {'error': 'Invalid User Type', "data": null};
    }
}

function getPrograms(filter) {
    let filterStr = '';
    if (filter)
        filterStr = '?' + new URLSearchParams(filter).toString();

    BusyLoading.show();
    return axios.get(apiHost + "/v2/program" + filterStr, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            if (response.data.programs_list)
                return { 'success': true, "data": response.data.programs_list };
            else
                return { 'success': true, "data": [] };

        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function getPractices() {
    BusyLoading.show();
    return axios.get(apiHost + "/v1/practice", { headers: apiHeader() })
    .then( (response) => {
        BusyLoading.hide();
    if(response.data.practices_list)
        return {'success': true, "data": response.data.practices_list};
    else
        return {'success': true, "data": []};
    
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch( (err)=> {
    console.log(err);
    BusyLoading.hide();
    return {'error': err, "data": null};
    });
}

function getProgramById(id) {
    BusyLoading.show();
    return axios.get(apiHost + "/v2/program/" + id, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            if (response.data)
                return { 'success': true, "data": response.data };
            else
                return { 'success': true, "data": [] };

        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function mediaUpload(data, file) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/initiate_media_upload", data, { headers: apiHeader() })
        .then((response) => {
            if(response.data.url) {
                var document_id = response.data.id;
            
                axios.put(response.data.url, file, {
                    headers: { "Content-Type": "multipart/form-data" }
                }).then((resp) => {
                    return axios.post(apiHost + "/v2/callback_media_upload", {'document_id': document_id}, { headers: apiHeader() })
                    .then((res) => {
                        BusyLoading.hide();
                        return { "success": true, "data": res.data };
                    }).catch((e) => {
                        console.log(e);
                        BusyLoading.hide();
                        return { "error": e, "data": null };
                    })
                }).catch((err) => {
                    console.log(err);
                    BusyLoading.hide();
                    return { "error": err, "data": null };
                })

                return { "success": true, "data": {'document_id': document_id} };
            }
        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((error) => {
            console.log(error);
            BusyLoading.hide();
            return { "error": error, "data": null };
        });
}

function autocomplete(params) {
    let qStr = '?' + new URLSearchParams(params).toString();
    return axios.get(apiHost + "/v2/autocomplete" + qStr, { headers: apiHeader() })
    .then( (response) => {

    if(response.data)
        return {'success': true, "data": response.data};
    else
        return {'success': true, "data": []};
    
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch( (err)=> {
    return {'error': err, "data": null};
    });
}

function updateProgram(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/program",
    data,
    { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        console.log(response.data)
        return { 'success': true, "data": response.data }
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    });

}

function addModule(program_id, data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/program/" + program_id + "/module",
    data,
    { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        return { 'success': true, "data": {} }
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    });

}

function updateModule(program_id, module_id, data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/program/" + program_id + "/module/" + module_id,
    data,
    { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        return { 'success': true, "data": response.data }
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    });

}

function generatePayment(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/create_payment",
    data, 
    {headers: apiHeader()})
    .then((response) => {
        BusyLoading.hide();
        console.log(response.data);
        return {'success': true, "data": response.data};
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch( (err)=> {
        BusyLoading.hide();
        return {'error': err, "data": null};
    });
    
}

function getClinics() {
    BusyLoading.show();
    return axios.get(apiHost + "/v2/clinic", { headers: apiHeader() })
    .then( (response) => {
        BusyLoading.hide();
    if(response.data.clinics_list)
        return {'success': true, "data": response.data.clinics_list};
    else
        return {'success': true, "data": []};
    
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch( (err)=> {
    console.log(err);
    BusyLoading.hide();
    return {'error': err, "data": null};
    });
}

function addClinics(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/clinic",
    data,
    { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        console.log(response.data)
        return { 'success': true, "data": response.data }
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    });

}

function updateClinic(clinic_id, data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/clinic/" + clinic_id ,
    data,
    { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        return { 'success': true, "data": response.data }
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    });

}

function getDiagnosis(filter) {
    let filterStr = '';
    if (filter)
        filterStr = '?' + new URLSearchParams(filter).toString();

    BusyLoading.show();
    return axios.get(apiHost + "/v2/diagnosis" + filterStr, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            if (response.data.diagnosis_list)
                return { 'success': true, "data": response.data.diagnosis_list };
            else
                return { 'success': true, "data": [] };

        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function SelfRegister(clinic_id, data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/registration/" + clinic_id,
    data,
    { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        return { 'success': true, "data": response.data }
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    });

}

function queueRegister(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/patient_queue",
    data,
    { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        return { 'success': true, "data": response.data }
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    });
}

function updateQueueRegister(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v2/patient_queue/update",
    data,
    { headers: apiHeader() })
    .then((response) => {
        BusyLoading.hide();
        return { 'success': true, "data": response.data }
    }, (error) => {
        BusyLoading.hide();
        if ([401, 403].indexOf(error.response.status) !== -1) {
            Toast.warning("Loading", true);
        }
        return { "error": error.response.data, "data": null };
    }).catch((err) => {
        BusyLoading.hide();
        return { 'error': err, "data": null };
    });
}

function fetchPrognosisReport(cqms_id) {
    
    BusyLoading.show();
    return axios.get(apiHost + "/v2/prognosis_report/" + cqms_id, { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            if (response.data.success)
                return { 'success': true, "data": response.data.prognosis_report, "version": response.data.version};

        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

function fetchStates() {
    
    BusyLoading.show();
    return axios.get(apiHost + "/v2/states", { headers: apiHeader() })
        .then((response) => {
            BusyLoading.hide();
            if (response.data.success)
                return { 'success': true, "data": response.data.states };

        }, (error) => {
            BusyLoading.hide();
            if ([401, 403].indexOf(error.response.status) !== -1) {
                Toast.warning("Loading", true);
            }
            return { "error": error.response.data, "data": null };
        }).catch((err) => {
            console.log(err);
            BusyLoading.hide();
            return { 'error': err, "data": null };
        });
}

export default Resource;
import React, {useEffect, useState} from 'react';
import { Link, useNavigate, Routes } from 'react-router-dom';
import logo from './../../images/mlogo.png';
import './Login.scss';
import authenticationService from './../../services/authentication.service';
import { useAuth } from './../../services/use-auth';
import AuthRoute from './../../helpers/AuthRoute';
import GetOtp from './../GetOtp/GetOtp';
import IosScreenPopup from './../HomescreenPopup/IosScreenPopup/IosScreenPopup';
import AndroidScreenPopup from '../HomescreenPopup/AndroidScreenPopup/AndroidScreenPopup';
import useHandleEnter from '../../hooks/useHandleEnter';

const Login = () => {
    var auth = useAuth();
    var navigate = useNavigate();

    const [otp, setOtp] = useState(false);
    const [showPopup,setShowPopup] = useState(true);
    const {handleKeyDown} = useHandleEnter();

    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }
    
    // Detects if device is on Android
    const isAndroid = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /android/i.test( userAgent );
    }

    useEffect(()=>{
        if (matchMedia('(display-mode: standalone)').matches || navigator && navigator.standalone) {  
            setShowPopup(false);
        } 
    },[]);
    
    function handleClick() {

        var phoneformat = /^([6-9])(\d{9})$/;
        var phoneError = "Phone number cannot be empty";
        var phonevalueError = "Please provide a valid phone number";
        var input = document.getElementById("phone-number");
        var phone_error = document.getElementById("phoneerror");
        let phone = '+91' + input.value;
        let role = 'default';
        if(input.value.length == 0 )
        {
            phone_error.style.display = "inline";
            input.classList.add("invalid");
            phone_error.innerHTML = phoneError;
        }
        else if(true || input.value.match(phoneformat))
        {
            phone_error.style.display = "none";
            input.classList.remove("invalid");
            
            authenticationService.generateOTP(phone, role).then(data => {
                setOtp(data);
            });
        } 
        else 
        {
            phone_error.style.display = "inline";
            input.classList.add("invalid");
            phone_error.innerHTML = phonevalueError; 
        }
    }

    if (auth && auth.user && auth.user.role) {
        if(auth.user.role == authenticationService.config.ROLES.ADMIN) {
            navigate('/home', { replace: true });
        }
        else {
            if (auth.user.auth_id)
                navigate('/home', { replace: true });
            else
                navigate('/register', { replace: true });
        } 
            
    }
    else if(auth && auth.user && !auth.user.role) {
        navigate('/select-role', { replace: true });
    }
    else {
        return otp ? (<AuthRoute>
            <GetOtp phoneNumber={otp.phone_number} sessionId={otp.otp_session_id}></GetOtp>
            </AuthRoute>
        ) : (
            <AuthRoute>
                <div className="introduction">
                    {/* <div className="logo">
                        <img className='logo_image' src={logo} alt="logoImage"></img>
                    </div> */}
                    <div className='login-logo'>
                        <img className='logo_image' src={logo} alt="logoImage"></img>
                    </div>
                    <div className='form-container'>
                        <div className='form-element'>
                            <label>10-digit Phone Number</label>
                            <input type="tel" id="phone-number" className="inputField" onKeyDown={(e) => handleKeyDown(e,handleClick)} placeholder="+91   Enter phone number" required />
                            <span className="field-error" id="phoneerror"></span>
                        </div>
                        <button className="getOtp-btn" onClick={handleClick} >GET OTP</button>
                    </div>
                    {
                       (
                            (isIos() && showPopup) &&  <IosScreenPopup />
                            ||
                            (isAndroid() && showPopup) && <AndroidScreenPopup />
                       )    
                    }
                </div>
            </AuthRoute>
        )
        
    }

    
}

export default Login;
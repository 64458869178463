import React, { useState, useEffect, useRef } from 'react';
import './PatientEditform.scss';
import Resource from '../../../services/resource.service';
import { useAuth } from '../../../services/use-auth';
import Button from '../../Button/Button';

const PatientEditForm = ({rowDetail}) => {
    const selectedGender = useRef('');
    const [selectedProgramDuration, setSelectedProgramDuration] = useState(rowDetail.program_info.payment_schedule);
    const auth = useAuth();
    const [clinic, setClinic] = useState(rowDetail.doctor_id);
    const [selectedProgramId, setSelectedProgramId] = useState(rowDetail.program_info.program_id);
    const [programs, setPrograms] = useState([]);
    
    const [careManagerId, setCareManagerId] = useState(rowDetail.care_manager_id);
    const [careManagers, setCareManagers] = useState([]);

    const selectedProgramRef = useRef({
        quote: rowDetail.program_info.quote,
        currency: rowDetail.program_info.currency,
        discount: rowDetail.program_info.discount
    });

    const [programQuote, setProgramQuote] = useState(rowDetail.program_info.quote);
    const [programDiscount, setProgramDiscount] = useState(rowDetail.program_info.discount);

    useEffect(() => {
        if(rowDetail.role == 'patient') {
            Resource.getUsers(Resource.ROLES.CARE_MANAGER).then((response) => {
                if(response.success)
                    setCareManagers(response.data);
            });
        }
    }, []);

    useEffect(() => {
        if(clinic) {
            Resource.getPrograms({doctor_id: clinic}).then((resp) => {
                if(resp.success) {
                    setPrograms(resp.data);
                }
            });
        }
    }, [clinic]);

    useEffect(() => {
        console.log(selectedProgramDuration, selectedProgramId);
        if(selectedProgramDuration && selectedProgramId) {
            for(let idx in programs) {
                let program = programs[idx];
                if(program.id == selectedProgramId) {
                    selectedProgramRef.current = {
                        quote: program.metainfo.payment_schedule[selectedProgramDuration].quote,
                        currency: program.metainfo.payment_schedule[selectedProgramDuration].currency,
                        discount: program.metainfo.payment_schedule[selectedProgramDuration].discount
                    }
                    setProgramQuote(selectedProgramRef.current.quote);
                    setProgramDiscount(selectedProgramRef.current.discount);
                    document.getElementById('p-discount').value = program.metainfo.payment_schedule[selectedProgramDuration].discount;
                    break;
                }
            }   
        }
        console.log(selectedProgramDuration, selectedProgramId);
        
    }, [selectedProgramId, selectedProgramDuration]);

    useEffect(() => {
        selectedProgramRef.current.discount = (programDiscount >= 0) ? programDiscount : 0;
    }, [programDiscount]);

    const handleGenderRadioButton = (e) => {
        let currentValue = e.target.value;
        selectedGender.current = currentValue;
        console.log(selectedGender);
    }
    const handleProgramDurationRadBtn = (e) => {
        let currentValue = e.target.value;
        //if(e.target.checked)
        setSelectedProgramDuration(currentValue);
        console.log(currentValue);
    }

    const updatePatient = () => {
        //e.preventDefault();
        console.log(selectedProgramDuration, selectedProgramId);
        
        let Error = "This field cannot be empty";
        // let emailvalueError = "Please provide a valid email-id";
        let namevalueError = "Please provide a name";
        let phonevalueError = "Please provide a 10 digit valid phone number";
        let agevalueError = "Please provide a age between 18 and 99";
        let phoneformat = /^\+(91)([6-9])(\d{9})$|^([6-9])(\d{9})$/;

        let name = document.getElementById('p-name');
        let phoneNumber = document.getElementById('p-phone');
        let clinciName = document.getElementById('p-clinicName');
        let age = document.getElementById('p-age');
        let programReferred = document.getElementById('p-programReferred');
        let genderRadioGroup = document.getElementById('genderRadioGroup');
        let programDurationRadioGroup = document.getElementById('programDurationRadioGroup');

        let nameerror = document.getElementById('nameerror');
        let phoneerror = document.getElementById('phoneerror');
        let clinicNameerror = document.getElementById('clinicNameerror');
        let ageerror = document.getElementById('ageerror');
        let gendererror = document.getElementById('gendererror');
        let programReferrederror = document.getElementById('programReferrederror');
        let programDurationerror = document.getElementById('durationerror');

        console.log('updatePatient', phoneNumber.value.replace('+91', '').match(phoneformat));

        if (name.value || age.value || phoneNumber.value.replace('+91', '') || clinciName.value || programReferred.value || selectedGender !== '' || selectedProgramDuration.current !== ''  ) {
            if (name.value.length > 0) {
                name.classList.remove('invalid');
                nameerror.style.display = "none";
                        
                if (age.value >= 9 ) {
                    ageerror.style.display = "none";
                    age.classList.remove("invalid");
                        
                    if(selectedGender){
                        gendererror.style.display = 'none';
                        if (phoneNumber.value.replace('+91', '') && phoneNumber.value.replace('+91', '').match(phoneformat)) {
                            phoneNumber.classList.remove('invalid');
                            phoneerror.style.display = "none";
                            if (rowDetail.doctor_id) {
                                if (programReferred.value !== '') {
                                    programReferrederror.style.display = "none";
                                    programReferred.classList.remove("invalid");
                                    console.log(selectedProgramDuration);
                                    if(selectedProgramDuration){
                                        
                                        programDurationerror.style.display = 'none';
                                        let obj = {};
                                        let type = 'lead';
                                        let id;

                                        if(rowDetail.role == 'patient') {
                                            obj.first_name = name.value.trim().split(' ')[0];
                                            obj.last_name = name.value.trim().replace(obj.first_name, '').trim();
                                            obj.contact_number = '+91' + phoneNumber.value.replace('+91', '').replace(/\D/g, "");
                                            obj.role = 'patient';
                                            type = 'patient';
                                            id = rowDetail.auth_id;
                                            obj.care_manager_id = careManagerId;
                                        }
                                        else if(rowDetail.status == 'Lead') {
                                            obj.name = name.value;
                                            obj.phone_number = '+91' + phoneNumber.value.replace('+91', '').replace(/\D/g, "");
                                            id = rowDetail.lead_id;
                                            obj.doctor_id = clinic;

                                            type = 'lead';
                                            obj.program_id = programReferred.value;
                                            obj.payment_schedule = selectedProgramDuration;
                                            obj.program_quote = selectedProgramRef.current.quote;
                                            obj.program_discount = selectedProgramRef.current.discount;
                                        
                                        }

                                        obj.sex = selectedGender.current.value;
                                        obj.age = age.value;
                                        console.log(obj,'obj');
                                        //closeModal();
                                        return Resource.updateUser(type, id, obj).then((resp) => {
                                            if(resp.success) {
                                                return true;
                                            }
                                            else {
                                                return false;
                                            }
                                        });
                                    }
                                    else{
                                        programDurationerror.style.display = "inline";
                                        programDurationerror.innerHTML = Error;

                                        return new Promise( () => {return false} );
                                    } 
                                } 
                                else {
                                    programReferred.classList.add('invalid');
                                    programReferrederror.style.display = "inline";
                                    programReferrederror.innerHTML = Error;

                                    return new Promise( () => {return false} );
                                }    
                            }
                            else {
                                return new Promise( () => {return false} );
                            }
                        }
                        else {
                            phoneerror.style.display = "inline";
                            phoneNumber.classList.add("invalid");
                            phoneerror.innerHTML = phonevalueError;

                            return new Promise( () => {return false} );
                        }
                    }
                    else{
                        gendererror.style.display = "inline";
                        gendererror.innerHTML = Error;

                        return new Promise( () => {return false} );
                    }
                }
                else {
                    ageerror.style.display = "inline";
                    age.classList.add("invalid");
                    ageerror.innerHTML = agevalueError;

                    return new Promise( () => {return false} );
                }
            }
            else{
                nameerror.style.display = "inline";
                name.classList.add("invalid");
                nameerror.innerHTML = namevalueError;

                return new Promise( () => {return false} );
            } 
        }
        else {
            nameerror.style.display = "inline";
            name.classList.add("invalid");
            nameerror.innerHTML = namevalueError;

            ageerror.style.display = "inline";
            age.classList.add("invalid");
            ageerror.innerHTML = agevalueError;

            phoneNumber.classList.add('invalid');
            phoneerror.style.display = "inline";
            phoneerror.innerHTML = Error;

            programReferred.classList.add('invalid');
            programReferrederror.style.display = "inline";
            programReferrederror.innerHTML = Error;

            gendererror.style.display = "inline";
            gendererror.innerHTML = Error;

            programDurationerror.style.display = "inline";
            programDurationerror.innerHTML = Error;

            return new Promise( () => {return false} );
        }    
    }
    
    useEffect(() => {
        rowDetail.updateUser = updatePatient;

        return () => {
            delete rowDetail.updateUser;
        };

    });

    return (
        <div className="addPatientContainer">
            
                <div className='addPatientheader'>
                    <h1 className="title">Patient Details</h1>
                </div>
                
                <form autoComplete="disabled"  className="registerForm">
                    <div className='form-element'>
                        <label>Doctor</label>
                        <select disabled={rowDetail.doctor_id ? true : false} id="p-doctorName" className="inputField" placeholder='Select Doctor' required>
                            <option selected={true} value={0}>{'Dr. ' + rowDetail.doctor_first_name + ' ' + rowDetail.doctor_last_name}</option>
                        </select>
                        <span className="field-error" id="doctorNameerror"></span>
                    </div>
                    {
                    rowDetail.clinic_name &&
                    <div className='form-element'>
                        <label>Clinic Name</label>
                        <select disabled={rowDetail.doctor_id ? true : false} id="p-clinicName" value={clinic} onChange={(e)=>setClinic(e.target.value)} defaultValue={clinic} className="inputField" placeholder='Select Clinic' required>
                            <option selected={true} value={rowDetail.doctor_id}>{rowDetail.clinic_name}</option>
                        </select>
                        <span className="field-error" id="clinicNameerror"></span>
                    </div>
                    }
                    {
                    rowDetail.role == 'patient' &&
                    <div className='form-element'>
                        <label>Care Manager</label>
                        <select id="p-careManager" value={careManagerId} onChange={(e)=>setCareManagerId(e.target.value)} defaultValue={careManagerId} className="inputField" placeholder='Select Care Manager'>
                            <option value="">-- Select Care Manager --</option>
                                {
                                    careManagers && careManagers.map((careManager, idx) => {
                                        return <option key={careManager.auth_id} value={careManager.auth_id}>{'Dr. ' + careManager.first_name + ' ' + careManager.last_name}</option>
                                    })
                                }
                        </select>
                        <span className="field-error" id="careManagererror"></span>
                    </div>
                    }
                    <div className='form-element'>
                        <label>Name</label>
                        <input defaultValue={rowDetail.first_name + ' ' + rowDetail.last_name} type="text" id="p-name" className="inputField" placeholder="Enter patient's name" required />
                        <span className="field-error" id="nameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Age</label>
                        <input defaultValue={rowDetail.age} type="number" id="p-age" className="inputField" placeholder="Enter patient's age" required />
                        <span className="field-error" id="ageerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Gender</label>
                        <div className='radioButtonGroup'>
                                <label>
                                    <input ref={selectedGender} defaultChecked={rowDetail.sex == 'M'} type="radio" id="male" name="gender" value="M" onClick={(e)=>handleGenderRadioButton(e)} />Male
                                </label>
                            </div>
                            <div className='radioButtonGroup'>
                                <label>
                                    <input ref={selectedGender} defaultChecked={rowDetail.sex == 'F'} type="radio" id="female" name="gender" value="F" onClick={(e)=>handleGenderRadioButton(e)} />Female
                                </label>
                            </div>
                        <span className="field-error" id="gendererror"></span>
                    </div>

                    <div className='form-element'>
                        <label>10-digit Phone Number</label>
                        <input autoComplete="disabled"  defaultValue={rowDetail.username ? rowDetail.username : rowDetail.contact_number} type="tel" id="p-phone" className="inputField" placeholder="Enter phone number" required />
                        <span className="field-error" id="phoneerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Program Referred</label>
                        <select disabled={rowDetail.role === 'patient' ? true : false} id="p-programReferred" className="inputField" placeholder='Select Program' value={selectedProgramId} onChange={(e)=>setSelectedProgramId(e.target.value)} required>
                            <option value="">-- Select Program --</option>
                            {
                                programs && programs.map((program, idx) => {
                                    return <option key={program.id} value={program.id}>{program.title}</option>
                                })
                            }
                        </select>
                        <span className="field-error" id="programReferrederror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Payment Schedule</label>
                        <div className='radioButtonGroup'>
                            <label>
                                <input disabled={rowDetail.role === 'patient' ? true : false} defaultChecked={selectedProgramDuration === "3m"} type="radio" id="threemnthDuration" name="paymentSchedule" value="3m" onClick={(e)=>handleProgramDurationRadBtn(e)} />3 Months
                            </label>
                        </div>
                        <div className='radioButtonGroup'>
                            <label>
                                <input disabled={rowDetail.role === 'patient' ? true : false} defaultChecked={selectedProgramDuration === "monthly"} type="radio" id="monthlySchedule" name="paymentSchedule" value="monthly" onClick={(e)=>handleProgramDurationRadBtn(e)} />Monthly
                            </label>
                        </div>
                        <span className="field-error" id="durationerror"></span>
                    </div>
                    {
                        selectedProgramId && selectedProgramDuration &&
                        <>
                        <div className='form-element'>
                            <label>Program Quote</label>
                            <input value={selectedProgramRef.current.currency + ' ' + programQuote + ' ' + (selectedProgramDuration == '3m' ? 'for 3 months' : 'monthly')}
                            disabled type="text" id="p-quote" className="inputField" placeholder="Enter quote" required />
                            <span className="field-error" id="quoteerror"></span>
                        </div>
                        <div className='form-element'>
                            <label>Program Discount</label>
                            <input min="0" onChange={(e) => setProgramDiscount(e.target.value ? parseInt(e.target.value, 10) : 0)} value={programDiscount} type="number" id="p-discount" className="inputField" placeholder="Enter discount" required />
                            <span className="field-error" id="discounterror"></span>
                        </div>
                        <label> Patient pays - <strong>{selectedProgramRef.current.currency + ' ' + (programQuote - programDiscount) + ' ' + (selectedProgramDuration == 'monthly' ? 'monthly' : '')}</strong></label>
                        <div className='form-element'>
                            <label>▁▁▂▁▁▁▁▁▂▁▁▁▁▁▂▁▁▁▁▁▂▁▁▁▁</label>
                        </div>
                        </>
                    }
                </form>
        </div>
    );
}
export default PatientEditForm;
import React from 'react';
import logo from './../../../images/md+.png';
import './AndroidScreenPopup.scss';

const AndroidScreenPopup = () => {
    return (
        <div className="Androidpopup"  id="banner"  >
            <div className="Androidpopup-content" > 
                <div className="popup-section">
                    <div className='popup-logo'>
                       <img className="logo" src={logo} alt="image" />  
                    </div>
                    <p className="popup-message">Tap "Install" to add it to the Home screen</p>
                    <svg className='popup-close-icon' id="cancel-install-app" onClick={closePopup} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
                </div> 
                <div className='installBtnDiv'>
                    <button id="install-app" className="installBtn" >Install</button>
                </div>  
            </div>
        </div>
    );

    function closePopup() {
        const popUp = document.getElementsByClassName("Androidpopup")[0];
        popUp.style.display = "none";
    }   
}
export default AndroidScreenPopup;
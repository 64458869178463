import React,{useState,useEffect} from 'react';
import QRCode  from 'qrcode.react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import './AssistantPatientProfile.scss';
import Resource from '../../../services/resource.service';
import { useRef } from 'react';

const AssistantPatientProfile = ({patientInfo}) => {
    console.log(patientInfo);

    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState("QRcode");
    const [showQrCode, setShowQrCode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [discount, setDiscount] = useState('');
    const [discountedPay, setDiscountedPay] = useState('');
    const [showDiscountInput, setShowDiscountInput] = useState(false);
    const [disableDiscountSubmitBtn,setDisableDiscountSubmitBtn] = useState(true);
    
    const paymentUrl = useRef();
    const discountErrorRef = useRef();
    const discountRef = useRef();

    const displayPaymentOptions = () => {
        setShowPaymentOptions(true);
    }

    const closeModal = () => {
        //setShowPaymentOptions(false);
        setShowPaymentOptions(false);
        setShowQrCode(false);
        setSelectedPaymentOption('QRcode');
    }

    const handleContinueBtnClick = () => {
        Resource.generatePayment({
            order_id: patientInfo.program_info.order_id,
            option: selectedPaymentOption,
            status: patientInfo.status
        }).then((response) => {
            if(response.success) {
                if(selectedPaymentOption == 'paymentLink') {
                    closeModal();
                }
                else {
                    paymentUrl.current = response.data.payment_link;
                    setShowQrCode(true);
                }
            }
            
        });
    }

    const handleRadioButton = (e) => {
        let currentValue = e.target.value;
        setSelectedPaymentOption(currentValue);
        console.log(currentValue);
    }
     
    const showChatBox = () => {

    }

    const submitDiscount = () => {
        setDiscountedPay(discountedPay);
        setDiscount(discountRef.current.value);
        setShowDiscountInput(false);
        setDisableDiscountSubmitBtn(true);
    }
    
    const handleShowDiscountInput = () => {
        setShowDiscountInput(!showDiscountInput);
        if (showDiscountInput === true){
          if(discountRef.current.value !== discount){
            setDiscount(discount);
            setDiscountedPay(discountedPay);
          }
        }
    }
    
    const handleDiscountInput = (e) => {
        let currentDiscountValue = e.target.value;
        let quote = patientInfo.program_info.quote;
        let discountError = document.querySelector('#discountError');
    
        if(currentDiscountValue >= quote){
          discountError.style.display = "inline";
          discountError.innerHTML = 'Enter discount below quote';
          setDiscountedPay(discountedPay);
          setDisableDiscountSubmitBtn(true);
        }
        else if (currentDiscountValue == ''){
          discountError.style.display = "inline";
          discountError.innerHTML = 'Discount cannot be empty';
          setDiscountedPay(discountedPay);
          setDisableDiscountSubmitBtn(true);
        }else{
          let pay = quote - currentDiscountValue;
          discountError.style.display = 'none';
          setDiscountedPay(pay);
          setDisableDiscountSubmitBtn(false);
        }
        console.log(currentDiscountValue, "currentDiscountValue");
    }

    const setDimension = () => {
        setScreenSize(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    useEffect(()=>{
        let quote = patientInfo.program_info.quote;
        let discount = patientInfo.program_info.discount;
        let discountedPay = quote - discount;
        setDiscount(discount);
        setDiscountedPay(discountedPay);
    },[patientInfo]);

    return (
        <>
            <div className='Patient_profile_container'>
                <div className='patient_profile_header'>
                    <h2 className='patient_name'>{patientInfo && patientInfo.name}</h2>
                    <h4 className='patient_status'><span className="activeDot"></span>{patientInfo && patientInfo.status}</h4>
                </div>
                <div className='assistant_patient_details_container'>
                    <div className='assistant_patient_details_block'>
                        <h6 className='patient_heading'>Age</h6>
                        <span className='patient_value'>{patientInfo && patientInfo.age ? patientInfo.age :'-'}</span>
                    </div>
                    <div className='assistant_patient_details_block'>
                        <h6 className='patient_heading'>Gender</h6>
                        <span className='patient_value'>{patientInfo && patientInfo.sex ? patientInfo.sex :'-'}</span>
                    </div>
                    <div className='assistant_patient_details_block'>
                        <h6 className='patient_heading'>Doctor</h6>
                        <span className='patient_value'>{
                           patientInfo && patientInfo.doctor_name
                        }</span>
                    </div>
                    {/*<div className='patient_cohort_start_date'>
                        <h6 className='patient_heading'>Cohort Start Date</h6>
                        <span className='patient_value'>{patientInfo.cohortStartDate}</span>
                    </div>*/}
                    <div className='assistant_patient_details_block'>
                        <h6 className='patient_heading'>Care Manager</h6>
                        <span className='patient_value'>{patientInfo && patientInfo.status !== 'New' ? 'Dr. ' + patientInfo.care_manager_name : '-'}</span>
                    </div>
                    {/*<div className='patient_relationship_status'>
                        <h6 className='patient_heading'>Relationship Status</h6>
                        <span className='patient_value'>Female</span>
                    </div> */}
                </div>
                {/*<div className='care_manager_and_cohortDate'>
                    <div className='patient_assistant_care_manager'>
                        <h6 className='patient_heading'>Assigned Care Manager</h6>
                        <span className='patient_value'>Swetha Ramakrishnan 
                            <span className='patient_message' onClick={showChatBox}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 20.0001L4.3 16.1001C3.17644 14.4384 2.76999 12.4705 3.15622 10.5624C3.54244 8.65427 4.69506 6.93575 6.39977 5.72635C8.10447 4.51696 10.2453 3.89898 12.4241 3.98732C14.6029 4.07566 16.6715 4.86431 18.2453 6.20664C19.819 7.54896 20.7909 9.35362 20.9801 11.2851C21.1693 13.2165 20.563 15.1433 19.2739 16.7072C17.9848 18.2711 16.1007 19.3657 13.9718 19.7874C11.8429 20.2092 9.6142 19.9294 7.7 19.0001L3 20.0001" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M12 12V12.01" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M8 12V12.01" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M16 12V12.01" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span> 
                        </span>
                    </div>
                    <div className='patient_cohort_start_date'>
                        <h6 className='patient_heading'>Cohort Start Date</h6>
                        <span className='patient_value'>25/12/2021</span>
                    </div>
                </div>*/}
                {
                    screenSize >= 480 &&
                        <Button text={'Collect Payment'} buttonType={'primary_btn'} onclick={displayPaymentOptions} />
                }
                {
                    showPaymentOptions ? 
                    <Modal {...(screenSize <= 500 && {position:'bottom'})} closeModal={closeModal}>
                        <div className='assistant_payment_options_container'>
                            {
                                showQrCode ? 
                                <div className='assistant_qr_container'>
                                    <div className='header'>
                                        <Button buttonType={'icon_btn'} onclick={()=>{setShowQrCode(false)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#070708" fill="none" strokeLinecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="5" y1="12" x2="19" y2="12" />
                                            <line x1="5" y1="12" x2="9" y2="16" />
                                            <line x1="5" y1="12" x2="9" y2="8" />
                                            </svg>
                                        </Button>
                                        <Button buttonType={'icon_btn'} onclick={closeModal}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="18" y1="6" x2="6" y2="18" />
                                            <line x1="6" y1="6" x2="18" y2="18" />
                                            </svg>
                                        </Button>
                                    </div>
                                    <div className='qr_code'>
                                        {/*<QRCode  
                                           value={"https://reactjs.org/"} 
                                           size={200}
                                        />*/}
                                        <img src={paymentUrl.current} size={300}/>
                                    </div>
                                    <div className='qr_content'>
                                        <p>Scan the above QRcode to continue Payment</p>
                                    </div>
                                </div>
                                :
                                <>
                                 <div className='patient_payment_container'>
                                    <div className="patient_payment_block">
                                    <div className="patient_payment_content">
                                        <div className="patient_pays">Patient Pays INR</div>
                                        <div className="patient_pays_amount"> {discountedPay} </div>
                                    </div>
                                    {
                                        showDiscountInput ? <Button text={'Cancel'} color={'#d34040'} buttonType={'tertiary_btn'} textSize={'regular'} fontWeight={'medium'} size={'small'} onclick={handleShowDiscountInput}></Button>
                                        :
                                        <Button text={'Edit'} buttonType={'tertiary_btn'} textSize={'regular'} fontWeight={'medium'} size={'small'} onclick={handleShowDiscountInput}></Button>
                                    }
                                    </div>
                                    {
                                    showDiscountInput &&
                                    <>
                                        <div className="discount_input_container">
                                            <div className="discount_input">
                                            <label>Discount</label>
                                            <input ref={discountRef} type="number" id="dicount-input" className="inputField" defaultValue={discount} onChange={handleDiscountInput} required />
                                            </div>
                                            <Button disable={disableDiscountSubmitBtn} size='small' buttonType={'primary_btn'} onclick={submitDiscount}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M5 12l5 5l10 -10" />
                                            </svg>
                                            </Button>                        
                                        </div>
                                        <span className="field-error" ref={discountErrorRef} id="discountError"></span>
                                    </>
                                    }
                                </div>
                                <div className='payment_option_container'>
                                    <h4 className="payment_option_title">Select Payment Option</h4>
                                    <div className='radioButtonGroup'>
                                        <label>
                                            <input type="radio" id="paymentLink" name="paymentOption" value="paymentLink" onChange={(e)=>handleRadioButton(e)} checked={selectedPaymentOption === "paymentLink"} />Send Payment Link
                                        </label>
                                    </div>
                                    <div className='radioButtonGroup'>
                                        <label>
                                            <input type="radio" id="QRcode" name="paymentOption" value="upi_qr" onChange={(e)=>handleRadioButton(e)} checked={selectedPaymentOption === "upi_qr"} />UPI QR Code
                                        </label>
                                    </div>
                                </div>
                                <div className='continue_btn'>
                                    <Button buttonType={'primary_btn'} text={'Continue'} onclick={handleContinueBtnClick}/>
                                </div>
                                </>
                            }
                        </div>

                    </Modal> : <></>  
                }
            </div>
            {
                    screenSize <= 480 ? 
                    <div className='bottomElement buttonWrapper'>
                        <Button text={'Collect Payment'} buttonType={'primary_btn'} onclick={displayPaymentOptions} />
                    </div>
                    :''
            }
        </>
    )
}

export default AssistantPatientProfile;
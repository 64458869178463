import React,{useState} from 'react';
import { useEffect, useRef } from 'react';
import Resource from '../../services/resource.service';
import { useAuth } from '../../services/use-auth';
import Button from '../Button/Button';
import './AddPatientForm.scss';

const AddPatientForm = ({closeModal}) => {
    const [selectedGender, setSelectedGender] = useState("");
    const [selectedProgramDuration, setSelectedProgramDuration] = useState("");
    const auth = useAuth();

    const clinics = useRef(auth.user.clinics);
    const programs = useRef([]);

    useEffect(() => {
        switch(auth.user.role) {
            case Resource.ROLES.DOCTOR:
                clinics.current = auth.user.clinics;
                break;
            case Resource.ROLES.ASSISTANT:
                clinics.current = auth.user.clinics;
                break;
            case Resource.ROLES.CARE_MAAGER:
                clinics.current = '';
                break;
            case Resource.ROLES.ADMIN:
                clinics.current = '';
                break;
        }
    }, []);

    const handleGenderRadioButton = (e) => {
        let currentValue = e.target.value;
        setSelectedGender(currentValue);
    }
    const handleProgramDurationRadBtn = (e) => {
        let currentValue = e.target.value;
        setSelectedProgramDuration(currentValue);
    }

    function addPatient(e) {
        e.preventDefault();
        let Error = "This field cannot be empty";
        // let emailvalueError = "Please provide a valid email-id";
        let namevalueError = "Please provide a name";
        let phonevalueError = "Please provide a 10 digit valid phone number";
        let agevalueError = "Please provide a age between 18 and 99";
        let phoneformat = /^([6-9])(\d{9})$/;

        let name = document.getElementById('p-name');
        let phoneNumber = document.getElementById('p-phone');
        let clinciName = document.getElementById('p-clinicName');
        let age = document.getElementById('p-age');
        let programReferred = document.getElementById('p-programReferred');
        let genderRadioGroup = document.getElementById('genderRadioGroup');
        let programDurationRadioGroup = document.getElementById('programDurationRadioGroup');

        let nameerror = document.getElementById('nameerror');
        let phoneerror = document.getElementById('phoneerror');
        let clinicNameerror = document.getElementById('clinicNameerror');
        let ageerror = document.getElementById('ageerror');
        let gendererror = document.getElementById('gendererror');
        let programReferrederror = document.getElementById('programReferrederror');
        let programDurationerror = document.getElementById('durationerror');

        if (name.value || age.value || phoneNumber.value || clinciName.value || programReferred.value || selectedGender !== '' || selectedProgramDuration !== ''  ) {
            if (name.value.length > 0) {
                name.classList.remove('invalid');
                nameerror.style.display = "none";
                if (age.value >= 9 ) {
                    ageerror.style.display = "none";
                    age.classList.remove("invalid");
                    if(selectedGender !== ''){
                        gendererror.style.display = 'none';
                        if (phoneNumber.value !== '' && phoneNumber.value.match(phoneformat)) {
                            phoneNumber.classList.remove('invalid');
                            phoneerror.style.display = "none";
                            if (clinciName.value !== '') {
                                clinicNameerror.style.display = "none";
                                clinciName.classList.remove("invalid");
                                if (programReferred.value !== '') {
                                    programReferrederror.style.display = "none";
                                    programReferred.classList.remove("invalid");
                                    if(selectedProgramDuration !== ''){
                                        programDurationerror.style.display = 'none';
                                        let obj = {
                                            name:'',
                                            phoneNumber:'',
                                            age:'',
                                            clinciName:'',
                                            programReferred:'',
                                            gender:'',
                                            programDuration:''
                                        };
                                        obj.name = name.value;
                                        obj.phoneNumber = phoneNumber.value;
                                        obj.age = age.value;
                                        obj.clinciName = clinciName.value;
                                        obj.programReferred = programReferred.value;
                                        obj.gender = selectedGender;
                                        obj.programDuration = selectedProgramDuration;
                                        console.log(obj,'obj');
                                        closeModal();
                                    }
                                    else{
                                        programDurationerror.style.display = "inline";
                                        programDurationerror.innerHTML = Error;
                                    } 
                                } 
                                else {
                                    programReferred.classList.add('invalid');
                                    programReferrederror.style.display = "inline";
                                    programReferrederror.innerHTML = Error;
                                }    
                            }
                            else {
                                clinciName.classList.add('invalid');
                                clinicNameerror.style.display = "inline";
                                clinicNameerror.innerHTML = Error;
                            }
                        }
                        else {
                            phoneerror.style.display = "inline";
                            phoneNumber.classList.add("invalid");
                            phoneerror.innerHTML = phonevalueError;
                        }
                    }
                    else{
                        gendererror.style.display = "inline";
                        gendererror.innerHTML = Error;
                    }
                }
                else {
                    ageerror.style.display = "inline";
                    age.classList.add("invalid");
                    ageerror.innerHTML = agevalueError;
                }
            }
            else{
                nameerror.style.display = "inline";
                name.classList.add("invalid");
                nameerror.innerHTML = namevalueError;
            } 
        }
        else {
            nameerror.style.display = "inline";
            name.classList.add("invalid");
            nameerror.innerHTML = namevalueError;

            ageerror.style.display = "inline";
            age.classList.add("invalid");
            ageerror.innerHTML = agevalueError;

            phoneNumber.classList.add('invalid');
            phoneerror.style.display = "inline";
            phoneerror.innerHTML = Error;

            clinciName.classList.add('invalid');
            clinicNameerror.style.display = "inline";
            clinicNameerror.innerHTML = Error;

            programReferred.classList.add('invalid');
            programReferrederror.style.display = "inline";
            programReferrederror.innerHTML = Error;

            gendererror.style.display = "inline";
            gendererror.innerHTML = Error;

            programDurationerror.style.display = "inline";
            programDurationerror.innerHTML = Error;
        }    
    }
    
    return (
        <div className="addPatientContainer">
                <div className='addPatientheader'>
                    <h1 className="title">Patient Details</h1>
                    <svg onClick={closeModal} className='close-btn' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M6 6L18 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                
                <form className="registerForm">
                    <div className='form-element'>
                        <label>Name</label>
                        <input type="text" id="p-name" className="inputField" placeholder="Enter patient's name" required />
                        <span className="field-error" id="nameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Age</label>
                        <input type="number" id="p-age" className="inputField" placeholder="Enter patient's age" required />
                        <span className="field-error" id="ageerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Gender</label>
                        <div className='radioButtonGroup'>
                                <label>
                                    <input type="radio" id="male" name="gender" value="male" onChange={(e)=>handleGenderRadioButton(e)} checked={selectedGender === "male"} />Male
                                </label>
                            </div>
                            <div className='radioButtonGroup'>
                                <label>
                                    <input type="radio" id="female" name="gender" value="female" onChange={(e)=>handleGenderRadioButton(e)} checked={selectedGender === "female"} />Female
                                </label>
                            </div>
                        <span className="field-error" id="gendererror"></span>
                    </div>

                    <div className='form-element'>
                        <label>10-digit Phone Number</label>
                        <input autoComplete="disabled"  type="tel" id="p-phone" className="inputField" placeholder="Enter phone number" required />
                        <span className="field-error" id="phoneerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Clinic Name</label>
                        <select id="p-clinicName" className="inputField" placeholder='Select Clinic' required>
                            <option value="">-- Select Clinic --</option>
                            {
                                clinics.current && clinics.current.map((clinic, idx) => {
                                    return <option value={clinic.doctor_id}>{clinic.name}</option>
                                })
                            
                            }
                        </select>
                        <span className="field-error" id="clinicNameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Program Referred</label>
                        <select id="p-programReferred" className="inputField" placeholder='Select Status' required>
                            <option value="">-- Select Program --</option>
                            {
                                programs && programs.current.map((program, idx) => {
                                    return <option value={program.id}>{program.title}</option>
                                })
                            }
                        </select>
                        <span className="field-error" id="programReferrederror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Program Duration</label>
                        <div className='radioButtonGroup'>
                            <label>
                                <input type="radio" id="threemnthDuration" name="paymentSchedule" value="3 months" onChange={(e)=>handleProgramDurationRadBtn(e)} checked={selectedProgramDuration === "3 months"} />3 Months
                            </label>
                        </div>
                        <div className='radioButtonGroup'>
                            <label>
                                <input type="radio" id="sixmnthDuration" name="paymentSchedule" value="6 months" onChange={(e)=>handleProgramDurationRadBtn(e)} checked={selectedProgramDuration === "6 months"} />6 Months
                            </label>
                        </div>
                        <span className="field-error" id="durationerror"></span>
                    </div>
                    <div className='patientAddBtn'>
                        <Button text={'Add Patient'} onclick={(e)=>addPatient(e)} buttonType={'primary_btn'} />
                    </div>
                </form>
        </div>
    );
}

export default AddPatientForm;
import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import closeIcon from './../../../images/close.png';
import { Link, useLocation } from 'react-router-dom';
import './../Doctormodal/Doctormodal.scss';
import { useAuth } from './../../../services/use-auth';
import Resource from '../../../services/resource.service';
import Toast from '../../Toaster/Toast';
import Button from '../../Button/Button';
import App from './../../../App';
import DoctorForm from '../../RegisterForm/DoctorForm/DoctorForm';
import { useEffect } from 'react';

const Doctormodal = ({closeModal}) => {

    // function uploadImage(e) {
    //    console.log(e.target.files);
    // }

    const [practices, setPractices] = useState([]);
    const auth = useAuth();
    const location = useLocation();
    // const role = location.state.role;

    const [showClinicSuggestion , setShowClinicSuggestion] = useState(false);
    
    const [matchedClinicData,setMatchedClinicData] = useState([]);
    
    let searchClinicRef = useRef();
    let clinicSuggestionRef = useRef();
    
    useEffect(() => {
        Resource.getPractices().then((response) => {
            if(response.success)
                setPractices(response.data);
        });
    }, [auth.user])

    function getSelectedClinicSearchValue(item){
        console.log(item);
        setShowClinicSuggestion(false);
        searchClinicRef.current.value = item.name ;
        searchClinicRef.current.item = item;
        setMatchedClinicData([]);
    }

    function onChangeClinicSearchInput(e) {
        let value = e.target.value;
        
        if(value.length > 0){
            searchClinicRef.current.item = null;
            setShowClinicSuggestion(true);
            Resource.autocomplete({q: value, otype: 'clinic'}).then((response) => {
                if(response.success) {
                    setMatchedClinicData(response.data.matches);
                }
            });
        } else{
            setShowClinicSuggestion(false);
        }
    }

    const addDoctor = (e) => {
        e.preventDefault();
        var Error = "This field cannot be empty";
        // var emailvalueError = "Please provide a valid email-id";
        var namevalueError = Error;
        var phonevalueError = "Please provide a 10 digit valid phone number";
        // var agevalueError = "Please provide a age between 18 and 99";
        let phoneformat = /^([6-9])(\d{9})$/;

        var firstName = document.getElementById('d-fname');
        var lastName = document.getElementById('d-lname');
        var email = document.getElementById('d-email');
        var practice = document.getElementById('d-practice');
        var medicalcouncil = document.getElementById('d-council');
        var clinic = document.getElementById('d-clinic');
        var registernumber = document.getElementById('d-registernumber');
        var phonenumber = document.getElementById('assistant-ph');

        var firstnameerror = document.getElementById('firstnameerror');
        var lastnameerror = document.getElementById('lastnameerror');
        var practiceerror = document.getElementById('practiceerror');
        var clinicerror = document.getElementById('clinicerror');
        var councilerror = document.getElementById('councilerror');
        var registernumbererror = document.getElementById('registernumbererror');
        var phoneerror = document.getElementById('phoneerror');
        // var emailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (firstName.value || lastName.value || phonenumber.value || practice.value || clinic.value || medicalcouncil.value || registernumber.value ) {

            if (firstName.value.length > 0) {
                firstName.classList.remove('invalid');
                firstnameerror.style.display = "none";

                if (lastName.value.length > 0) {
                    lastName.classList.remove('invalid');
                    lastnameerror.style.display = "none";

                    if (phonenumber.value !== '' && phonenumber.value.match(phoneformat)) {
                        phoneerror.classList.remove('invalid');
                        phoneerror.style.display = "none";

                        if (practice.value !== '' ) {
                           practiceerror.style.display = "none";
                           practice.classList.remove("invalid");

                            if (clinic.value !== '') {
                                clinic.classList.remove('invalid');
                                clinicerror.style.display = "none";

                                if (medicalcouncil.value !== '' ) {
                                    councilerror.style.display = "none";
                                    medicalcouncil.classList.remove("invalid");

                                    if (registernumber.value !== '') {
                                        registernumbererror.style.display = "none";
                                        registernumber.classList.remove("invalid");
                                        var obj = {
                                            first_name:'',
                                            last_name:'',
                                            email:'',
                                            phone_number:'',
                                            role: Resource.ROLES.DOCTOR,
                                            metainfo: {
                                                medical_council: '',
                                                medical_registration_number: '',
                                                practice_id: ''
                                            }
                                        };
                                        obj.first_name = firstName.value;
                                        obj.last_name = lastName.value;
                                        obj.email = email.value;
                                        obj.phone_number = '+91' + phonenumber.value.replace(/\D/g, "");
                                        obj.metainfo.practice_id = practice.value;
                                        obj.metainfo.medical_council = medicalcouncil.value;
                                        obj.metainfo.medical_registration_number = registernumber.value;
                                        obj.contact_number = '+91' + phonenumber.value.replace(/\D/g, "");
                                        
                                        if(searchClinicRef.current.item) {
                                            obj.clinics = [searchClinicRef.current.item.id];
                                        }
                                        else {
                                            obj.metainfo.clinic_name = clinic.value;
                                        }
                                        
                                        console.log(obj);
                                        Resource.createUser(Resource.ROLES.DOCTOR, obj).then((resp) => {
                                            if(resp.success) {
                                                Toast.success("Doctor added successfully");
                                            }
                                        });
                                        // Resource.updateSelf(obj).then((resp) => {
                                        //     if(resp.success) {
                                        //         auth.getConfig({role: role}).then((resp) => {
                                        //             Toast.success("Registration form submitted successfully!!");  
                                        //         });
                                        //     }
                                        // });

                                    }
                                    else {
                                        registernumber.classList.add('invalid');
                                        registernumbererror.style.display = "inline";
                                        registernumbererror.innerHTML = Error;
                                    }
                                }    
                                else {
                                    councilerror.style.display = "inline";
                                    medicalcouncil.classList.add("invalid");
                                    councilerror.innerHTML = Error;
                                }    
                            }
                            else {
                                clinic.classList.add('invalid');
                                clinicerror.style.display = "inline";
                                clinicerror.innerHTML = Error;
                            }
                        }
                        else {
                            practiceerror.style.display = "inline";
                            practice.classList.add("invalid");
                            practiceerror.innerHTML = Error;
                        }
                    }
                    else {
                        phonenumber.classList.add('invalid');
                        phoneerror.style.display = "inline";
                        phoneerror.innerHTML = phonevalueError; 
                    }   
                }
                else {
                    lastnameerror.style.display = "inline";
                    lastName.classList.add("invalid");
                    lastnameerror.innerHTML = namevalueError;
                }     
            }
            else {
                firstnameerror.style.display = "inline";
                firstName.classList.add("invalid");
                firstnameerror.innerHTML = namevalueError;
            }
        }
        else {
            firstnameerror.style.display = "inline";
            firstName.classList.add("invalid");
            firstnameerror.innerHTML = namevalueError;

            lastnameerror.style.display = "inline";
            lastName.classList.add("invalid");
            lastnameerror.innerHTML = namevalueError;

            phoneerror.style.display = "inline";
            phonenumber.classList.add('invalid');
            phoneerror.innerHTML = Error;

            practiceerror.style.display = "inline";
            practice.classList.add("invalid");
            practiceerror.innerHTML = Error;

            clinic.classList.add('invalid');
            clinicerror.style.display = "inline";
            clinicerror.innerHTML = Error;

            medicalcouncil.classList.add('invalid');
            councilerror.style.display = "inline";
            councilerror.innerHTML = Error;

            registernumbererror.style.display = "inline";
            registernumber.classList.add("invalid");
            registernumbererror.innerHTML = Error;


        }       
    }
    
    return (
       <div className='doctor-modal'>

            <div className="add-doctor container">
                <div className='addDoctorContainer'>
                    <h1 className="title">Doctor Form</h1>
                    <Link to="/home"><img src={closeIcon} onClick={closeModal} alt="img" className='close-btn'  /></Link>
                </div>
                
                <form autoComplete="disabled"  className="registerForm addDoctor-form">
                    
                    <div className='form-element'>
                        <label>First Name</label>
                        <input type="text" id="d-fname" className="inputField" placeholder="Enter first name" required />
                        <span className="field-error" id="firstnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Last Name</label>
                        <input type="text" id="d-lname" className="inputField" placeholder="Enter last name" required />
                        <span className="field-error" id="lastnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Email</label>
                        <input autoComplete="disabled"  type="email" id="d-email" className="inputField" placeholder="Enter email address" />
                        <span className="field-error" id="emailerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>10-digit Phone Number</label>
                        <input autoComplete="disabled"  type="tel" id="assistant-ph" className="inputField" placeholder="Enter phone number" />
                        <span className="field-error" id="phoneerror"></span>
                    </div>

                    <div className='form-element clinicSearchGroup' >
                        <label>Clinic Name</label>
                        <input autoComplete="disabled" type="text" id="d-clinic" ref={searchClinicRef} className="inputField" placeholder="Enter clinic name" onChange={(e)=>onChangeClinicSearchInput(e)} required />
                        <span className="field-error" id="clinicerror"></span>
                        {
                            showClinicSuggestion ? 
                            <div className="suggestionBox" ref={clinicSuggestionRef}>
                                {
                                matchedClinicData.length > 0 ? matchedClinicData.map((item)=>{
                                        return  (
                                            <>
                                                <div className="searchSuggestion" onClick={()=>getSelectedClinicSearchValue(item)}>
                                                    <span className="searchValue">
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    }) :
                                    ''
                                }
                            </div>
                            :
                            ' '
                        }
                    </div>
                    
                    <div className='form-element'>
                        <label>Practicing As</label>
                        <select id="d-practice" className="inputField" placeholder='Select practice' required>
                            <option value="">-- Select Practice --</option>
                            {
                                practices.map((p) => {
                                    return <option value={p.id}>{p.name}</option>
                                })
                            }
                            <option value="other">Other</option>
                        </select>
                        <span className="field-error" id="practiceerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>Medical Council</label>
                        <select id="d-council" className="inputField" placeholder='Select medical council' required>
                            <option value="">-- Select Medical Council --</option>
                            <option value="NMC - National Medical Commission">NMC - National Medical Commission</option>
                            <option value="IMR - Indian Medical Register">IMR - Indian Medical Register</option>
                            <option value="State Medical Council">State Medical Council</option>
                            <option value="FOGSI">FOGSI</option>
                            <option value="other">Other</option>
                        </select>
                        <span className="field-error" id="councilerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>Medical Registration Number</label>
                        <input type="text" id="d-registernumber" className="inputField" placeholder="Enter registration number" required />
                        <span className="field-error" id="registernumbererror"></span>
                    </div>
                    <Button text={'Add Doctor'} buttonType={'primary_btn'} onclick={addDoctor} ></Button>
                </form>
            </div>
        </div>
    );

};

export default Doctormodal;
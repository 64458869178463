import { useState } from 'react';
import Toast from './../Toaster/Toast';
import './Support.scss';
import Button from './../Button/Button';
import Header from '../Header/Header';
import { useNavigate } from 'react-router-dom';
import Resource from '../../services/resource.service';

const Support = ()=>{

    const [supportText, setSupportText] = useState('');
    const navigate = useNavigate();
    const handleSubmit = () => {
        if(supportText.length !== 0) 
        {
            Resource.raiseTicket({query: supportText}, 'v2').then((response) => {
                if(response.success) {
                    setSupportText('');
                    Toast.success("Success: Your Query has been sent successfully!!");
                }
            });
        }
        else{
            Toast.error("Error: Please enter your Query!!");
        }
    }

    return (
        <div className="support-container">
            <div className="support-header">
                <Button buttonType={'icon_btn'} onclick={()=>{navigate('/')}}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#070708" fill="none" strokeLinecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="5" y1="12" x2="9" y2="16" />
                    <line x1="5" y1="12" x2="9" y2="8" />
                    </svg>
                </Button>
                <h1 className="title">Support</h1>
            </div>
            <div className="support-content">
                <label>How can we help you ?</label>
                <textarea id="supportTextArea" name="supportTextArea" rows="8" cols="50" value={supportText} onChange={(e)=> setSupportText(e.target.value)}></textarea>
                <div className='btn-holder'>
                    <Button text={'Submit'} buttonType={'primary_btn'} onclick={handleSubmit} />
                </div>
            </div>
        </div>
    );
}

export default Support;
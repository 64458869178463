import './ProfileView.scss';

import Resource from "../../../services/resource.service";
import AssistantList from "../AssistantList/AssitantList";
import dummyDp from './../../../images/dummy-dp.png';
import { useEffect, useRef, useState } from 'react';
import { beautifyPhoneNumber } from '../../../helpers/utils';
import { useAuth } from '../../../services/use-auth';
import { Link } from 'react-router-dom';

const ProfileView = ({ currentTab, rowData, renderAddAssistant }) => {
    const auth = useAuth();
    const [assistantList, setAssistantList] = useState([]);
    var display_pic = useRef(rowData.metainfo?.display_pic + '?d=' + Date.now());
    
    useEffect(() => {
        if(currentTab.resource == Resource.ROLES.DOCTOR) {
            Resource.getUsers(Resource.ROLES.ASSISTANT, rowData.id).then((response) => {
                if(response.success) {
                    let l = response.data;
                    l.sort((a, b) => (a.status == 'revoked' ? 1 : (b.status == 'revoked' ? -1 : (a.status == 'pending' ? -1 : 1))));
                    setAssistantList(l); 
                }
            });
        }
    }, [rowData, auth.user]);

    if(currentTab.resource == Resource.ROLES.DOCTOR) {
        return (
            <div className="profile-section">
                <div className="doctor-profile">
                    <div className="profile-pic"><img src={display_pic.current || dummyDp } /></div>
                    <div className="profile-info">
                        {
                        rowData.url ?
                        <div className="name"><Link target="_blank" to={rowData.url}>{'Dr. ' + rowData.first_name + ' ' + rowData.last_name}</Link></div>
                        :
                        <div className="name">{'Dr. ' + rowData.first_name + ' ' + rowData.last_name}</div>
                        }
                        <div className="practice">{rowData?.metainfo ? rowData?.metainfo?.practice_id : ''}</div>
                        <div className="clinic">{
                        rowData?.clinics && rowData.clinics.map((clinic) =>
                            {
                                return <>🏨 {clinic?.clinic_name} <br/></>
                            }
                        )}
                        </div>
                        <div className="contact">{beautifyPhoneNumber(rowData.contact_number || rowData.username)}</div>
                    </div>
                </div>
                <AssistantList assistant_list={assistantList} renderAddAssistant={renderAddAssistant} />
            </div>
      ); 
    }
    else if(currentTab.resource == Resource.ROLES.CARE_MANAGER) {
        return (
            <div className="profile-section">
                <div className="caremanager-profile">
                    <div className="profile-pic"><img src={rowData?.metainfo?.display_pic || dummyDp } /></div>
                    <div className="profile-info">
                        {
                        rowData.url ?
                        <div className="name"><Link target="_blank" to={rowData.url}>{'Dr. ' + rowData.first_name + ' ' + rowData.last_name}</Link></div>
                        :
                        <div className="name">{'Dr. ' + rowData.first_name + ' ' + rowData.last_name}</div>
                        }
                        <div className="practice">{rowData?.metainfo ? rowData?.metainfo?.practice_id : ''}</div>
                        <div className="clinic">{rowData?.metainfo?.clinic_name || ''}</div>
                        <div className="contact">{beautifyPhoneNumber(rowData.contact_number || rowData.username)}</div>
                    </div>
                </div>
            </div>
        );
    }
    else{
        return (
            <div className="profile-section">
                <div className="patient-profile">
                    <div className="profile-info">
                        <div className='name_and_status_group'>
                            {
                            rowData.url ?
                            <div className="name"><Link target="_blank" to={rowData.url}>{rowData.name ? rowData.name : rowData.first_name}</Link></div>
                            :
                            <div className="name">{rowData.name ? rowData.name : rowData.first_name}</div>
                            }
                            
                            <div className="type">{rowData.status}</div>
                        </div>
                        <div className="diagnosis">{rowData.program_info.program_title}</div>
                        <div className="contact">{beautifyPhoneNumber(rowData.contact_number || rowData.username)}</div>
                        {/* <div className="gender">{rowData.sex}</div> */}
                        <div className="program-referred"></div>
                        <div className="relationship-status"></div>
                        <div className="doctor-name">{rowData.doctor_name}</div>
                        <div className="clinic">{rowData.clinic_name}</div>
                    </div>
                </div>
            </div>
        )
    }
    // else{
    //     return (
    //         <div className="profile-section">
    //             <div className="clinic-profile">
    //                 <div className="profile-info">
    //                     <div className="clinic">{rowData.clinic_name}</div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }
}

export default ProfileView;
import React from "react";
import Button from './../../Button/Button';
import './Overview.scss';
import Resource from "../../../services/resource.service";
import { useLocation } from 'react-router-dom';


const Overview = ({data}) => {

    

    const saveNewChanges = (e)=> {

        e.preventDefault();
        console.log('clicked')
        console.log(data)

        const body = {
            name: document.getElementById("module-name").value ,
            summary: document.getElementById("module-description").value ,
            unlock_day: parseInt(document.getElementById("unlock-duration").value, 10)
        }
        
        console.log(data.id);
        console.log(body);
        if (data.program_id){
            Resource.updateModule(data.program_id, data.id, body);
        }
        else{
            Resource.addModule(data.id, body);
        }
    }

    return (
        <>
            <form className='addModuleForm'>
                <div className='form-element'>
                    <label>Module Name</label>
                    <input type="text" id="module-name" className="inputField" defaultValue={data && data.name} required />
                </div>
                <div className='form-element'>
                    <label>Module Description</label>
                    <textarea id="module-description" name="program-description" defaultValue={data && data.summary} className="inputField" rows="4" cols="50" required ></textarea>
                </div>
                <div className='form-element'>
                    <label>Unlock Content</label>
                    <div className="unlock-content">
                        <input type="number" id="unlock-duration" className="inputField" defaultValue={data && data.unlock_day} required />
                        <select id="select-unlockContent" className="inputField"  placeholder='Select unlock duration' required>
                            <option value="">Select unlock duration</option>
                            <option value="minute">Minute after the client enrolls</option>
                            <option value="hour">Hour after the client enrolls</option>
                            <option value="day">Day after the client enrolls</option>
                            <option value="week">Week after the client enrolls</option>
                            <option value="month">Month after the client enrolls</option>
                        </select>
                    </div>
                </div>
                {/* <div className='form-element' >
                    <label>Module Name</label>
                    <input type="text" id="module-name" className="inputField" required />
                </div>
                <div className='form-element relative_element'>
                    <div className="addModuleContentHeader">
                        <h5 className='moduleContent'>Module Content</h5>
                        <button type="button" className="saveContentBlock-btn" onClick={showSavedContent} >Save</button>
                    </div>
                    <div className="moduleContentContainer">
                        {
                            contentBlockList.map((item,index) => {
                                if(item.type === "textEditor") {
                                    return <TextEditor handleRemove={handleRemove} index={index} />
                                }
                                else {
                                    return <MediaEditor handleRemove={handleRemove} index={index} />
                                }   
                            })
                        }  
                    </div>
                </div>
                <div className="modulesBtn">
                    <div className="addButtons">
                        <button type="button" className="addContentBlock-btn" onClick={addContentBlock} ><img src={addIcon} className="add-image" alt="addicon" />Add Content Block</button>
                        <button type="button" className="addMediaBlock-btn" onClick={addMediaBlock} ><img src={addIcon} className="add-image" alt="addicon" />Add Media Block</button>
                    </div>
                    <button type="button" className="addModuleBlock-btn" onClick={addNewModule} >Add Module</button>
                </div> */}
                <div className='modulesSave-button'>
                    <Button 
                        onclick={saveNewChanges}
                        text={'Save'} 
                        buttonType={'primary_btn'}
                     />
                </div>
            </form>
                
        </>
    )
}

export default Overview;
import React, { useEffect, useState } from 'react';
import PatientTabRow from '../../PatientTabRow/PatientTabRow';
import './Search.scss';

const Search = ({ patientsData, sendDate }) => {

    const [searchField, setSearchField] = useState("");
    const [dateField, setDateField] = useState(false);
    const [dateValue, setDateValue] = useState('');
    
    useEffect(() => {
        let dt = new Date().toISOString().split('T')[0];
        setDateValue(dt);
        sendDate(dt);
    }, []);

    const filteredPersons = patientsData.filter(
        patient => {
            return (
                patient
                .patient_name
                .toLowerCase()
                .includes(searchField.toLowerCase()) ||
                patient
                .doctor_name
                .toLowerCase()
                .includes(searchField.toLowerCase())
            );
        }
    );

    const handleChange = e => {
        setSearchField(e.target.value);
    };

    const changeDate = (e) => {
        let date = document.getElementsByClassName("date_picker");
        if(e.target.value === '') {
            setDateField(false);
        }
        else {
            setDateField(true);
            date.value = e.target.value;
            sendDate(date.value);
        }
    }

    return (
        <div className='search'>
            <div className="searchInput">
                <div className='form-element'>
                    <input 
                    className="searchInputField"
                    type = "search" 
                    placeholder='Search...'
                    onChange = {handleChange}
                    />
                </div>
                <div className="form-element" id="datePickerDiv"  >
                    <input type="date" defaultValue={dateValue} placeholder="dd/mm/yyyy" name="datePicker" className="date_picker" id={dateField ? "new_date_picker" : "default_date_picker"} min="2000-01-01" onChange={changeDate}  ></input>
                </div>
            </div>
            {
                filteredPersons && filteredPersons.length > 0 ?
                filteredPersons.map(person =>  
                    <div key={person.id} className='patientRow'>
                       <PatientTabRow patientsData={person} />
                    </div>
                )
                :
                <div>
                    <h3>No patients found</h3>
                </div>


            }
        
        </div>
    );
}

export default Search;
import React from 'react';
import './Journal.scss';

const Journal = () => {

    return (
        <div className="Journal_Container">
            <div className='Journal'>Journal</div>
        </div>
    )

}

export default Journal;
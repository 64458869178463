import React, { useState } from 'react';
import closeIcon from './../../../images/close.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../Button/Button';
import MedicalCertificateUpload from '../../Upload/MedicalCertificateUpload';
import { useAuth } from '../../../services/use-auth';
import Resource from '../../../services/resource.service';
import Toast from '../../Toaster/Toast';
import useHandleEnter from '../../../hooks/useHandleEnter';
import { useEffect } from 'react';
import './CaremanagerForm.scss';

const CaremanagerForm = () => {

    const [showUploadField , setShowUploadField] = useState(false);
    const [certificateId, setCertificateId] = useState();
    const auth = useAuth();
    const location = useLocation();
    const role = location.state.role;
    const navigate = useNavigate();
    const {handleKeyDown} = useHandleEnter();

    function handleRadioButton(e) {
        let currentValue = e.target.value;
        if(currentValue === 'yes') {
            setShowUploadField(true);
        } 
        else {
            setShowUploadField(false);
        }
    }

    const certificateUpload = (docData) => {
        if(docData.document_id)
            setCertificateId(docData.document_id);

    }

    useEffect(() => {
        if(auth.user.role == Resource.ROLES.CARE_MANAGER)
            navigate("/home");

    }, [auth.user]);

    const registerCaremanager = (e) => {
        e.preventDefault();
        var Error = "This field cannot be empty";
        // var emailvalueError = "Please provide a valid email-id";
        var namevalueError = Error;
        var radiovalueError = "Please check an option";
        // var phonevalueError = "Please provide a 10 digit valid phone number";
        // var agevalueError = "Please provide a age between 18 and 99";
        var cmFirstname = document.getElementById('cm-firstname');
        var cmLastname = document.getElementById('cm-lastname');
        var cmPractice = document.getElementById('cm-practice');
        var cmEmail = document.getElementById('cm-email');
        var ayushRegistered = document.getElementById('ayushRegistered');
        var ayushNotRegistered = document.getElementById('ayushNotRegistered');

        var firstNameerror = document.getElementById('firstnameerror');
        var lastNameerror = document.getElementById('lastnameerror');
        var practiceerror = document.getElementById('practiceerror');
        var fileerror = document.getElementById('fileerror');
        var ayushRegisterederror = document.getElementById('ayushRegisterederror');

        if (cmFirstname.value || cmLastname.value || cmPractice.value ) {
            console.log(certificateId);

            if (cmFirstname.value.length > 0) {
                cmFirstname.classList.remove('invalid');
                firstNameerror.style.display = "none";

                if (cmLastname.value.length > 0 ) {
                    lastNameerror.style.display = "none";
                    cmLastname.classList.remove("invalid");

                    if (cmPractice.selectedIndex !== -1) {
                        cmPractice.classList.remove('invalid');
                        practiceerror.style.display = "none";

                        if ((ayushRegistered.checked == true) || (ayushNotRegistered.checked == true)) {
                            ayushRegistered.classList.remove('invalid');
                            ayushNotRegistered.classList.remove('invalid');
                            ayushRegisterederror.style.display = "none";                        

                            if ((showUploadField && certificateId) || !showUploadField) {

                                if(showUploadField)
                                    fileerror.style.display = "none";

                                var obj = {
                                    first_name:'',
                                    last_name:'',
                                    metainfo: {},
                                    email:'',
                                    role: role
                                };
                                obj.first_name = cmFirstname.value;
                                obj.last_name = cmLastname.value;
                                obj.contact_number = auth.user ? auth.user.username : null;
                                obj.metainfo.specialisation = cmPractice.value;
                                obj.metainfo.ayush_registered = ayushRegistered.checked ? true : false;
                                obj.metainfo.ayush_certificate_id = certificateId;
                                obj.email = cmEmail.value;
                                console.log(obj);
                                Resource.updateSelf(obj).then((resp) => {
                                    if(resp.success) {
                                        auth.getConfig({role: Resource.ROLES.CARE_MANAGER}).then((resp) => {
                                            Toast.success("Registration form submitted successfully!!");
                                        });
                                    }
                                })

                            }
                            else {
                                fileerror.style.display = "inline";
                                fileerror.innerHTML = Error;
                            }
                        }
                        else {
                            ayushRegistered.classList.add('invalid');
                            ayushNotRegistered.classList.add('invalid');
                            ayushRegisterederror.style.display = "inline";
                            ayushRegisterederror.innerHTML = radiovalueError;
                        }                        
                    }
                    else {
                        cmPractice.classList.add('invalid');
                        practiceerror.style.display = "inline";
                        practiceerror.innerHTML = Error;
                    }
                }
                else {
                    lastNameerror.style.display = "inline";
                    cmLastname.classList.add("invalid");
                    lastNameerror.innerHTML = namevalueError;
                }
            }
            else {
                firstNameerror.style.display = "inline";
                cmFirstname.classList.add("invalid");
                firstNameerror.innerHTML = namevalueError;
            }
        }
        else {
            firstNameerror.style.display = "inline";
            cmFirstname.classList.add("invalid");
            firstNameerror.innerHTML = namevalueError;

            lastNameerror.style.display = "inline";
            cmLastname.classList.add("invalid");
            lastNameerror.innerHTML = namevalueError;

            cmPractice.classList.add('invalid');
            practiceerror.style.display = "inline";
            practiceerror.innerHTML = Error;

            ayushRegistered.classList.add('invalid');
            ayushNotRegistered.classList.add('invalid');
            ayushRegisterederror.style.display = "inline";
            ayushRegisterederror.innerHTML = radiovalueError;

            fileerror.style.display = "inline";
            fileerror.innerHTML = Error;
        }    
        
    }
    return (
        role == Resource.ROLES.CARE_MANAGER &&
        <div className='caremanager'>
            <div className="add-caremanager container">
                <div className='addCaremanagerContainer'>
                    <h1 className="title">Sign up as a Care Manager</h1>
                    
                </div>
                
                <form className=" registerForm addCaremanager-form">
                    
                    <div className='form-element'>
                        <label>First Name</label>
                        <input type="text" id="cm-firstname" className="inputField" placeholder="Enter first name" required />
                        <span className="field-error" id="firstnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Last Name</label>
                        <input type="text" id="cm-lastname" className="inputField" placeholder="Enter last name" required />
                        <span className="field-error" id="lastnameerror"></span>
                    </div>
                    <div className='form-element'>
                        <label>Email</label>
                        <input type="text" id="cm-email" className="inputField" placeholder="Enter email address"  />
                        <span className="field-error" id="emailerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>Specialisation</label>
                        <select id="cm-practice" className="inputField" placeholder='Select practice' required>
                            <option value="">-- Select Specialisation --</option>
                            <option value="Naturopathy">Naturopathy</option>
                            <option value="Ayurveda">Ayurveda</option>
                            <option value="Functional Medicine">Functional Medicine</option>
                            <option value="Homeopathy">Homeopathy</option>
                            <option value="Others">Others</option>
                        </select>
                        <span className="field-error" id="practiceerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>AYUSH Registered ?</label>
                        <div className='radioButtonGroup' onClick={() => {document.getElementById('ayushRegistered').checked = true;setShowUploadField(true);}}>
                            <label for="yes">
                                <input type="radio" id="ayushRegistered" name="yes/no" value="yes"  onChange={(e)=>handleRadioButton(e)}/>Yes
                            </label>
                        </div>
                        <div className='radioButtonGroup' onClick={() => {document.getElementById('ayushNotRegistered').checked = true;setShowUploadField(false);}}>
                            <label for="no">
                                <input type="radio" id="ayushNotRegistered" name="yes/no" value="no" onKeyDown={(e) => handleKeyDown(e,registerCaremanager)} onChange={(e)=>handleRadioButton(e)}/> No
                            </label>
                        </div>
                        <span className="field-error" id="ayushRegisterederror"></span>
                    </div>
                    {
                        showUploadField &&
                        <div className='form-element'>
                            <label>Upload Certificate</label>
                            <MedicalCertificateUpload id="cm-file" assigned_to={auth.user.id} callback={certificateUpload}/>
                            <span className="field-error" id="fileerror"></span>
                        </div>
                    }
                    
                    <div className='bottomElement'>
                       <Button text={'Register'} className={'buttonBottomStyle'} buttonType={'primary_btn'} onclick={registerCaremanager} size={'fluid'}></Button>
                    </div>
                </form>
            </div>
       </div>
    );

};

export default CaremanagerForm;
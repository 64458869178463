import { useState } from "react";
import OtpInput from "./OtpInput";


const OtpInputCard = ({resendOTP, ...rest }) => {
  const [OTP, setOTP] = useState("");
  return (
    <div>
      <OtpInput value={OTP} onChange={setOTP} {...rest} />
    </div>
  );
};

export default OtpInputCard;
/*import { render } from "@testing-library/react";
import { VscCheck, VscIssues, VscReport } from "react-icons/vsc";
import './../App.css';*/

const authHeader = (refresh) => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var currentUserToken;
    if(refresh) {
        const currentUserRefreshToken = localStorage.getItem('currentUserRefreshToken');
        currentUserToken = currentUserRefreshToken;
    }
    else {
        const currentUserAccessToken = localStorage.getItem('currentUserAccessToken');
        currentUserToken = currentUserAccessToken;
    }

    if (currentUser && currentUserToken) {
        return { Authorization: `Bearer ${currentUserToken}` };
    } else {
        return {};
    }
}

export default authHeader;

export function apiHeader(refresh) {
    var header = authHeader(refresh);
    header['Content-Type'] = 'application/json';
    return header;
}



import Resource from "../../services/resource.service";
import Toast from "../Toaster/Toast";

const PrescriptionUpload = ({assigned_to}) => {

    function handleFileInput(e) {
        var file = e.target.files[0];
        let uploadObj = {
            type: 'prescription',
            assigned_to: assigned_to,
            file_name: (file.name.replaceAll(' ', '_')).replace(/\.[^/.]+$/, "") ,
            file_extension: (file.name).split('.').pop()
        }
        Resource.mediaUpload(uploadObj, file).then((res) => {
            if(res.success) {
                Toast.success("Prescription uploaded successfully!!");
            }
            else {
                Toast.error("ERROR: Prescription could not be uploaded. Please try again.");
            }
        })
    }

    return <input type="file" className="inputField" accept="image/*, .pdf, .doc, .docx" onChange={handleFileInput} ></input>
};

export default PrescriptionUpload;
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './InformationSidebar.scss';

const InformationSidebar = ({ getSidebarData, activeitem }) => {

    
    let navLabels = [
        "Documents", "Diet & Lifestyle Plans", "Journal", "Tasks", "Measurements & Vitals", "Medical History", "Diet & Lifestyle Habits" 
    ]
    let selectedValue;
    

    function showSelected(e) {
        selectedValue = e.target.textContent;
        // setActiveItem(selectedValue);
        getSidebarData(selectedValue);
    }


    return (
        <div className="information_sidebar">
            <ul className="nav-items">
                {
                    navLabels.map((navLabel) => {
                        return <li className={`nav-item ${activeitem === navLabel ? "active-item" : ""}` } onClick={showSelected} >{navLabel}</li>
                    })
                }
                {/* <li className="nav-item active-item" onClick={showSelected} >
                    Documents
                </li>
                <li className="nav-item" onClick={showSelected} >
                    Diet & Lifestyle Plans
                </li>
                <li className="nav-item" onClick={showSelected} >
                    Journal
                </li>
                <li className="nav-item" onClick={showSelected} >
                    Tasks
                </li>
                <li className="nav-item" onClick={showSelected} >
                    Measurements & Vitals
                </li>
                <li className="nav-item" onClick={showSelected} >
                    Medical History
                </li>
                <li className="nav-item"onClick={showSelected} >
                   Diet & Lifestyle Habits
                </li> */}
            </ul>
        </div>
    )

}

export default InformationSidebar;
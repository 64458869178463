import React from "react";
import './CmPatientDetails.scss';
import Header from '../../Header/Header';
import UpcomingAppointmentWidget from "./../../UpcomingAppointmentWidget/UpcomingAppointmentWidget";
import PatientProfile from "../../PatientProfile/PatientProfile";
import PatientTabContainer from "../../PatientTabContainer/PatientTabContainer";
import { useAuth } from "../../../services/use-auth";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Resource from "../../../services/resource.service";
import { formatPatientData } from "../../../helpers/utils";

const CmPatientDetails = () => {

    let linkToUrl = '/';
    const [patientInfo, setPatientInfo] =  useState();
    const auth = useAuth();
    const params = useParams();

    useEffect(() => {
        var auth_id = params.id;
        Resource.getUserById(Resource.ROLES.PATIENT, auth_id).then((response) => {
            if(response.success)
                setPatientInfo(formatPatientData(response.data));
        });
    }, [auth.user]);

    let CareManagertabLabels = [
        'Goals','Timeline','Information','Progress','Program'
    ];

    return (
        <div className="CareManager_patient_details_component">
            <div className="patient_details_container">
                <Header linkToUrl={linkToUrl} />
                <div className="patient_details">
                    <div className="patient_profile">
                        {patientInfo && <PatientProfile patientInfo={patientInfo}/> }
                    </div>
                    <div className="patient_appointments">
                        <UpcomingAppointmentWidget />
                    </div>
                </div>
                <div className="patient_details_tab">
                    <PatientTabContainer tabLabels={CareManagertabLabels} />
                </div>
            </div>
        </div>
    )
}

export default CmPatientDetails;
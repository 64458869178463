import React from "react";

const ProgressGraph = () => {

    return (
        <>
           <h1>I am progress tab</h1>
        </>
    )
}

export default ProgressGraph;
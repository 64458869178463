import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import './Header.scss';

const Header = ({headerTitle, linkToUrl,pageData}) => {

    const navigate = useNavigate();
    return (
        <div className='addProgramHeader'>
            <div className='addprogram_header_content'>
                <Link to={linkToUrl} state={pageData && pageData}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="5" y1="12" x2="11" y2="18" />
                    <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                </Link>
                <h1 className='addProgramTitle'>{headerTitle}</h1>
            </div>
        </div>
    )
}    

export default Header;
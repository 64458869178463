import React,{useRef,useState,useEffect} from 'react';
import closeIcon from './../../../images/close.png';
import { Link } from 'react-router-dom';
import { register } from '../../../serviceWorkerRegistration';
import Button from '../../Button/Button';
import './AddAssistantForm.scss';
import Resource from '../../../services/resource.service';
import Toast from '../../Toaster/Toast';

const AddAssistantForm = ({rowData}) => {

    const registerAssistant = () => {
        var Error = "This field cannot be empty";
        // var emailvalueError = "Please provide a valid email-id";
        var namevalueError = "Please provide a name";
        var phonevalueError = "Please provide a 10 digit valid phone number";
        // var agevalueError = "Please provide a age between 18 and 99";
        let phoneformat = /^([6-9])(\d{9})$/;


        var firstname = document.getElementById('assistant-fname');
        var lastname = document.getElementById('assistant-lname');
        var searchByClinic = document.getElementById('search-clinic');
        var phonenumber = document.getElementById('assistant-ph');

        var firstnameerror = document.getElementById('firstnameerror');
        var lastnameerror = document.getElementById('lastnameerror');
        var searchByClinicError = document.getElementById('searchByClinicError');
        var phoneerror = document.getElementById('phoneerror');

        if (firstname.value || lastname.value || searchByClinic.value || phonenumber.value) {
            if (firstname.value.length > 0) {
                firstname.classList.remove('invalid');
                firstnameerror.style.display = "none";

                if (lastname.value.length > 0) {
                    lastname.classList.remove('invalid');
                    lastnameerror.style.display = "none";

                    if (searchByClinic.value !== '') {
                        searchByClinic.classList.remove('invalid');
                        searchByClinicError.style.display = "none";

                        if (phonenumber.value !== ''&& phonenumber.value.match(phoneformat)) {
                            var obj = {
                                first_name: '',
                                last_name: '',
                                doctor_id: '',
                                assistant_phone_number: ''
                            };
                            obj.first_name = firstname.value;
                            obj.last_name = lastname.value;
                            obj.assistant_phone_number = '+91' + phonenumber.value.replace(/\D/g, "");
                            obj.doctor_id = parseInt(searchByClinic.value, 10);
                            
                            console.log(obj);
    
                            return Resource.createUser(Resource.ROLES.ASSISTANT, obj).then((resp) => {
                                if(resp.success) {
                                    return true;
                                }
                            });
                        }
                        else {
                            phoneerror.classList.add('invalid');
                            phoneerror.style.display = "inline";
                            phoneerror.innerHTML = phonevalueError;   

                            return new Promise( () => {return false} );
                        }
                    }
                    else {
                        searchByClinic.classList.add('invalid');
                        searchByClinicError.style.display = "inline";
                        searchByClinicError.innerHTML = Error;   

                        return new Promise( () => {return false} );
                    }
                }
                else {
                    lastname.classList.add("invalid");
                    lastnameerror.style.display = "inline";
                    lastnameerror.innerHTML = namevalueError;   

                    return new Promise( () => {return false} );
                }
            }
            else {
                firstname.classList.add("invalid");
                firstnameerror.style.display = "inline";
                firstnameerror.innerHTML = namevalueError;
                   
                return new Promise( () => {return false} );
            }
        }
        else {
            firstnameerror.style.display = "inline";
            firstname.classList.add("invalid");
            firstnameerror.innerHTML = namevalueError;

            lastnameerror.style.display = "inline";
            lastname.classList.add("invalid");
            lastnameerror.innerHTML = namevalueError;

            searchByClinicError.style.display = "inline";
            searchByClinic.classList.add('invalid');
            searchByClinicError.innerHTML = Error;

            phoneerror.classList.add('invalid');
            phoneerror.style.display = "inline";
            phoneerror.innerHTML = Error;  

            return new Promise( () => {return false} );  
        }
        
    }

    useEffect(() => {
        rowData.registerAssistant = registerAssistant;

        return () => {
            delete rowData.registerAssistant;
        };
    });

    return (
       <div className='container'>
    
            <form autoComplete="disabled"  className="registerForm addAssistant-form">
                
                <div className='form-element'>
                    <label>Add staff to </label>
                    <h3>Dr. {rowData.first_name + ' ' + rowData.last_name}</h3>
                </div>
                <div className='form-element'>
                    <select id="search-clinic" className="inputField" placeholder='Select clinic' required>
                        <option value="">-- Select Clinic --</option>
                        {
                            rowData?.clinics && rowData.clinics.map((c) => {
                                return <option value={c.doctor_id}>{c.clinic_name}</option>
                            })
                        }
                    </select>
                    <span className="field-error" id="searchByClinicError"></span>
                </div>

                <div className='form-element'>
                    <label>First Name</label>
                    <input type="text" id="assistant-fname" className="inputField" placeholder="First name" required />
                    <span className="field-error" id="firstnameerror"></span>
                </div>

                <div className='form-element'>
                    <label>Last Name</label>
                    <input type="text" id="assistant-lname" className="inputField" placeholder="Last name" required />
                    <span className="field-error" id="lastnameerror"></span>
                </div>

                <div className='form-element'>
                    <label>10-digit Phone Number</label>
                    <input autoComplete="disabled"  type="tel" id="assistant-ph" className="inputField" placeholder="+91 " required />
                    <span className="field-error" id="phoneerror"></span>
                </div>
                
                {/*
                <Button text={'Register'} buttonType={'primary_btn'} onclick={registerAssistant} size={'fluid'}></Button> */}
            </form>
       </div>
    );

};

export default AddAssistantForm;
import React , { useState, useEffect, useRef } from "react";
import './Search.scss';

const Search = ({suggestionLookup, callbackLookup, clear, patientsData }) => {

    const [showSuggestionBox , setShowSuggestionBox] = useState(false);
    const [patients, setPatients] = useState(patientsData);
    const [searchVal, setSearchVal] = useState("");
    const inputRef = useRef();
    const suggestionRef = useRef();
    const [matchedData,setMatchedData] = useState([]);
    
    useEffect(() => {
        if(clear >= 0)
            inputRef.current.value = '';
    }, [clear]);

    function getSelectedSearchValue(item) {
        setShowSuggestionBox(false);
        inputRef.current.value = item.name ;
        inputRef.current.item = item;

        if(callbackLookup) {
            callbackLookup(item);
        }
    }

    // function onChangeSearchInput(e) {
    //     if (searchVal === "") { 
    //         setProducts(patientsData); 
    //         return; 
    //     }
    //     const filteredPersons = patientsData.filter(
    //         patientData => {
    //           return (
    //             patientData
    //             .doctor_name
    //             .toLowerCase()
    //             .includes(searchVal.toLowerCase()) ||
    //             patientData
    //             .patient_name
    //             .toLowerCase()
    //             .includes(searchVal.toLowerCase())
    //           );
    //         }
    //     );
        
    // }

    function onChangeSearchInput(e) {
        let value = e.target.value;
        if(value.length > 0){
            setShowSuggestionBox(true);
            suggestionLookup(value).then((response) => {
                if(response.success) {
                    setMatchedData(response.data.matches.slice(0, 10));
                }
            })
        } else{
            setShowSuggestionBox(false);
            if(callbackLookup)
                callbackLookup(null);
        }
    }

    function handleClickOutside(e) {
        if (suggestionRef.current && !suggestionRef.current.contains(e.target)) { 
            setShowSuggestionBox(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        });
    },[showSuggestionBox]);

    return (
        <>
            <div className="search-container" >
                <input autoComplete="disabled" className="searchField" ref={inputRef} type="text" placeholder=" Search" name="search" onChange={(e)=>onChangeSearchInput(e)}/>
                {
                    showSuggestionBox ? 
                    <div className="suggestionBox" ref={suggestionRef}>
                        {
                           matchedData.length > 0 ? matchedData.map((item)=>{
                            console.log(item,"item")
                                return  (
                                    <>
                                        <div className="searchSuggestion" onClick={()=>getSelectedSearchValue(item)}>
                                            <span className="searchValue">
                                                {item.name}
                                            </span>
                                            <span className="searchType">
                                                {item.type} 
                                            </span>
                                        </div>
                                    </>
                                )
                            }) :
                            <span className="noDataFound" >No data matched</span>
                        }
                    </div>
                    :
                    ' '
                }
             
            </div>
        </>
    );
}

export default Search;
import React, { useState } from 'react';
import { Link, } from 'react-router-dom';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import Resource from '../../../../services/resource.service';
import Toast from '../../../Toaster/Toast';

const ClinicPageDetails = ({clinicData}) => {

    const [showEditForm, setShowEditForm] = useState(false);

    const displayEditForm = () => {
        setShowEditForm(true);
    }

    const closeModal = () => {
        setShowEditForm(false);
    }
    

    function userSave() {

        var obj = {
            id: '',
            name:'',
            address:'',
            contact_number:'',
            metainfo: {}
        };
        obj.name = document.getElementById("clinic-name").value;
        obj.address = document.getElementById("clinic-address").value;
        obj.contact_number = '+91' + document.getElementById("clinic-phonenumber").value.replace(/\D/g, "");
        obj.metainfo.city = document.getElementById("clinic-location").value;
        obj.metainfo.whatsapp_number = document.getElementById("clinic-whatsappnumber").value;
        obj.metainfo.incentives = document.getElementById("clinic-incentives").value;
        console.log(obj);


        Resource.updateClinic(clinicData.id, obj).then((resp) => {
            if(resp.success) {
                Toast.success("✔ Clinic updated successfully");
                closeModal();
            }
            else {
                        Toast.error("ERROR: Clinic could not be updated!!");
                 }
        });
        //the following function is set in PatientEditform component
        // rowDetail.updateUser().then((resp) => {
        //     if(resp) {
        //         forceReload("✔ Profile updated successfully.");
        //         closeModal();
        //     }
        //     else {
        //         Toast.error("ERROR: Profile could not be updated!!");
        //     }
        // })
    }

    return (
        <>
            <div className='clinic_page_details_container'>
                <div className='clinic_page_details_header'>
                    <h1 className='clinic_page_title'>Clinic Details</h1>
                    <Button buttonType={'icon_btn'} onclick={displayEditForm}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1c1e1f" fill="none" strokeLinecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                        <line x1="16" y1="5" x2="19" y2="8" />
                        </svg> 
                    </Button>
                </div>
                <div className='clinic_name'>
                    <span>Clinic Name</span>
                    <h3>{clinicData.name}</h3>
                </div>
                <div className='location'>
                    <div className='address'>
                        <span>Address</span>
                        <h3>{clinicData.address}</h3>
                    </div>
                    <div className='city'>
                        <span>Location</span>
                        <h3>{clinicData.city}</h3>
                    </div>
                </div>
                <div className='phone_number'>
                    <span>Contact Number</span>
                    <h3>{clinicData.contact_number}</h3>
                </div>
                <div className='whatsapp_number'>
                    <span>Whatsapp Number</span>
                    <h3>{clinicData.whatsapp_number}</h3>
                </div>
                <div className='incentives'>
                    <span>Incentives</span>
                    <h3>{clinicData.incentives}</h3>
                </div>
            </div>

            {
                showEditForm ?
                    <Modal closeModal={ () => setShowEditForm(false) } right={'right'}>
                        <div className='right_modal_width container'>
                            <div className='modal_header'>
                                <svg onClick={closeModal}  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 6L6 18" stroke="#070708" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                <path d="M6 6L18 18" stroke="#070708" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                </svg>
                                {
                                    showEditForm && <Button text={'Save'} id='update-user' buttonType={'primary_btn'} onclick={() => userSave()} />
                                }
                            </div>
                            <div className='clinic_Data_Edit_Form registerForm'>
                                <div className='form-element'>
                                    <label>Clinic Name</label>
                                    <input type="text" id="clinic-name" className="inputField"  defaultValue={clinicData.name} required />
                                    <span className="field-error" id="nameerror"></span>
                                </div>
                                
                                <div className='form-element'>
                                    <label>Address</label>
                                    <input type="text" id="clinic-address" className="inputField"  defaultValue={clinicData.address} required />
                                    <span className="field-error" id="addresserror"></span>
                                </div>

                                <div className='form-element'>
                                    <label>10-digit Phone Number</label>
                                    <input disabled type="tel" id="clinic-phonenumber" className="inputField"  defaultValue={clinicData.contact_number} required />
                                    <span className="field-error" id="phonenumbererror"></span>
                                </div>

                                <div className='form-element'>
                                    <label>Location</label>
                                    <input type="text" id="clinic-location" className="inputField"  defaultValue={clinicData.city} required />
                                    <span className="field-error" id="locationerror"></span>
                                </div>

                                <div className='form-element'>
                                    <label>Whatsapp Number</label>
                                    <input type="tel" id="clinic-whatsappnumber" className="inputField"  defaultValue={clinicData.whatsapp_number} required />
                                    <span className="field-error" id="phonenumerror"></span>
                                </div>

                                <div className='form-element'>
                                    <label>Incentives</label>
                                    <input type="number" id="clinic-incentives" className="inputField"  defaultValue={clinicData.incentives} required />
                                    <span className="field-error" id="incentiveserror"></span>
                                </div>
                            </div>
                        </div>
                    </Modal>
                :''
            } 
        </>
    )
}

export default ClinicPageDetails;
import React from 'react';
import { beautifyPhoneNumber } from '../../../../helpers/utils';

const DoctorMyAccountForm = ({currentUser}) => {

    let clinicData = currentUser.clinics;
    
    return (
        <>
            <div className='myAccount_name'>
                <div className='first_name'>
                    <span>First Name</span>
                    <h3>{currentUser.first_name}</h3>
                </div>
                <div className='last_name'>
                    <span>Last Name</span>
                    <h3>{currentUser.last_name}</h3>
                </div>
            </div>
            <div className='clinic_name'>
                <span>Clinic Name</span>
                {
                    clinicData.map((clinic)=>{
                        return  <h3>{clinic.name}</h3>
                    })
                }
            </div>
            <div className='phone_number'>
                <span>Contact Number</span>
                <h3>{beautifyPhoneNumber(currentUser.contact_number)}</h3>
            </div>
            <div className='practicing_as'>
                <span>Practicing As</span>
                <h3>{currentUser.metainfo?.practice_id}</h3>
            </div>
            <div className='medical_council'>
                <span>Medical Council</span>
                <h3>{currentUser.metainfo?.medical_council}</h3>
            </div>
            <div className='medical_registernumber'>
                <span>Medical Registration Number</span>
                <h3>{currentUser.metainfo?.medical_registration_number}</h3>
            </div>
            <div className='phone_number'>
                <span>Username</span>
                <h3>{currentUser.username}</h3>
            </div>
        </>
    )
}

export default DoctorMyAccountForm;
import Button from "./../../../Button/Button";
import { useState } from "react";
import Resource from "../../../../services/resource.service";
import Toast from "../../../Toaster/Toast";

const BankForm = ({amount, closeModal})=>{
    const [selectedBank,setSelectedBank] = useState(-1);

    let Banks = [
        'State Bank of India',
        'Canara Bank',
        'Axis Bank',
        'HDFC Bank',
        'ICICI Bank',
        'Kotak Mahindra Bank',
        'Yes Bank'
    ];

    const submitForm = (e)=>{

        e.preventDefault();
        let Error = "This field cannot be empty";
        let ifscError = ' enter valid IFSC Code'
        let ifscFormat = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;

        let bankAccountNumber = document.getElementById('bankAccountNumber');
        let bankAccountIfsc = document.getElementById('bankAccountIfsc');
        let bankAccountHolderName = document.getElementById('bankAccountHolderName');
        let bankName = document.getElementById('bankName');

        let bankAccountNumberError = document.getElementById('bankAccountNumberError');
        let bankAccountIfscError = document.getElementById('bankAccountIfscError');
        let bankAccountHolderNameError = document.getElementById('bankAccountHolderNameError');
        let bankNameError = document.getElementById('bankNameError');
       
        if (bankAccountNumber.value || bankAccountIfsc.value  !== '' || bankAccountHolderName.value !== '') {
            if (bankAccountNumber.value.length > 0) {
                bankAccountNumber.classList.remove('invalid');
                bankAccountNumberError.style.display = "none";

                if (bankAccountIfsc.value.match(ifscFormat)) {
                    bankAccountIfsc.classList.remove('invalid');
                    bankAccountIfscError.style.display = "none";

                    if (bankAccountHolderName.value !== '' ) {
                        bankAccountHolderNameError.style.display = "none";
                        bankAccountHolderName.classList.remove("invalid");

                        if (bankName.value !== '-1') {
                            bankName.classList.remove('invalid');
                            bankNameError.style.display = "none";
                            var payload = {
                                amount: amount,
                                type: 'withdrawal',
                                mode: 'neft',
                                bank_account_number: bankAccountNumber.value,
                                bank_account_ifsc: bankAccountIfsc.value,
                                bank_account_holder: bankAccountHolderName.value,
                                bank_name: bankName.selectedOptions[0].innerText + ' (' + bankName.value + ')'
                            }
                            
                            Resource.requestWithdraw(payload).then((response) => {
                                if(response.success) {
                                    Toast.success("SUCCESS: Your request to withdraw INR " + amount + " has been received!!" );
                                }
                                else {
                                    Toast.error("ERROR: Server error. Please try again in sometime!!" );
                                }
                                closeModal();
                            });

                        }
                        else {
                            bankNameError.style.display = "inline";
                            bankName.classList.add("invalid");
                            bankNameError.innerHTML = Error;
                        }
                    }
                    else {
                        bankAccountHolderNameError.style.display = "inline";
                        bankAccountHolderName.classList.add("invalid");
                        bankAccountHolderNameError.innerHTML = Error;
                    }
                }
                else {
                    bankAccountIfscError.style.display = "inline";
                    bankAccountIfsc.classList.add("invalid");
                    bankAccountIfscError.innerHTML = ifscError;
                }     
            }
            else {
                bankAccountNumberError.style.display = "inline";
                bankAccountNumber.classList.add("invalid");
                bankAccountNumberError.innerHTML = Error;
            }
        }
        else {
            bankAccountNumberError.style.display = "inline";
            bankAccountNumber.classList.add("invalid");
            bankAccountNumberError.innerHTML = Error;
            
            bankAccountIfscError.style.display = "inline";
            bankAccountIfsc.classList.add("invalid");
            bankAccountIfscError.innerHTML = Error;

            bankAccountHolderNameError.style.display = "inline";
            bankAccountHolderName.classList.add("invalid");
            bankAccountHolderNameError.innerHTML = Error;

            bankNameError.style.display = "inline";
            bankName.classList.add("invalid");
            bankNameError.innerHTML = Error;   
        }  
    }
    return(
        <>
            <form className=" registerForm registerDoctor-form">
                    
                    <div className='form-element'>
                        <label>Bank Account Number</label>
                        <input type="number" id="bankAccountNumber" className="inputField" placeholder="Enter account number" required />
                        <span className="field-error" id="bankAccountNumberError"></span>
                    </div>

                    <div className='form-element'>
                        <label>Bank Account IFSC</label>
                        <input type="text" id="bankAccountIfsc" className="inputField" placeholder="Enter ifsc code" required />
                        <span className="field-error" id="bankAccountIfscError"></span>
                    </div>

                    <div className='form-element'>
                        <label>Bank Account Holder Name</label>
                        <input type="text" id="bankAccountHolderName" className="inputField" placeholder="Enter name" />
                        <span className="field-error" id="bankAccountHolderNameError"></span>
                    </div>

                    <div className='form-element' >            
                        <label>Bank Name</label>
                        <select id="bankName" value={selectedBank} onChange={(e)=>setSelectedBank(e.target.value)} defaultValue={selectedBank} className="inputField" placeholder='Select Bank' required>
                            <option value={-1}>-- Select Bank --</option>
                            <option value="ATP">Airtel Payments Bank</option> 
                            <option value="APG">Andhra Pragathi Grameena Bank</option> 
                            <option value="AUB">AU Small Finance Bank</option> 
                            <option value="UTI">Axis Bank</option> 
                            <option value="BDN">Bandhan Bank</option> 
                            <option value="BBK">Bank of Bahrain and Kuwait</option> 
                            <option value="BBR">Bank of Baroda - Retail Banking</option> 
                            <option value="BBC">Bank of Baroda - Corporate</option> 
                            <option value="BOI">Bank of India</option> 
                            <option value="BOM">Bank of Maharashtra</option> 
                            <option value="BRL">Barclays Bank - Corporate Net Banking</option> 
                            <option value="BCB">Bassein Catholic Co-operative Bank</option> 
                            <option value="BNP">BNP Paribas</option> 
                            <option value="CNB">Canara Bank</option> 
                            <option value="CSB">Catholic Syrian Bank</option> 
                            <option value="CBI">Central Bank of India</option> 
                            <option value="CUB">City Union Bank</option> 
                            <option value="COB">Cosmos Co-operative Bank</option> 
                            <option value="DCB">DCB Bank</option> 
                            <option value="DBK">Deutsche Bank</option> 
                            <option value="DBS">Digibank by DBS</option> 
                            <option value="DLB">Dhanlaxmi Bank</option> 
                            <option value="EQB">Equitas Small Finance Bank</option> 
                            <option value="ESF">ESAF Small Finance Bank</option> 
                            <option value="FBK">Federal Bank</option> 
                            <option value="FNC">Fincare Bank</option> 
                            <option value="HDF">HDFC Bank</option> 
                            <option value="ICI">ICICI Bank</option> 
                            <option value="IDB">IDBI</option> 
                            <option value="IDN">IDFC FIRST Bank</option> 
                            <option value="INB">Indian Bank</option> 
                            <option value="IOB">Indian Overseas Bank</option> 
                            <option value="IDS">Indusind Bank</option> 
                            <option value="JKB">Jammu and Kashmir Bank</option> 
                            <option value="JNB">Jana Small Finance Bank</option> 
                            <option value="JSB">Janata Sahakari Bank (Pune)</option> 
                            <option value="KJB">Kalyan Janata Sahakari Bank</option> 
                            <option value="KBL">Karnataka Bank</option> 
                            <option value="PKB">Karnataka Gramin Bank</option> 
                            <option value="KVG">Karnataka Vikas Grameena Bank</option> 
                            <option value="KVB">Karur Vysya Bank</option> 
                            <option value="162">Kotak Mahindra Bank</option> 
                            <option value="LVR">Lakshmi Vilas Bank - Retail Banking</option> 
                            <option value="MSB">Mehsana urban Co-operative Bank</option> 
                            <option value="NKB">NKGSB Co-operative Bank</option> 
                            <option value="NEB">North East Small Finance Bank</option> 
                            <option value="NUT">Nutan Nagrik Bank</option> 
                            <option value="PSB">Punjab &amp; Sind Bank</option> 
                            <option value="CPN">Punjab National Bank - Corporate Banking</option> 
                            <option value="PNB">Punjab National Bank - Retail Banking</option> 
                            <option value="RBL">RBL Bank</option> 
                            <option value="RTC">RBL Bank Limited - Corporate</option> 
                            <option value="SWB">Saraswat Co-operative Bank</option> 
                            <option value="SVC">Shamrao Vithal Co-operative Bank</option> 
                            <option value="SV2">Shamrao Vithal Co-op Bank - Corporate</option> 
                            <option value="SHB">Shivalik Mercantile Co-operative Bank Ltd</option> 
                            <option value="SIB">South Indian Bank</option> 
                            <option value="SCB">Standard Chartered Bank</option> 
                            <option value="SBI">State Bank of India</option> 
                            <option value="SRB">Suryoday Small Finance Bank</option> 
                            <option value="TMB">Tamilnadu Mercantile Bank</option> 
                            <option value="TNC">Tamilnadu State Apex Co-operative Bank</option> 
                            <option value="TBB">Thane Bharat Sahakari Bank Ltd</option> 
                            <option value="KLB">The Kalupur Commercial Co-operative Bank</option> 
                            <option value="TJB">TJSB Bank</option> 
                            <option value="UCO">UCO Bank</option> 
                            <option value="UBI">Union Bank</option> 
                            <option value="VRB">Varachha Co-operative Bank Limited</option> 
                            <option value="YBK">Yes Bank</option> 
                            <option value="YBC">Yes Bank - Corporate</option> 
                            <option value="ZOB">Zoroastrian Co-operative Bank Limited</option>
                        </select>
                        <span className="field-error" id="bankNameError"></span>
                    </div>
                    <Button text={'Withdraw Amount'} buttonType={'primary_btn'} onclick={submitForm} size={'fluid'}></Button>
            </form>
        </>
    )
}
export default BankForm;
import React,{useEffect} from "react";
import { NavLink, Link, useNavigate, Navigate } from "react-router-dom";
import { useState } from "react";
import Resource from "../../services/resource.service";
import { useAuth } from "../../services/use-auth";
import logo from './../../images/md+.png';
import './SelectRole.scss';
import Button from './../Button/Button';
import useHandleEnter from "../../hooks/useHandleEnter";

const SelectRole = () => {

    const [role, setRole] = useState();
    const navigate = useNavigate();
    const auth = useAuth();
    const {handleKeyDown} = useHandleEnter();

    function selectedRole(e) {
       let selectedValue = e.target.value;
       console.log(selectedValue);
       setRole(selectedValue);
    }

    const registerForm = () => {
        if(!role || role == 'default') {
            
        }
        else {
            navigate('/register', {state: {role} })
        }
    }

    if(auth && auth.user && !(auth.user.role && 
        [Resource.ROLES.ADMIN, Resource.ROLES.CARE_MANAGER,Resource.ROLES.DOCTOR, Resource.ROLES.ASSISTANT, Resource.ROLES.MR_SALES_MANAGER].includes(auth.user.role))
        ) {

        return (
            <div className="select_role_container">
                <div className="logo">
                    <img className='logo_image' src={logo} alt="logoImage"></img>
                </div>
                <div className='form-element'>
                    <label>Select Role</label>
                    <select id="select-role" className="inputField" onKeyDown={(e) => handleKeyDown(e,registerForm)} value={selectedRole.value} onChange={selectedRole} required>
                        <option value="" default>--Select Role--</option>
                        <option value="doctor">Doctor</option>
                        <option value="assistant">Assistant / Frontdesk</option>
                        <option value="care_manager">Care Manager</option>
                        <option value="mr_sales_manager">MR Sales Manager</option>
                    </select>
                </div>
                <Button text={'Continue'} buttonType={'primary_btn'} onclick={registerForm} size={'fluid'}></Button>
                <span className='divider'>
                    or
                </span>
                <span className='logout'>
                    <a href="#" onClick={() => (auth.signout())}>Logout</a> if you want to continue later
                </span>
                
            </div>
        )
    }
    else if(!(auth && auth.user)) {
        return <Navigate to='/' replace={true} />
    }
    else {
        return <Navigate to='/home' replace={true} />
    }
}

export default SelectRole;
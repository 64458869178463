import React from 'react';
import Button from './../../../../../Button/Button';
import './Documents.scss';

const Documents = () => {

    return (
        <div className="documents_container">
            <div className='documents'>Documents</div>
            <Button text={'Upload Document'} buttonType={'primary_btn'} />
        </div>
    )

}

export default Documents;
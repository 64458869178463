import React from 'react';
import { beautifyPhoneNumber } from '../../../../helpers/utils';

const CaremanagerMyAccountForm = ({currentUser}) => {
    
    return (
        <>
            <div className='myAccount_name'>
                <div className='first_name'>
                    <span>First Name</span>
                    <h3>{currentUser.first_name}</h3>
                </div>
                <div className='last_name'>
                    <span>Last Name</span>
                    <h3>{currentUser.last_name}</h3>
                </div>
            </div>
            <div className='phone_number'>
                <span>Contact Number</span>
                <h3>{beautifyPhoneNumber(currentUser.contact_number)}</h3>
            </div>
            <div className='phone_number'>
                <span>Username</span>
                <h3>{currentUser.username}</h3>
            </div>
            <div className='ayush_registered'>
                <span>Ayush Registered?</span>
                <span>
                    {
                        currentUser.metainfo?.ayush_registered === true ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="3" stroke="#7bc62d" fill="none" strokeLinecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M5 12l5 5l10 -10" />
                        </svg> 
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="3" stroke="#ff2825" fill="none" strokeLinecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                       
                    }
                </span>
            </div>
        </>
    )
}

export default CaremanagerMyAccountForm;
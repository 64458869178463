import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ProgramContentNavigation from '../../../components/ProgramContentNavigation/ProgramContentNavigation';
import backIcon from './../../../images/back.png';
import './AddProgram.scss';
import Tabcontainer from '../../../components/Tabcontainer/Tabcontainer';
import Header from '../../../components/Header/Header';
import ProgramForm from '../../../components/ProgramForm/ProgramForm';
import Resource from '../../../services/resource.service';
import Button from '../../../components/Button/Button';


const Addprogram = () => {
    let headerTitle = "Add Program overview";
    let linkToUrl = "/programs";
    let buttonText = "Next";
    let showButton = false;

    const navigate = useNavigate();
    // data that contains where to go back and data need to load the page where it goes back
    const data = {
        pageData:false,
        backTo:'/programs/addProgram'
    }

    const createProgram = ()=> {

        // e.preventDefault();
        console.log('clicked')
        console.log(data)

        const body = {
            title: document.getElementById("program-name").value ,
            description: document.getElementById("program-description").value ,
            duration: parseInt(document.getElementById("program-duration").value, 10)
        }
        
        // console.log(data.id);
        console.log(body);
        Resource.updateProgram(body).then((response) => {
            if(response.success){
                console.log(response.data);
                data.pageData = response.data;
                navigate('/programs/addModules',{state: data})
            }
        });

    }

    return (
        <>
            <Header headerTitle={headerTitle} linkToUrl={linkToUrl}  backgroundColor={"headerBackground"} />
            <div className='addProgramContainer'>
                <ProgramForm />
                <Button  
                    onclick={createProgram}
                    className="next-btn">Next</Button>
            </div>
        </>
    )
}

export default Addprogram;
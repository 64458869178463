import React, { useEffect, useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import Tabcontainer from '../../Tabcontainer/Tabcontainer';
import ClinicPageDetails from './ClinicPageDetails/ClinicPageDetails';
import Button from './../../Button/Button'
import './ClinicPage.scss';
import ClinicAssociateCard from './ClinicAssociateCard/ClinicAssociateCard'
import useModal from '../../../hooks/useModal';
import DoctorEditForm from '../../EditForm/DoctorEditform/DoctorEditform';
import Modal from '../../Modal/Modal';
import AssistantEditForm from '../../EditForm/AssistantEditForm/AssistantEditForm';
import AddDoctorModal from './AddDoctorModal/AddDoctorModal';
import AddAssistantModal from './AddAssistantModal/AddAssistantModal';
import Resource from '../../../services/resource.service';


const ClinicPage = () => {

  const [activeTab, setActivetab] = useState("Doctors");
  const [currentDoctor,setCurrentDoctor] = useState('');
  const [currentAssistant,setCurrentAssistant] = useState('');
  const [doctorData, setDoctorData] = useState([]);
  const [assistantData, setAssistantData] = useState([]);
  
  let location = useLocation();
  let navigate = useNavigate();

  const doctorModal = useModal();
  const assistantModal = useModal();
  const addDoctorModal = useModal();
  const addAssistantModal = useModal();


  const clinicData = location.state;

  let tabLabels = [
    'Doctors','Assistants'
  ];

  const getDoctor = ()=>{
    Resource.getUsers(Resource.ROLES.DOCTOR, null, {'clinic_id': clinicData.id}).then((response) => {
      if (response.success) {
        setDoctorData(response.data);
      }
    });
  }

  function setActiveTab(value) {
    let Modulestab = tabLabels[value];
    setActivetab(Modulestab);

    if(Modulestab == 'Doctors'){
      getDoctor();
    }
    else{
      Resource.getUsers(Resource.ROLES.ASSISTANT, null, {'clinic_id': clinicData.id}).then((response) => {
        if (response.success) {
          setAssistantData(response.data);
        }
      });
    }
  }

  const handleDoctor = (value)=>{
    doctorModal.toggle();
    setCurrentDoctor(value)
  }
  const handleAssistant = (value)=>{
    assistantModal.toggle();
    setCurrentAssistant(value)
  }

  const addAssociate = ()=>{
    if(activeTab === 'Doctors'){
      addDoctorModal.toggle();
    }else{
      addAssistantModal.toggle();
    }
  }

  useEffect(() => {
    getDoctor();
  }, []);

  return (
    <>
      <div className='clinic_page'>
        <div className="support-header">
            <Button buttonType={'icon_btn'} onclick={()=>{navigate('/')}}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#070708" fill="none" strokeLinecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="5" y1="12" x2="9" y2="16" />
                <line x1="5" y1="12" x2="9" y2="8" />
                </svg>
            </Button>
        </div>
        <div className='clinicPageDetails'>
          <ClinicPageDetails clinicData={clinicData} />
        </div>
        <div className='clinic_page_tab_container'>
          <Tabcontainer className={'leftTab'} setActiveTab={setActiveTab} tabs={tabLabels}/>
          <div className='tab_content'>
            <div className='tab_content_header'>
              <Button buttonType={'primary_btn'} onclick={addAssociate} >
                  <svg  width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.5 3.95825V15.0416" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                  <path d="M3.95834 9.5H15.0417" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                  </svg>
                  Add {activeTab ==='Doctors' ? 'Doctor' : 'Assistant'}
              </Button>
            </div>
            {
              activeTab ==='Doctors' ?  
              <div>
                {
                  doctorData && doctorData.map((doctor)=>{
                    return <ClinicAssociateCard data={doctor} handleClick={()=>handleDoctor(doctor)}/>
                  })
                }
              </div>
              :  
                <div>
                {
                  assistantData && assistantData.map((assistant)=>{
                    return <ClinicAssociateCard data={assistant} handleClick={()=>handleAssistant(assistant)}/>
                  })
                }
              </div>            
            }  
          </div>
        </div>
      </div>
      {
        doctorModal.isShowing && 
        <Modal closeModal={() => doctorModal.toggle()} right={'right'}>
          <div className='modal_header'>
            <svg onClick={() => doctorModal.toggle()} className='close-btn' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M18 6L6 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
             <path d="M6 6L18 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
            </svg>
            <Button text={'Save'} buttonType={'primary_btn'} ></Button>
          </div>
          <DoctorEditForm rowDetail={currentDoctor} showVisitingSchedule = {true} />
        </Modal>
      }
      {
        assistantModal.isShowing && 
        <Modal closeModal={() => assistantModal.toggle()} right={'right'}>
          <div className='modal_header'>
            <svg onClick={() => doctorModal.toggle()} className='close-btn' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
              <path d="M6 6L18 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
            </svg>
            <Button text={'Save'} buttonType={'primary_btn'} ></Button>
          </div>
          <AssistantEditForm rowDetail={currentAssistant} showVisitingSchedule = {true} />
        </Modal>
      }
     {
        addDoctorModal.isShowing && 
        <Modal closeModal={() => addDoctorModal.toggle()} right={'right'}>
            <div>
              <svg onClick={() => addDoctorModal.toggle()} className='close-btn' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                  <path d="M6 6L18 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <AddDoctorModal clinicId={clinicData.id} closeModal={() => addDoctorModal.toggle()} getDoctor={getDoctor}/>
        </Modal>
      }
      {
        addAssistantModal.isShowing && 
        <Modal closeModal={() => addAssistantModal.toggle()} right={'right'}>
          <div>
            <svg onClick={() => addAssistantModal.toggle()} className='close-btn' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
              <path d="M6 6L18 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <AddAssistantModal/>
        </Modal>
      }
    </>
  )
}

export default ClinicPage;

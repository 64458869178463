import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// import { ProvideAuth, useAuth } from '../../services/use-auth';
import './PatientRow.scss';

const PatientRow = ({patientsData}) => {

    let navigate = useNavigate();
    
    function goToPatientDetails() {
        if(patientsData.status == 'New')
            navigate(`/lead/${patientsData.id}`, {state: patientsData})
        else
            navigate(`/patient/${patientsData.id}`, {state: patientsData})

    }

    return (
        <div className="patientRow" onClick={goToPatientDetails}>
            <div className='patientRowDetails'>
                <div className='rowDetails1'>
                    <h1 className='patientName'>{patientsData.name}</h1>
                    <h4 className='patientStatus'><span className="activeDot"></span>{patientsData.status}</h4>
                </div>
                <div className='rowDetails2'>
                    <div className='doctorDetails'>
                        <h5 className='doctorTitle'>Doctor</h5>
                        <h2 className='doctorName'>{patientsData.doctor_name}</h2>
                    </div>
                    <div className='cohortDetails'>
                        <h5 className='cohortTitle'>{patientsData.program_title}</h5>
                        <h2 className='cohortStartDate'>{patientsData.program_start_date || '-'}</h2>
                    </div> 
                </div>
            </div>
        </div>
    )

}

export default PatientRow;
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import binIcon from './../../../images/bin.png';
import maximizeIcon from './../../../images/maximaize.png';
import './MediaEditor.scss';

const MediaEditor = ({handleRemove, index}) => {
    const [value, setValue] = useState("");

    let modules = {
        toolbar: [
        [{ align: "center" }, { align: "right" }],
        ["link", "image", "video"],
        ["clean"]
        ]
    };

    let formats = [
        "align",
        "link",
        "image",
        "video"
    ];

    function showValue(value) {
        setValue(value);
        console.log(value, "value");
    }

    return (
        <div className="mediaEditorContainer" id={index}>
            <div className="deleteSection">
                <img src={maximizeIcon} className="maximize-image"  alt="maximizeImage" />
                <img src={binIcon} className="bin-image" onClick={() => {handleRemove(index)}} alt="binImage" />
            </div>
            <ReactQuill theme="snow"  onChange={showValue} modules={modules} formats={formats} />
        </div>
    )
}

export default MediaEditor;
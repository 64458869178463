import { useAuth } from "./../../services/use-auth";
import React from "react";
import Modal from "./../Modal/Modal";
import Button from "./../Button/Button";
import logo from "./../../images/md+.png";
import "./Logout.scss";

const Logout = ({ setShowLogoutModal, closeModal }) => {
  const auth = useAuth();

  const confirmLogout = () => {
    setShowLogoutModal(false);
    auth.signout();
  };

  return (
    <>
      <Modal
        closeModal={(e) => {
          e.stopPropagation();
          console.log(e,'event')
          closeModal();
        }}
      >
        <div className="logout_container">
          <div className="logo">
            <img className="logo_image" src={logo} alt="logoImage"></img>
          </div>
          <h3 className="main_content">Do you want to Logout?</h3>
          <div className="button_section">
          <Button
              buttonType={"primary_btn"}
              text={"Logout"}
              onclick={confirmLogout}
            />
            <Button
              buttonType={"tertiary_btn"}
              text={"Cancel"}
              onclick={closeModal}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Logout;

import React, { useState } from 'react'
import Tabcontainer from './../Tabcontainer/Tabcontainer';
import Tabcontent from './Tabcontent/Tabcontent';
import Timeline from './Timeline/Timeline';
import Goals from './Goals/Goals';
import Progress from './ProgressGraph/ProgressGraph';
import Programs from './Programs/Programs';
import InformationContainer from './InformationContainer/InformationContainer';

const PatientTabContainer = ({ tabLabels }) => {

    const [activeTab, setActivetab] = useState(tabLabels[0]);

    let programData = [
        {
            programName: 'Endometriosis'
        },
        {
            programName: 'PCOS'
        },
        {
            programName: 'Mental Wellbeing'
        }
    ]

    let tabComponents = {
        Goals:<Goals/>,
        Timeline:<Timeline/>,
        Information:<InformationContainer/>,
        Progress:<Progress/>,
        Program:<Programs programData={programData} />
    }

    function setActiveTab(index) {
        setActivetab(tabLabels[index]);
    }

    return (
        <>
           <Tabcontainer className={'leftTab'} setActiveTab={setActiveTab} tabs={tabLabels}/>
           <Tabcontent data={activeTab}>
            {
                tabComponents[activeTab]
            }
           </Tabcontent>
        
        </>
    )
}

export default PatientTabContainer;

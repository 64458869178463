import React from 'react';
import logo from './../../../images/md+.png';
import './IosScreenPopup.scss';

const IosScreenPopup = () => {
    return (
        <div className="popup" id="banner">
            <div className="popup-content"  > 
                <div className="popup-section">
                    <div className='popup-logo'>
                       <img className="logo" src={logo} alt="image" />  
                    </div>
                    <p className="popup-message">Tap 
                    <svg className='share' width="30" height="45" viewBox="0 0 30 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M26.49 11.5668H22.413C22.131 11.5668 21.903 11.8404 21.903 12.1788C21.903 12.5172 22.131 12.7908 22.413 12.7908H25.977V31.176H4.023V12.7908H7.587C7.869 12.7908 8.097 12.5172 8.097 12.1788C8.097 11.8404 7.869 11.5668 7.587 11.5668H3.51C3.228 11.5668 3 11.8404 3 12.1788V31.788C3 32.1264 3.228 32.4 3.51 32.4H26.49C26.772 32.4 27 32.1264 27 31.788V12.1788C27 11.8404 26.772 11.5668 26.49 11.5668Z" fill="black"/>
                       <path d="M15 19.6596C15.282 19.6596 15.51 19.386 15.51 19.0476V5.69159L19.512 10.494C19.611 10.6128 19.743 10.674 19.872 10.674C20.001 10.674 20.133 10.6128 20.232 10.494C20.43 10.2564 20.43 9.86759 20.232 9.62639L15.36 3.77999C15.312 3.72239 15.255 3.67919 15.195 3.64679C15.069 3.58559 14.931 3.58559 14.805 3.64679C14.742 3.67919 14.685 3.72239 14.64 3.77999L9.76502 9.62639C9.56702 9.86399 9.56702 10.2528 9.76502 10.494C9.96302 10.7352 10.287 10.7316 10.488 10.494L14.49 5.69159V19.0476C14.49 19.386 14.718 19.6596 15 19.6596Z" fill="black"/>
                    </svg>
                       & add it to the home screen
                    </p>
                    <svg className='popup-close-icon' id="cancel-install-app" onClick={closePopup} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
                </div>   
            </div>
        </div>
    );

    function closePopup() {
        const popUp = document.getElementsByClassName("popup")[0];
        popUp.style.display = "none";
    }

    
}
export default IosScreenPopup;
import React, { useState,useEffect } from 'react';
import logo from './../../images/md+.png';
import reactDom, { createPortal } from 'react-dom';
import { Link, useNavigate } from 'react-router-dom';
import './RegisterForm.scss';
import Header from './../Header/Header';
import DoctorForm from './DoctorForm/DoctorForm';
import CaremanagerForm from './CaremanagerForm/CaremanagerForm';
import AssistantForm from './AssistantForm/AssistantForm';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../services/use-auth';
import Resource from '../../services/resource.service';
import Button from '../Button/Button';

const RegisterForm = () => {

    let linkToUrl = '/select-role';
    const location = useLocation();
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const auth = useAuth();

    function displayRegisterForm(userRole) {
        console.log(userRole);
        switch(userRole) {
            case 'doctor':
                return <DoctorForm />
            case 'care_manager':
                return <CaremanagerForm />
            case 'assistant':
                return <AssistantForm />
            default:
                return <Navigate to="/select-role" replace={true} />  
        }
    }

    
    const setDimension = () => {
        setScreenSize(window.innerWidth);
        console.log(screenSize,'screensize')
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    if(auth && auth.user && !(auth.user.role &&
        [
            Resource.ROLES.ADMIN, 
            Resource.ROLES.CARE_MANAGER,
            Resource.ROLES.DOCTOR,
            Resource.ROLES.ASSISTANT].includes(auth.user.role)
            ) && location.state.role ) {

        return (
            <div className="form_container" >
                <div className='form_content'>
                    <div className='backButton'>
                        <Link to={linkToUrl}>
                            <svg className='back-image' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 16H23" stroke="#5F6368" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                            <path d="M9 16L15 22" stroke="#5F6368" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                            <path d="M9 16L15 10" stroke="#5F6368" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                            </svg>
                        </Link>
                    </div>
                    <div className='login-container'>
                        <div className='login-logo'>
                            <img className='logo_image' src={logo} alt="logoImage"></img>
                        </div>
                    </div>
                    {
                        displayRegisterForm(location.state.role)
                    }
                    {
                        screenSize > 480 ?
                        <span className='divider'>
                        or
                        </span>
                        :''
                    }
                    <span className='logout'>
                        <a href="#" onClick={() => (auth.signout())}>Logout</a> if you want to continue later
                    </span>
                    {
                        screenSize <= 480 ?
                        <span className='divider'>
                        or
                        </span>
                        :''
                    }
                </div>
            </div>
        );
    }
    else {
        return <Navigate to="/select-role" replace={true} />
    }

};

export default RegisterForm;
import React, { useState, useEffect, useRef } from "react";
import addIcon from './../../images/add.png';
import './ModulesBlock.scss';
import TextEditor from './TextEditor/TextEditor';
import MediaEditor from "./MediaEditor/MediaEditor";
import Tabcontainer from '../Tabcontainer/Tabcontainer';
// import Header from './../Header/Header';
import Button from './../Button/Button';
import Overview from "./Overview/Overview";

const ModulesBlock = ({ModuleData}) => {

    const [activeTab, setActivetab] = useState("Overview");
    const [contentBlockList, setContentBlockList] = useState(
    [
        {
            value: "",
            type: "textEditor"
        }
    ]);

    let tabLabels = [
        'Overview','Content'
    ];

    console.log(ModuleData)
    
    function setActiveTab(value) {
        let Modulestab = tabLabels[value];
        setActivetab(Modulestab);
    }

    function addContentBlock() {
        setContentBlockList([...contentBlockList,  {
            value: "",
            type: "textEditor"
        }]);
    }

    function addMediaBlock() {
        setContentBlockList([...contentBlockList,  {
            value: "",
            type: "mediaEditor"
        }]);
    }

    function showSavedContent() {
        console.log("clicked save button");
    }

    function addNewModule() {
        console.log("clicked add module button");
    }

    function handleRemove(index){
        let list = [...contentBlockList];
        console.log(list,'list before splice')
        list.splice(index, 1);
        console.log(list,'list after splice')
        setContentBlockList(list);
    }
    const saveContentChanges = ()=>{
        console.log('clicked saveContentChanges')
    }

    return (
        <>
            <div className='addModuleContainer'>
                <div className="moduleContainerTab">
                   <Tabcontainer className={'centerTab'} setActiveTab={setActiveTab} tabs={tabLabels}/>
                </div>
                {
                    activeTab ==='Overview' ?  <Overview data={ModuleData} /> 
                    :  
                    <div className="editorComponent">
                        <div className="moduleContentContainer">
                            {
                                contentBlockList.map((item,index) => {
                                    if(item.type === "textEditor") {
                                        return <TextEditor handleRemove={handleRemove} index={index} />
                                    }
                                    else {
                                        return <MediaEditor handleRemove={handleRemove} index={index} />
                                    }   
                                })
                            }  
                        </div>
                        <div className="modulesBtn">
                            <div className="addButtons">
                                <button type="button" className="addContentBlock-btn" onClick={addContentBlock} >
                                    <svg className="add-image" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 3.95834V15.0417" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                        <path d="M3.9585 9.5H15.0418" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    </svg>
                                   Add Content Block
                                </button>
                                <button type="button" className="addMediaBlock-btn" onClick={addMediaBlock} >
                                    <svg className="add-image" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 3.95834V15.0417" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                       <path d="M3.9585 9.5H15.0418" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    </svg>
                                   Add Media Block
                                </button>
                            </div>
                            <div className="contentSaveBtnContainer">
                                <Button 
                                        onclick={saveContentChanges}
                                        text={'Save'} 
                                        buttonType={'primary_btn'}
                                    />
                            </div>
                            
                        </div> 
                    </div>
                }
            </div>
            
        </>
    )
}

export default ModulesBlock;


var config;
if(process.env.NODE_ENV == 'production') {
    config = {
        API_HOST : 'https://symcheck.maasika.in/mdplus',
        PATIENT_QR_HOST: 'https://opd.maasika.in',
    }
}
else if(process.env.NODE_ENV == 'development') {
    config = {
        API_HOST : 'https://test.maasika.in/mdplus',
        PATIENT_QR_HOST: 'http://localhost:3000',
    }
}
else {
    config = {
        API_HOST : 'https://test.maasika.in/mdplus',
        PATIENT_QR_HOST: 'http://test.maasika.in',
    }
}

config['ROLES'] = {
    'DOCTOR': 'doctor',
    'CARE_MANAGER': 'care_manager',
    'ASSISTANT': 'assistant',
    'ADMIN': 'admin',
    'PATIENT': 'patient',
    'MR_SALES_MANAGER': 'mr_sales_manager'
};

export default config;
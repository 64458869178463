import Loader from "../components/Loader/Loader";
import ReactDOM from 'react-dom';

import React from 'react';

function loading() {
    const show = () => {
        ReactDOM.render(
            <Loader display='show' />,
            document.getElementById('response')
        )
    }

    const hide = () => {
        ReactDOM.render(
            <Loader display='hide' />,
            document.getElementById('response')
        )
    }

    return {
        show,
        hide
    }
}

const BusyLoading = loading();
export default BusyLoading;
import React, { useState, useEffect } from 'react';
import MultiInputComponent from './../MultiInputComponent/MultiInputComponent';

const AssistantMyAccountEditForm = ({currentUser}) => {

    const [clinicDataArray, setClinicDataArray] = useState([]);

    const addInputElement = () => {
        let newObject = {
            doctor_id: "",
            assistant_id: "",
            id: "",
            name: "",
            address: ""
        };
        setClinicDataArray([...clinicDataArray,newObject]);
    }

    const removeElement = (index) => {
        let duplicateArray = [...clinicDataArray];
        duplicateArray.splice(index, 1);
        setClinicDataArray(duplicateArray)
    }

    useEffect(() => {
        setClinicDataArray(currentUser.clinics)
        console.log(clinicDataArray,'clinicDataArray');
    },[currentUser])

    useEffect(() => {
        currentUser.editFormData = {
            'first_name': currentUser.first_name,
            'last_name': currentUser.last_name,
            'email': currentUser.email,
            'contact_number': currentUser.contact_number
        };

        return () => {
            delete currentUser.editFormData;
        }
    }, []);
  
    return (
       <>
            <div className='EditmyAccount_form registerForm'>
                <div className='form-element'>
                    <label>First Name</label>
                    <input type="text" defaultValue={currentUser.first_name} onChange={(e) => {currentUser.editFormData.first_name = e.target.value}}/>
                </div>
                <div className='form-element'>
                    <label>Last Name</label>
                    <input type="text" defaultValue={currentUser.last_name} onChange={(e) => {currentUser.editFormData.last_name = e.target.value}}/>
                </div>
                <div className='form-element'>
                    <label>Email</label>
                    <input type="text" defaultValue={currentUser.email} onChange={(e) => {currentUser.editFormData.email = e.target.value}}/>
                </div>
                {/*
                <div className='form-element'>
                    <div className='clinic_component'>
                        <div className="clinic_component_header">
                            <label>Clinic Name</label>
                            <svg onClick={addInputElement} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5V19" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                <path d="M5 12H19" stroke="#0076BA" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <MultiInputComponent clinicNames={clinicDataArray} removeElement={removeElement} />
                    </div>
                </div>
                */}
                <div className='form-element'>
                    <label>10-digit Phone Number</label>
                    <input type="tel" defaultValue={currentUser.contact_number} onChange={(e) => {currentUser.editFormData.contact_number = e.target.value}} />
                </div>
            </div>
        </>
    )
}

export default AssistantMyAccountEditForm;
import React, { useEffect, useState } from 'react';
import './HeaderCounterBox.scss';
import { BrowserRouter as Router, Route, NavLink, Switch, useLocation, useNavigate, withRouter } from 'react-router-dom';
// import { ProvideAuth, useAuth } from '../../services/use-auth';

const HeaderCounterBox = ({headerData}) => {
    
    let selectedValue;

    function showCardCounter(e) {
        selectedValue = e.target.textContent;
        console.log(selectedValue);
    }

    return (
        <div className="header-counter">
            <div className='headerCounterBox' onClick={showCardCounter}>
                <div className='headerCounterDetails'>
                    <h1 className='headerCount'>{headerData.count}</h1>
                    <h4 className='headerCountTitle'>{headerData.title}</h4>
                </div>
            </div>
        </div>
    )

}

export default HeaderCounterBox;
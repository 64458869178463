import HeaderCountBox from './../../../components/AssistantComponents/HeaderCountBox/HeaderCounterBox';
import PatientRow from './../../../components/CareManagerComponents/PatientRow/PatientRow';
import Search from './../../../components/Search/Search';
import Filter from './../../../components/Filter/Filter';
import Button from './../../../components/Button/Button';
import Modal from '../../Modal/Modal';
import AddPatientForm from '../../AddPatientForm/AddPatientForm';
import './CaremanagerHome.scss'
import { useState } from 'react';
import Resource from '../../../services/resource.service';
import { formatPatientData } from '../../../helpers/utils';
import { useEffect } from 'react';
import { useAuth } from '../../../services/use-auth';
import Toast from '../../Toaster/Toast';

const CaremanagerHome = () => {

    const auth = useAuth();
    const [headerCount, setHeaderCount] = useState([])
    const [patientData, setPatientData] = useState([]);
    const [showPatientForm, setShowPatientForm] = useState(false);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        
        Resource.getUsersCount('patient').then((resp) => {
            var countArr = [{
                cout: 0,
                title: 'Total patients'
            }, {
                count: 0,
                title: 'Active patients'
            }, {
                count: 0,
                title: 'Previous patients'
            }];

            if(resp.success) {
                countArr[0].count = resp.data.count;
                //setHeaderCount(countArr);
                console.log(countArr);
                Resource.getUsersCount('patient', {status: 'active'}).then((resp) => {
                    if(resp.success) {
                        countArr[1].count = resp.data.count;
                        Resource.getUsersCount('patient', {status: 'completed'}).then((resp) => {
                            if(resp.success){
                                countArr[2].count = resp.data.count;
                                setHeaderCount(countArr);
                                console.log(countArr);
                            }
                        });
                    }
                });
            }
        });

    }, [auth.user]);

    useEffect(() => {
        Resource.getUsers('patient').then((response) => {
            if(response.success) {
                setPatientData(response.data);
            }
        });

    }, [reload, auth.user]);
    

    const showAddPatientForm = () => {
        setShowPatientForm(true);
    }

    const closeModal = () => {
        setShowPatientForm(false);
    }
    
    function suggestionLookup(query) {
        var otype = 'patient;lead';

        var params = {
            q: query,
            otype: otype
        }

        return Resource.autocomplete(params);
    }

    function callbackLookup(item) {
        if(item) {
            Resource.getUserById(item.type, item.id).then((response) => {
                if(response.success) {
                    setPatientData([response.data]);
                }
                else {
                    Toast.error("ERROR: User not found");
                }
            });
        }
        else {
            setReload(!reload);
        }
    }

    return(
        <div className='patients_page'>
            <div className='patients_page_content'>
                <div className='header_counter_component'>
                    {
                        headerCount.map((headerComponentData) => {
                        return <HeaderCountBox headerData={headerComponentData} />
                        })
                    }
                </div>
                <div className='tool_container'>
                    <div className='tool_components'>
                        <Search suggestionLookup={suggestionLookup} callbackLookup={callbackLookup} />
                        {/* <Filter setFilterChange={setFilterChange}/> */}
                    </div>
                    <Button text={'Add Patient'} buttonType = {'primary_btn'} onclick={showAddPatientForm} />
                    {
                        showPatientForm ?
                        <Modal closeModal={closeModal} right={'right'} >
                            <div className='addPatientForm'>
                                <AddPatientForm closeModal={closeModal} />
                            </div>
                        </Modal>
                        :
                        ''
                    }
                </div>
                <div className='patient_row_component'>
                    {
                        patientData.length > 0 ?
                        patientData.map((patientData) => {
                        return <PatientRow patientsData={patientData} />
                        })
                        :
                        <div className="noDataDiv">
                           <h1 className="noDataContent">No Patients added</h1>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CaremanagerHome;
import React,{useState} from 'react';
import { useEffect, useRef } from 'react';
import Resource from '../../../services/resource.service';
import { useAuth } from '../../../services/use-auth';
import Button from '../../Button/Button';
import './AddPatientForm.scss';

const AddPatientForm = ({callback, closeModal}) => {
    const [selectedGender, setSelectedGender] = useState("");
    const [selectedProgramDuration, setSelectedProgramDuration] = useState("");
    const auth = useAuth();

    const [clinic, setClinic] = useState(-1);
    const [clinics, setClinics] = useState();
    const [doctors, setDoctors] = useState([]);
    const [selectedProgramId, setSelectedProgramId] = useState();
    const [programs, setPrograms] = useState([]);
    
    const selectedProgramRef = useRef({});
    const [programQuote, setProgramQuote] = useState();
    const [programDiscount, setProgramDiscount] = useState(0);

    useEffect(() => {
        Resource.getUsers(Resource.ROLES.DOCTOR).then((response) => {
            if(response.success)
                setDoctors(response.data);
        });

    }, [auth.user]);

    useEffect(() => {
        if(clinic) {
            Resource.getPrograms({doctor_id: clinic}).then((resp) => {
                if(resp.success) {
                    setPrograms(resp.data);
                }
            });
        }
    }, [clinic, auth.user]);

    useEffect(() => {
        if(clinics && clinics.length == 1) {
            setClinic(clinics[0].doctor_id);
        }
    }, [clinics, auth.user]);

    useEffect(() => {
        console.log(selectedProgramDuration, selectedProgramId);
        if(selectedProgramDuration && selectedProgramId) {
            for(let idx in programs) {
                let program = programs[idx];
                if(program.id == selectedProgramId) {
                    console.log('here');
                    let payment_schedule = program?.metainfo?.payment_schedule || program?.payment_schedule;
                    if(payment_schedule) {
                        selectedProgramRef.current = {
                            quote: payment_schedule[selectedProgramDuration].quote,
                            currency: payment_schedule[selectedProgramDuration].currency,
                            discount: payment_schedule[selectedProgramDuration].discount
                        }
                        setProgramQuote(selectedProgramRef.current.quote);
                        setProgramDiscount(selectedProgramRef.current.discount);
                        document.getElementById('p-discount').value = payment_schedule[selectedProgramDuration].discount;
                        break;
                    }
                }
            }   
        }
    }, [selectedProgramId, selectedProgramDuration]);

    useEffect(() => {
        selectedProgramRef.current.discount = programDiscount;
    }, [programDiscount]);

    const onDoctorChange = (e) => {
        var index = e.target.value;
        setClinics(doctors[index].clinics);
    }

    const handleGenderRadioButton = (e) => {
        let currentValue = e.target.value;
        setSelectedGender(currentValue);
    }
    const handleProgramDurationRadBtn = (e) => {
        let currentValue = e.target.value;
        setSelectedProgramDuration(currentValue);
    }

    function addPatient(e) {
        e.preventDefault();
        let Error = "This field cannot be empty";
        // let emailvalueError = "Please provide a valid email-id";
        let namevalueError = "Please provide a name";
        let phonevalueError = "Please provide a 10 digit valid phone number";
        let agevalueError = "Please provide a age between 18 and 99";
        let phoneformat = /^([6-9])(\d{9})$/;

        let doctorname = document.getElementById('p-doctorName');
        let patientname = document.getElementById('p-name');
        let phoneNumber = document.getElementById('p-phone');
        let clinicName = document.getElementById('p-clinicName');
        let age = document.getElementById('p-age');
        let programReferred = document.getElementById('p-programReferred');
        let genderRadioGroup = document.getElementById('genderRadioGroup');
        let programDurationRadioGroup = document.getElementById('programDurationRadioGroup');

        let doctornameerror = document.getElementById('doctorNameerror');
        let patientnameerror = document.getElementById('patientnameerror');
        let phoneerror = document.getElementById('phoneerror');
        let clinicNameerror = document.getElementById('clinicNameerror');
        let ageerror = document.getElementById('ageerror');
        let gendererror = document.getElementById('gendererror');
        let programReferrederror = document.getElementById('programReferrederror');
        let programDurationerror = document.getElementById('durationerror');

        if(doctorname.value || patientname.value || age.value || phoneNumber.value || (clinicName?.clinicName.value) || programReferred.value || selectedGender !== '' || selectedProgramDuration !== ''  ) {
            if (doctorname.value.length > 0) {
                doctorname.classList.remove('invalid');
                doctornameerror.style.display = "none";

                if (patientname.value.length > 0) {
                    patientname.classList.remove('invalid');
                    patientnameerror.style.display = "none";

                    if (age.value >= 9 ) {
                        ageerror.style.display = "none";
                        age.classList.remove("invalid");

                        if(selectedGender !== ''){
                            gendererror.style.display = 'none';

                            if (phoneNumber.value !== '' && phoneNumber.value.match(phoneformat)) {
                                phoneNumber.classList.remove('invalid');
                                phoneerror.style.display = "none";

                                if (clinicName.value && clinicName.value !== '') {
                                    clinicNameerror.style.display = "none";
                                    clinicName.classList.remove("invalid");

                                    if (programReferred.value !== '') {
                                        programReferrederror.style.display = "none";
                                        programReferred.classList.remove("invalid");

                                        if(selectedProgramDuration !== ''){
                                            programDurationerror.style.display = 'none';
                                            let obj = {
                                                name:'',
                                                phone_number:'',
                                                age:'',
                                                sex:'',
                                                program_id:'',
                                                payment_schedule:'',
                                                doctor_id: ''
                                            };
                                            obj.name = patientname.value;
                                            obj.phone_number = '+91' + phoneNumber.value.replace(/\D/g, "");
                                            obj.age = age.value;
                                            obj.doctor_id = clinic;
                                            obj.program_id = programReferred.value;
                                            obj.sex = selectedGender;
                                            obj.payment_schedule = selectedProgramDuration;
                                            obj.program_quote = selectedProgramRef.current.quote;
                                            obj.program_discount = selectedProgramRef.current.discount;
                                            callback(obj);
                                            }
                                        else{
                                            programDurationerror.style.display = "inline";
                                            programDurationerror.innerHTML = Error;
                                        }
                                    } 
                                    else {
                                        programReferred.classList.add('invalid');
                                        programReferrederror.style.display = "inline";
                                        programReferrederror.innerHTML = Error;
                                    }    
                                }
                                else {
                                    clinicName.classList.add('invalid');
                                    clinicNameerror.style.display = "inline";
                                    clinicNameerror.innerHTML = Error;
                                }
                            }
                            else {
                                phoneerror.style.display = "inline";
                                phoneNumber.classList.add("invalid");
                                phoneerror.innerHTML = phonevalueError;
                            }
                        }
                        else{
                            gendererror.style.display = "inline";
                            gendererror.innerHTML = Error;
                        }
                    }
                    else {
                        ageerror.style.display = "inline";
                        age.classList.add("invalid");
                        ageerror.innerHTML = agevalueError;
                    }
                }
                else {
                    patientnameerror.style.display = "inline";
                    patientname.classList.add("invalid");
                    patientnameerror.innerHTML = namevalueError;
                } 
            }
            else{
                doctornameerror.style.display = "inline";
                doctorname.classList.add("invalid");
                doctornameerror.innerHTML = namevalueError;
            } 
        }
        else {
            doctornameerror.style.display = "inline";
            doctorname.classList.add("invalid");
            doctornameerror.innerHTML = namevalueError;

            patientnameerror.style.display = "inline";
            patientname.classList.add("invalid");
            patientnameerror.innerHTML = namevalueError;

            ageerror.style.display = "inline";
            age.classList.add("invalid");
            ageerror.innerHTML = agevalueError;

            phoneNumber.classList.add('invalid');
            phoneerror.style.display = "inline";
            phoneerror.innerHTML = Error;

            programReferred.classList.add('invalid');
            programReferrederror.style.display = "inline";
            programReferrederror.innerHTML = Error;

            gendererror.style.display = "inline";
            gendererror.innerHTML = Error;

            programDurationerror.style.display = "inline";
            programDurationerror.innerHTML = Error;
            if(clinicName.value){
                clinicName.classList.add('invalid');
                clinicNameerror.style.display = "inline";
                clinicNameerror.innerHTML = Error;
            }
        }    
    }
    
    return (
        <div className="addPatientContainer">
                <div className='addPatientheader'>
                    <h1 className="title">Patient Details</h1>
                    <svg onClick={closeModal} className='close-btn' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M6 6L18 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                
                <form autoComplete="disabled"  className="registerForm">
                    <div className='form-element'>
                        <label>Doctor</label>
                        <select id="p-doctorName" onChange={onDoctorChange} className="inputField" placeholder='Select Doctor' required>
                            <option value=''>-- Select Doctor --</option>
                            {
                                doctors && doctors.map((d, idx) => {
                                    return <option value={idx}>{'Dr. ' + d.first_name + ' ' + d.last_name}</option>
                                })
                            
                            }
                        </select>
                        <span className="field-error" id="doctorNameerror"></span>
                    </div>
                    {clinics &&
                    <div className='form-element'>
                        <label>Clinic Name</label>
                        <select disabled={clinics.length == 1 ? true : false} id="p-clinicName" value={clinic} onChange={(e)=>setClinic(e.target.value)} defaultValue={clinic} className="inputField" placeholder='Select Clinic' required>
                            <option value={-1}>-- Select Clinic --</option>
                            {
                                clinics.map((c, idx) => {
                                    return <option value={c.doctor_id}>{c.clinic_name}</option>
                                })
                            
                            }
                        </select>
                        <span className="field-error" id="clinicNameerror"></span>
                    </div>
                    }
                    <div className='form-element'>
                        <label>Patient Name</label>
                        <input type="text" id="p-name" className="inputField" placeholder="Enter patient's name" required />
                        <span className="field-error" id="patientnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Age</label>
                        <input type="number" id="p-age" className="inputField" placeholder="Enter patient's age" required />
                        <span className="field-error" id="ageerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Gender</label>
                            <div className='radioButtonGroup'>
                                <label>
                                    <input type="radio" id="male" name="gender" value="M" onChange={(e)=>handleGenderRadioButton(e)} checked={selectedGender === "M"} />Male
                                </label>
                            </div>
                            <div className='radioButtonGroup'>
                                <label>
                                    <input type="radio" id="female" name="gender" value="F" onChange={(e)=>handleGenderRadioButton(e)} checked={selectedGender === "F"} />Female
                                </label>
                            </div>
                        <span className="field-error" id="gendererror"></span>
                    </div>

                    <div className='form-element'>
                        <label>10-digit Phone Number</label>
                        <input autoComplete="disabled"  type="tel" id="p-phone" className="inputField" placeholder="Enter phone number" required />
                        <span className="field-error" id="phoneerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Program Referred</label>
                        <select id="p-programReferred" className="inputField" placeholder='Select Program' value={selectedProgramId} onChange={(e)=>setSelectedProgramId(e.target.value)} defaultValue={selectedProgramId} required>
                            <option value="">-- Select Program --</option>
                            {
                                programs && programs.map((program, idx) => {
                                    return <option value={program.id}>{program.title}</option>
                                })
                            }
                        </select>
                        <span className="field-error" id="programReferrederror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Payment Schedule</label>
                        <div className='radioButtonGroup'>
                            <label>
                                <input type="radio" id="threemnthDuration" name="paymentSchedule" value="3m" onChange={(e)=>handleProgramDurationRadBtn(e)} checked={selectedProgramDuration === "3m"} />3 Months
                            </label>
                        </div>
                        <div className='radioButtonGroup'>
                            <label>
                                <input type="radio" id="monthlySchedule" name="paymentSchedule" value="monthly" onChange={(e)=>handleProgramDurationRadBtn(e)} checked={selectedProgramDuration === "monthly"} />Monthly
                            </label>
                        </div>
                        <span className="field-error" id="durationerror"></span>
                    </div>
                    {
                        selectedProgramId && selectedProgramDuration &&
                        <>
                        <div className='form-element'>
                            <label>Program Quote</label>
                            <input value={selectedProgramRef.current.currency + ' ' + programQuote + ' ' + (selectedProgramDuration == '3m' ? 'for 3 months' : 'monthly')}
                            disabled type="text" id="p-quote" className="inputField" placeholder="Enter quote" required />
                            <span className="field-error" id="quoteerror"></span>
                        </div>
                        <div className='form-element'>
                            <label>Program Discount</label>
                            <input min="0" onChange={(e) => setProgramDiscount(e.target.value ? parseInt(e.target.value, 10) : 0)}  type="number" id="p-discount" className="inputField" placeholder="Enter discount" required />
                            <span className="field-error" id="discounterror"></span>
                        </div>
                        <label> Patient pays - <strong>{selectedProgramRef.current.currency + ' ' + (programQuote - programDiscount) + ' ' + (selectedProgramDuration == 'monthly' ? 'monthly' : '')}</strong></label>
                        </>
                    }

                    <div className='patientAddBtn'>
                        <Button text={'Add Patient'} onclick={(e)=>addPatient(e)} buttonType={'primary_btn'} />
                    </div>
                </form>
        </div>
    );
}

export default AddPatientForm;
import React from "react";
import { useEffect, useState } from "react";
import Resource from "../../../services/resource.service";
import "./Assistant.scss";
import { Link } from "react-router-dom";
import Button from "./../../Button/Button";
import Modal from "./../../Modal/Modal";
import closeIcon from "./../../../images/close.png";
import AssistantContent from "./AssistantContent/AssistantContent";
import AddAssistantForm from "./../../AdminComponents/AddAssistantForm/AddAssistantForm";
import Toast from "./../../Toaster/Toast";
import { useAuth } from '../../../services/use-auth';

const Assistant = () => {
  const [showModal, setShowModal] = useState(false);
  const [assistantList, setAssistantList] = useState([]);
  const [showAddAssistantForm, setShowAddAssistantForm] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [clinic, setClinic] = useState(-1);
  const [clinics, setClinics] = useState();

  const auth = useAuth();


  function renderAddAssistantForm() {
    setShowModal(true);
    setShowAddAssistantForm(true);
  }

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setClinics(auth.user.clinics);
}, [auth.user]);

useEffect(() => {
  if(clinics && clinics.length == 1) {
      setClinic(clinics[0].doctor_id);
  }
}, [clinics]);


  const registerAssistant = () => {
    var Error = "This field cannot be empty";
    // var emailvalueError = "Please provide a valid email-id";
    var namevalueError = "Please provide a name";
    var phonevalueError = "Please provide a 10 digit valid phone number";
    // var agevalueError = "Please provide a age between 18 and 99";

    let phoneformat = /^([6-9])(\d{9})$/;

    var firstname = document.getElementById("assistant-fname");
    var lastname = document.getElementById("assistant-lname");
    var phonenumber = document.getElementById("assistant-ph");
    var email = document.getElementById("assistant-email");

    var firstnameerror = document.getElementById("firstnameerror");
    var lastnameerror = document.getElementById("lastnameerror");
    var emailerror = document.getElementById("emailerror");
    var phoneerror = document.getElementById("phoneerror");

    if (firstname.value || lastname.value || phonenumber.value ) {
      if (firstname.value.length > 0) {
        firstname.classList.remove("invalid");
        firstnameerror.style.display = "none";

        if (lastname.value.length > 0) {
          lastname.classList.remove("invalid");
          lastnameerror.style.display = "none";

          if (phonenumber.value !== "" && phonenumber.value.match(phoneformat)) {
            phoneerror.classList.remove("invalid");
            phoneerror.style.display = "none";

            var obj = {
                first_name: "",
                last_name: "",
                doctor_id: "",
                assistant_phone_number: "",
                assistant_email: "",
            };
            obj.first_name = firstname.value;
            obj.last_name = lastname.value;
            obj.assistant_phone_number =
            "+91" + phonenumber.value.replace(/\D/g, "");
            obj.doctor_id = assistantList[0].doctor_id;
            obj.assistant_email = email.value;

            console.log(obj);

            Resource.createUser(Resource.ROLES.ASSISTANT, obj).then(
                (resp) => {
                    if (resp.success) {
                        Toast.success("Staff member added successfully!!");
                        setShowAddAssistantForm(false);
                        setShowModal(false);
                    }
                }
            );
          } else {
            phonenumber.classList.add("invalid");
            phoneerror.style.display = "inline";
            phoneerror.innerHTML = phonevalueError;
          }
        } else {
          lastname.classList.add("invalid");
          lastnameerror.style.display = "inline";
          lastnameerror.innerHTML = namevalueError;
        }
      } else {
        firstname.classList.add("invalid");
        firstnameerror.style.display = "inline";
        firstnameerror.innerHTML = namevalueError;
      }
    } else {
      firstnameerror.style.display = "inline";
      firstname.classList.add("invalid");
      firstnameerror.innerHTML = namevalueError;

      lastnameerror.style.display = "inline";
      lastname.classList.add("invalid");
      lastnameerror.innerHTML = namevalueError;

      phoneerror.style.display = "inline";
      phonenumber.classList.add("invalid");
      phoneerror.innerHTML = Error;
    }
  };

  const setDimension = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    Resource.getUsers(Resource.ROLES.ASSISTANT).then((res) => {
      if (res.success) {
        setAssistantList(res.data);
      }
    });
  }, []);

  return (
    <div className="assistant_page_container">
      <div className="title-section">
        <h3 className="title">Your Staff Members</h3>
        <svg
          className="add-btn"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={renderAddAssistantForm}
        >
          <path
            d="M12 5V19"
            stroke="#1C1E1F"
            strokeWidth="1.5"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 12H19"
            stroke="#1C1E1F"
            strokeWidth="1.5"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="assistant_content">
        {assistantList.map((assistant, index) => {
          return (
            <AssistantContent
              assistantData={assistant}
              index={index}
            ></AssistantContent>
          );
        })}
      </div>
      {showModal ? (
        <Modal
          closeModal={closeModal}
          {...(screenSize <= 480 ? { mobileModal: true } : { right: "right" })}
        >
          <div className="profileContainer">
            <div className="profileHeader">
              <div className="closeBtn_container" onClick={closeModal}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 6L6 18"
                    stroke="#1C1E1F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#1C1E1F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              {showAddAssistantForm && (
                <Button
                  text={"Save"}
                  id="save-register"
                  buttonType={"primary_btn"}
                  onclick={() => registerAssistant()}
                />
              )}
            </div>
            {showAddAssistantForm && (
              <div className="AssistantFormContainer">
                <div className="registerForm">
                  <div className='form-element'>
                        <label>Clinic Name</label>
                        <select disabled={clinics.length == 1 ? true : false} id="clinicName" value={clinic} onChange={(e)=>setClinic(e.target.value)} defaultValue={clinic} className="inputField" placeholder='Select clinic'>
                            <option value="">-- Select clinic name --</option>
                                {
                                    clinics && clinics.map((clinic, index) => {
                                      return <option value={clinic.doctor_id}>{clinic.name}</option>
                                    })
                                }
                        </select>
                        <span className="field-error" id="careManagererror"></span>
                    </div>
                  <div className="form-element">
                    <label>First Name</label>
                    <input
                      type="text"
                      id="assistant-fname"
                      className="inputField"
                      placeholder="First name"
                      required
                    />
                    <span className="field-error" id="firstnameerror"></span>
                  </div>

                  <div className="form-element">
                    <label>Last Name</label>
                    <input
                      type="text"
                      id="assistant-lname"
                      className="inputField"
                      placeholder="Last name"
                      required
                    />
                    <span className="field-error" id="lastnameerror"></span>
                  </div>
                  <div className="form-element">
                    <label>10-digit Phone Number</label>
                    <input
                      type="tel"
                      id="assistant-ph"
                      className="inputField"
                      placeholder="+91 "
                      required
                    />
                    <span className="field-error" id="phoneerror"></span>
                  </div>
                  <div className="form-element">
                    <label>Email</label>
                    <input
                      type="email"
                      id="assistant-email"
                      className="inputField"
                      placeholder="your_email@gmail.com "
                    />
                    <span className="field-error" id="emailerror"></span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default Assistant;

import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import './App.scss';
import Sidebar from './components/Sidebar/Sidebar';
import Tabcontainer from './components/Tabcontainer/Tabcontainer';
import Programs from './pages/Programs/Programs';
import UploadDocument from './components/Upload/UploadDocument';
import Chat from './pages/Chat/Chat';
import Settings from './pages/Settings/Settings';
import Addprogram from './pages/Programs/AddProgram/AddProgram';
import { useLocation } from 'react-router-dom'; 
import AddProgram from './pages/Programs/AddProgram/AddProgram';
import AddModule from './pages/Programs/AddModule/AddModule';
import EditModule from './pages/Programs/EditModule/EditModule';
import EditProgram from './pages/Programs/EditProgram/EditProgram';
import Login from './components/Login/Login';
import AssistantHome from './components/AssistantComponents/AssistantHome/AssistantHome';
import CmPatientDetails from './components/CareManagerComponents/CmPatientDetails/CmPatientDetails';
import PatientDetails from './components/AssistantComponents/PatientDetails/PatientDetails';
import GetOtp from './components/GetOtp/GetOtp';
import PrivateRoute from './helpers/PrivateRoute';
import AuthRoute from './helpers/AuthRoute';
import SelectRole from './components/SelectRole/SelectRole';
import RegisterForm from './components/RegisterForm/RegisterForm';
import ProgramDetails from './components/ProgramDetails/ProgramDetails';
import Navigation from './components/Navigation/Navigation';
import { useAuth } from './services/use-auth';
import Home from './pages/Home/Home';
import Logout from './components/Logout/Logout';
import Assistant from './components/DoctorComponents/Assistant/Assistant';
import DocPatientDetails from './components/DoctorComponents/DocPatientDetails/DocPatientDetails';
import MyAccount from './components/MyAccount/MyAccount';
import DocLeadDetails from './components/DoctorComponents/DocLeadDetails/DocLeadDetails';
import MyWallet from './components/DoctorComponents/MyWallet/MyWallet';
import Support from './components/Support/Support';
import ClinicPage from './components/AdminComponents/ClinicPage/ClinicPage';
import MRClinicPage from './components/MRSalesManagerComponents/MRClinicPage/MRClinicPage';
import AndroidScreenPopup from './components/HomescreenPopup/AndroidScreenPopup/AndroidScreenPopup';
import logo from './images/mlogo.png';
import MRSalesManagerHome from './components/MRSalesManagerComponents/Home/Home';
import SelfCheckin from './components/SelfCheckin/SelfCheckin';
import NotFound from './components/NotFound/NotFound';

function App() {
  const [showNav,setShowNav] = useState(false);
  const [currentRole,setCurrentRole] = useState('');
  const [showPopup,setShowPopup] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  var auth = useAuth();

  function closePopup() {
    const popUp = document.querySelector(".allScreenAndroidPopup");
    popUp.style.display = "none";
  }

  const setDimension = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  let DoctorRoute = [
    { path:'/lead/:id', component:<DocLeadDetails/>},
    { path:'/patient/:id', component:<DocPatientDetails />},
    { path:'/programs', component:<Programs />},
    { path:'/programs/programdetails', component:<ProgramDetails/> },
    { path:'/programs/editModule', component:<EditModule />},
    { path:'/programs/addModules', component:<AddModule/> },
    { path:'/wallet', component:<MyWallet/> },
    { path:'/support', component:<Support/> },
    { path:'/assistant', component:<Assistant />}
  ]

  let AssistantRoute = [
    { path:'/lead/:id', component:<PatientDetails/>},
    { path:'/patient/:id', component:<PatientDetails />},
    { path:'/support', component:<Support/> },
  ];

  let CareManagerRoute = [ 
    { path:'/patient/:id', component:<CmPatientDetails/>},
    { path:'/patient/:id/:id', component:<CmPatientDetails/>},
    { path:'/support', component:<Support/> },
  ];

  let AdminRoute = [
    { path:'/programs', component:<Programs/> },
    { path:'/chat', component:<Chat/> },
    { path:'/lead/:id', component:<PatientDetails/>},
    { path:'/patient/:id', component:<PatientDetails />},
    // { path:'/settings', component:<Settings/> },
    { path:'/programs/addProgram', component:<AddProgram/> },
    { path:'/programs/addModules', component:<AddModule/> },
    { path:'/programs/editmodule', component:<EditModule/> },
    { path:'/programs/editProgram', component:<EditProgram/> },
    { path:'/programs/programdetails', component:<ProgramDetails/> },
    { path:'/support', component:<Support/> },
    { path:'/clinic/:id', component:<ClinicPage/> }
  ];

  let MRSalesManagerRoute = [
    { path:'/programs', component:<Programs/> },
    { path:'/chat', component:<Chat/> },
    { path:'/lead/:id', component:<PatientDetails/>},
    { path:'/patient/:id', component:<PatientDetails />},
    // { path:'/settings', component:<Settings/> },
    { path:'/programs/addProgram', component:<AddProgram/> },
    { path:'/programs/addModules', component:<AddModule/> },
    { path:'/programs/editmodule', component:<EditModule/> },
    { path:'/programs/editProgram', component:<EditProgram/> },
    { path:'/programs/programdetails', component:<ProgramDetails/> },
    { path:'/support', component:<Support/> },
    { path:'/clinic/:id', component:<MRClinicPage/> }
  ];

   const userType = {
    'doctor': DoctorRoute,
    'care_manager': CareManagerRoute,
    'assistant': AssistantRoute,
    'admin': AdminRoute,
    'mr_sales_manager': MRSalesManagerRoute,
    // 'patient': 'patient,
    //'default':DefaultRoute
   }


  useEffect(()=>{
    if(auth.user && auth.user.role && auth.user.role != 'default'){
      setShowNav(true);
      setCurrentRole(auth.user.role);
      setShowPopup(true);

    }else{
      console.log('iam not logged in');
      setShowNav(false);
      setShowPopup(false);
    }
  },[auth]);

  useEffect(()=>{
      if (matchMedia('(display-mode: standalone)').matches || navigator && navigator.standalone) {  
          setShowPopup(false);
      } 
  },[]);

  return (
    <>
    {
      showNav && <Navigation role={currentRole}/>
    }
      <Routes>
        <Route path="/" element={<AuthRoute><Login /></AuthRoute>}></Route>;
        <Route path="/lifestyle-counselling/:code" element={<SelfCheckin />}></Route>
        <Route path="/select-role" element={<PrivateRoute><SelectRole /></PrivateRoute>}></Route>;
        <Route path="/register" element={<PrivateRoute><RegisterForm /></PrivateRoute>}></Route>;
        <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>}></Route>;
        <Route path="/account" element={<PrivateRoute><MyAccount /></PrivateRoute>}></Route>;
        {
          currentRole && currentRole in userType && userType[currentRole].map(({path,component})=>{
            return <Route key={path} path={path} element={<PrivateRoute>{component}</PrivateRoute>}/>;
          })
        }

        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <div>
        {/*
          auth.user && auth.user.role && auth.user.role != 'default' && screenSize <= 500 && showPopup &&
            <div className="allScreenAndroidPopup" id="banner"> 
                  <div className="popup-section">
                      <div className='popup-logo'>
                        <img className="logo" src={logo} alt="image" />  
                      </div>
                      <p className="popup-message">Tap "Install" to add it to the Home screen</p>
                      <svg className='popup-close-icon' id="cancel-install-app" onClick={closePopup} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
                  </div> 
                  <div className='installBtnDiv'>
                    <button id="install-app" className="installBtn" >Install</button>
                  </div>  
          </div>
        */}  
      </div>
    </>
  );
}

export default App;

import React from 'react';
import Header from '../../../components/Header/Header';
import ModulesBlock from '../../../components/ModulesBlock/ModulesBlock';
import { Link, useLocation } from 'react-router-dom';
import Button from './../../../components/Button/Button';
import './EditModule.scss';

const EditModule = () => {
    let headerTitle = "Edit Module";
    let linkToUrl = '/programs/programdetails';
    let buttonText = "Save";
    let showButton = true;

    let location = useLocation();
    const {programData,moduleData} = location.state;
  
    return (
        <>
            <Header 
                headerTitle={headerTitle}
                linkToUrl={linkToUrl}
                background={"headerBackground"}
                pageData={programData}
            />
            <ModulesBlock ModuleData={moduleData}/>
        </>
    )
}

export default EditModule;
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from '../services/use-auth';


const AuthRoute = ({ children }) => {
    let auth = useAuth();
    return (auth && auth.user) ? (
        <Navigate to="/home" replace={true} />
    ) : (
        children
    )
}

export default AuthRoute;

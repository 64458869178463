import React,{useRef,useState,useEffect} from 'react';
import closeIcon from './../../../images/close.png';
import { Link, useLocation } from 'react-router-dom';
import { register } from '../../../serviceWorkerRegistration';
import Button from '../../Button/Button';
import Resource from '../../../services/resource.service';
import { useAuth } from '../../../services/use-auth';
import Toast from '../../Toaster/Toast';
import useHandleEnter from '../../../hooks/useHandleEnter';
import './AssistantForm.scss';

const AssistantForm = () => {

    const auth = useAuth();
    const location = useLocation();
    const role = location.state.role;
    const {handleKeyDown} = useHandleEnter();

    const [showClinicSuggestion , setShowClinicSuggestion] = useState(false);
    const [showDoctorSuggestion , setShowDoctorSuggestion] = useState(false);
    const [showAllDoctorCheckbox , setShowAllDoctorCheckbox] = useState(false);
    const [isAllDoctorChecked , setIsAllDoctorChecked] = useState(false);
    const [showSelectedDoctorsContainer, setShowSelectedDoctorsContainer] = useState(false);

    const [matchedClinicData,setMatchedClinicData] = useState([]);
    const [matchedDoctorData,setMatchedDoctorData] = useState([]);

    const [DoctorsSelectedArray,setDoctorsSelectedArray] = useState([]);

    let searchClinicRef = useRef();
    let searchDoctorRef = useRef();
    let clinicSuggestionRef = useRef();
    let doctorSuggestionRef = useRef();

    let doctorSelectedItemsRef = useRef([]);
    
    function getSelectedClinicSearchValue(item){
        setShowClinicSuggestion(false);
        setShowAllDoctorCheckbox(true);
        searchClinicRef.current.value = item.name ;
        searchClinicRef.current.item = item;
        setMatchedClinicData([]);
        searchClinicRef.current.focus();

    }

    function getSelectedDoctorSearchValue(item){
        console.log(item);
        let updatedDoctorListArray;
        setShowDoctorSuggestion(false);
        setShowSelectedDoctorsContainer(true);
        if(!DoctorsSelectedArray.includes(item.id)){
            updatedDoctorListArray = [...DoctorsSelectedArray,item.id]
            doctorSelectedItemsRef.current = [...doctorSelectedItemsRef.current, item];
        }else{
            updatedDoctorListArray = [...DoctorsSelectedArray];
        }
        setDoctorsSelectedArray(updatedDoctorListArray);
        searchDoctorRef.current.value = '' ;
        setMatchedDoctorData([]);
        searchDoctorRef.current.focus();
    }

    function removeItemFromArray(id){
        let array = [...DoctorsSelectedArray]
        let indexOfDoctor = array.indexOf(id);
        if(indexOfDoctor > -1){
            array.splice(indexOfDoctor, 1);
            setDoctorsSelectedArray(array);
            doctorSelectedItemsRef.current.splice(indexOfDoctor, 1);
        }
    }

    function CheckAllDoctor(){
        setIsAllDoctorChecked(!isAllDoctorChecked)
    }

    function onChangeClinicSearchInput(e) {
        let value = e.target.value;
        setShowAllDoctorCheckbox(false);
        if(value.length > 0){
            setShowClinicSuggestion(true);
            Resource.autocomplete({q: value, otype: 'clinic'}).then((response) => {
                if(response.success) {
                    setMatchedClinicData(response.data.matches);
                }
            });
        } else{
            setShowClinicSuggestion(false);
        }
    }

    function onChangeDoctorSearchInput(e) {
        let value = e.target.value;
        if(value.length > 0){
            setShowDoctorSuggestion(true);
            Resource.autocomplete({q: value, otype: 'doctor'}).then((response) => {
                if(response.success) {
                    setMatchedDoctorData(response.data.matches);
                }
            });
        } else{
            setShowDoctorSuggestion(false)
        }
    }

    const registerAssistant = (e) => {
        e.preventDefault();
        var Error = "This field cannot be empty";
        // var emailvalueError = "Please provide a valid email-id";
        var namevalueError = Error;
        // var phonevalueError = "Please provide a 10 digit valid phone number";
        // var agevalueError = "Please provide a age between 18 and 99";
        var firstname = document.getElementById('assistant-fname');
        var lastname = document.getElementById('assistant-lname');
        var searchByClinic = document.getElementById('search-clinic');
        var searchByDoctor = document.getElementById('search-doctor');

        var firstnameerror = document.getElementById('firstnameerror');
        var lastnameerror = document.getElementById('lastnameerror');
        var searchByClinicError = document.getElementById('searchByClinicError');
        var searchByDoctorError = document.getElementById('searchByDoctorError');

        if (firstname.value || lastname.value || searchByClinic.value || searchByDoctor.value ) {
            if (firstname.value.length > 0) {
                firstname.classList.remove('invalid');
                firstnameerror.style.display = "none";

                if (lastname.value.length > 0) {
                    lastname.classList.remove('invalid');
                    lastnameerror.style.display = "none";

                    if (searchByClinic.value !== '') {
                        searchByClinic.classList.remove('invalid');
                        searchByClinicError.style.display = "none";

                        if (isAllDoctorChecked || DoctorsSelectedArray.length > 0) {
                            if(!isAllDoctorChecked) {
                                searchByDoctor.classList.remove("invalid");
                                searchByDoctorError.style.display = "none";
                            }
                            var obj = {
                                first_name: '',
                                last_name: '',
                                role: role,
                                clinic: '',
                                doctors: ''
                            };
                            obj.first_name = firstname.value;
                            obj.last_name = lastname.value;
                            obj.clinic_id = searchClinicRef.current.item.id;
                            obj.all_doctors = isAllDoctorChecked;
                            obj.doctors = DoctorsSelectedArray;
                            obj.contact_number = auth.user ? auth.user.username : null;
                            
                            Resource.updateSelf(obj).then((resp) => {
                                if(resp.success) {
                                    auth.getConfig({role: role}).then((resp) => {
                                        Toast.success("Registration form submitted successfully!!");  
                                    });
                                }
                            });
                            
                        }
                        else {
                            searchByDoctor.classList.add('invalid');
                            searchByDoctorError.style.display = "inline";
                            searchByDoctorError.innerHTML = Error;
                        }
                    }
                    else {
                        searchByClinic.classList.add('invalid');
                        searchByClinicError.style.display = "inline";
                        searchByClinicError.innerHTML = Error;
                    }
                }
                else {
                    lastname.classList.add("invalid");
                    lastnameerror.style.display = "inline";
                    lastnameerror.innerHTML = namevalueError;
                }
            }
            else {
                firstname.classList.add("invalid");
                firstnameerror.style.display = "inline";
                firstnameerror.innerHTML = namevalueError;
            }
        }
        else {
            firstnameerror.style.display = "inline";
            firstname.classList.add("invalid");
            firstnameerror.innerHTML = namevalueError;

            lastnameerror.style.display = "inline";
            lastname.classList.add("invalid");
            lastnameerror.innerHTML = namevalueError;

            searchByClinicError.style.display = "inline";
            searchByClinic.classList.add('invalid');
            searchByClinicError.innerHTML = Error;

            searchByDoctorError.style.display = "inline";
            searchByDoctor.classList.add('invalid');
            searchByDoctorError.innerHTML = Error;
        }    
        
    }
    
    function handleClickOutside(e) {
        if (clinicSuggestionRef.current && !clinicSuggestionRef.current.contains(e.target)) { 
            setShowClinicSuggestion(false);
        }
        if (doctorSuggestionRef.current && !doctorSuggestionRef.current.contains(e.target)) { 
            setShowDoctorSuggestion(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        });
    },[showDoctorSuggestion,showClinicSuggestion]);

    return (
        role == Resource.ROLES.ASSISTANT &&
       <div className='addAssistant container'>
           <div className='addAssistantContainer'>
                    <h1 className="title">Sign up as a Assistant / Frontdesk</h1>
            </div>
                
                <form autoComplete="disabled"  className="registerForm addAssistant-form">
                    
                    <div className='form-element'>
                        <label>First Name</label>
                        <input type="text" id="assistant-fname" className="inputField" placeholder="First name" required />
                        <span className="field-error" id="firstnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Last Name</label>
                        <input type="text" id="assistant-lname" className="inputField" placeholder="Last name" required />
                        <span className="field-error" id="lastnameerror"></span>
                    </div>
                    <div className='form-element clinicSearchGroup'> 
                        <label>Search by Clinic</label>
                        <input autoComplete="disabled" type="text" id="search-clinic" ref={searchClinicRef} className="inputField" placeholder="Search clinic by name" onChange={(e)=>onChangeClinicSearchInput(e)} />
                        <span className="field-error" id="searchByClinicError"></span>
                          {
                            showClinicSuggestion ? 
                            <div className="suggestionBox" ref={clinicSuggestionRef}>
                                {
                                matchedClinicData.length > 0 ? matchedClinicData.map((item)=>{
                                        return  (
                                            <>
                                                <div className="searchSuggestion" onClick={()=>getSelectedClinicSearchValue(item)}>
                                                    <span className="searchValue">
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    }) :
                                    <span className="noDataFound" >No data matched</span>
                                }
                            </div>
                            :
                            ' '
                        }
                        {
                            showAllDoctorCheckbox && 
                            <div className='allDoctorCheckBox'>
                                <input
                                    id='allDoctor'
                                    type="checkbox"
                                    checked={isAllDoctorChecked}
                                    onChange={CheckAllDoctor}/>
                                <label for='allDoctor' >
                                    Select all doctors of {searchClinicRef.current.value}
                                </label>
                            </div>
                           
                           
                        }
                    </div>
                    {
                        isAllDoctorChecked ? '' : 
                        <div className='form-element doctorSearchGroup'> 
                            <label>Search by Doctor</label>
                            {
                               DoctorsSelectedArray.length > 0 && showSelectedDoctorsContainer && 
                                <div className='SelectedDoctorContainer'>
                                    {
                                        DoctorsSelectedArray.length > 0 ? DoctorsSelectedArray.map((id, idx) =>{
                                            return <div className='selectedDoctorItem'>
                                                <span>
                                                    {doctorSelectedItemsRef.current[idx].name} 
                                                </span>
                                                <svg onClick={()=>removeItemFromArray(id)} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                assistanterror        <path d="M18 6L6 18" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                    <path d="M6 6L18 18" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        }) 
                                        :''
                                    }
                                </div>
                            }
                            <input autoComplete="disabled" type="text" id="search-doctor" ref={searchDoctorRef} className="inputField" placeholder="Search doctor by name" onKeyDown={(e) => handleKeyDown(e,registerAssistant)} onChange={(e)=>onChangeDoctorSearchInput(e)} />
                            <span className="field-error" id="searchByDoctorError"></span>
                            {
                                showDoctorSuggestion ? 
                                <div className="suggestionBox" ref={doctorSuggestionRef}>
                                    {
                                        matchedDoctorData.length > 0 ? matchedDoctorData.map((item)=>{
                                            return  (
                                                <>
                                                    <div className="searchSuggestion" onClick={()=>getSelectedDoctorSearchValue(item)}>
                                                        <span className="searchValue">
                                                            {item.name}
                                                        </span>
                                                    </div>
                                                </>
                                            )
                                        }) :
                                        <span className="noDataFound" >No data matched</span>
                                    }
                                </div>
                                :
                                ' '
                            }
                        </div>
                    }
                    <div className='bottomElement'>
                      <Button text={'Register'} className={'buttonBottomStyle'} buttonType={'primary_btn'} onclick={registerAssistant} size={'fluid'}></Button>
                    </div>
                    
                </form>
       </div>
    );

};

export default AssistantForm;
import React, { useState,useEffect } from 'react';
import { useAuth } from "./../../services/use-auth";
import Resource from "../../services/resource.service";
import AdminMyAccountEditForm from './MyAccountEditForm/AdminMyAccountEditForm/AdminMyAccountEditForm';
import DoctorMyAccountEditForm from './MyAccountEditForm/DoctorMyAccountEditForm/DoctorMyAccountEditForm';
import CaremanagerMyAccountEditForm from './MyAccountEditForm/CaremanagerMyAccountEditForm/CaremanagerMyAccountEditForm';
import AssistantMyAccountEditForm from './MyAccountEditForm/AssistantMyAccountEditForm/AssistantMyAccountEditForm';
import AdminMyAccountForm from './MyAccountForm/AdminMyAccountForm/AdminMyAccountForm';
import DoctorMyAccountForm from './MyAccountForm/DoctorMyAccountForm/DoctorMyAccountForm';
import CaremanagerMyAccountForm from './MyAccountForm/CaremanagerMyAccountForm/CaremanagerMyAccountForm';
import AssistantMyAccountForm from './MyAccountForm/AssistantMyAccountForm/AssistantMyAccountForm';
import Button from './../Button/Button';
import Header from './../Header/Header';
import './MyAccount.scss';
import { useNavigate } from 'react-router-dom';
import Toast from '../Toaster/Toast';

const MyAccount = () => {

    const [showEditForm, setShowEditForm] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const auth = useAuth();
    const navigate = useNavigate();
    let currentUser = auth.user;

    function renderEditForm() {
        setShowEditForm(true);
    }

    function showDetails() {
        setShowEditForm(false);
    }

    const saveAccountData = () => {
        console.log(currentUser.editFormData);
        if(currentUser.editFormData) {
            Resource.updateSelf(currentUser.editFormData).then((resource) => {
                if(resource.success) {
                    //Toast.success('Profile updated successfully!!');
                    auth.getConfig().then((resp) => {
                        showDetails();
                    });
                }
                else {
                    //Toast.success('ERROR: Profile could not be updated.')
                }
            });
        }
    }

    function DisplayMyAccountEditForm() {
        if(currentUser.role === Resource.ROLES.ADMIN) {
            return <AdminMyAccountEditForm currentUser={currentUser} />
        }
        else if(currentUser.role === Resource.ROLES.DOCTOR) {
            return <DoctorMyAccountEditForm currentUser={currentUser} />
        }
        else if(currentUser.role === Resource.ROLES.CARE_MANAGER) {
            return <CaremanagerMyAccountEditForm currentUser={currentUser} />
        }  
        else {
            return <AssistantMyAccountEditForm currentUser={currentUser} />
        }  
    }

    function DisplayMyAccountForm() {
        if(currentUser.role === Resource.ROLES.ADMIN) {
            return <AdminMyAccountForm currentUser={currentUser} />
        }
        else if(currentUser.role === Resource.ROLES.DOCTOR) {
            return <DoctorMyAccountForm currentUser={currentUser} />
        }
        else if(currentUser.role === Resource.ROLES.CARE_MANAGER) {
            return <CaremanagerMyAccountForm currentUser={currentUser} />
        }  
        else {
            return <AssistantMyAccountForm currentUser={currentUser} />
        }  
    }
    const setDimension = () => {
        setScreenSize(window.innerWidth);
    }    
    
    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    return (
        <>
            <div className='myAccount_Container'>
                <div className='myAccount_Header'>
                    <div className='headerGroup'>
                        {
                            showEditForm ?
                            <Button buttonType={'icon_btn'} onclick={()=>{setShowEditForm(false)}}>
                               <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <line x1="5" y1="12" x2="19" y2="12" />
                                <line x1="5" y1="12" x2="11" y2="18" />
                                <line x1="5" y1="12" x2="11" y2="6" />
                                </svg>
                            </Button>
                            :
                            <Button buttonType={'icon_btn'} onclick={()=>{navigate('/')}}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <line x1="5" y1="12" x2="19" y2="12" />
                                <line x1="5" y1="12" x2="11" y2="18" />
                                <line x1="5" y1="12" x2="11" y2="6" />
                                </svg>
                            </Button>
                        }
                        <h1 className='myAccount_Title'>{showEditForm ? 'Edit Account':'My Account'}</h1>
                    </div>
                    {
                        showEditForm ? 
                        (screenSize >= 480 ? 
                            <Button text={'Save'} buttonType={'primary_btn'} onclick={saveAccountData} />
                            : ''
                        )
                        :
                        <Button buttonType={'icon_btn'} onclick={renderEditForm}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1c1e1f" fill="none" strokeLinecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                            <line x1="16" y1="5" x2="19" y2="8" />
                            </svg> 
                       </Button>
                    }
                </div>
                <div className='container'>
                    {
                        showEditForm ?
                        <DisplayMyAccountEditForm />
                        :
                        <DisplayMyAccountForm />
                    }
                </div>
              
            </div>
            {
                screenSize <= 480 && showEditForm? 
                    <div className='myAccountBottomElement'>
                        <Button text={'Save'} buttonType={'primary_btn'} onclick={saveAccountData} />
                    </div>
                    :''
                }
        </>
        
    )
}

export default MyAccount;
import React,{useState} from 'react';
import './UpcomingAppointmentWidget.scss';
import Button from '../Button/Button';

const UpcomingAppointmentWidget = () =>{
    const [seeMore,setSeeMore] = useState(true);
    const cancelSchedule = ()=>{

    }
    const showMore = () => {
        setSeeMore(false)
    }
    return (
        <>
            <div className='upcoming_appointments_widget_container'>
                <h4 className='appointments_heading'>Upcoming Appointments</h4>
                <div className='appointments_container'>
                    <div className='appointment'>
                        <div className='appointment_row1'>
                            <div className='appointment_date_and_day'>
                                <div className='appointment_date'>
                                    02.9.2022
                                </div>
                                <div className='appointment_day'>
                                    Wednesday
                                </div>
                            </div>
                            <Button text={'Cancel'} buttonType={'tertiary_btn'} onclick={cancelSchedule}/>
                        </div>  
                        <div className='appointment_row2'>
                            <div className='appointment_time'>
                                7:00 AM - 8:00 AM
                            </div>
                        </div>
                    </div>
                    {/* {
                        seeMore ?  <button className='seeMore_btn' onClick={showMore}> See More </button> : ''
                    }
                    */}
                </div>
            </div>
        </>
    )
}

export default UpcomingAppointmentWidget;
import React, { useEffect,useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './ProgramModule.scss';

const ProgramModule = ({module,programDetailsData}) => {

    const [showDesc,setShowDesc] = useState(false);

    function showDescription(){
       setShowDesc(!showDesc)
   }

   const data = {
       programData:programDetailsData,
       moduleData:module
   }

    return(
        <div className='module'>
        <div className='module_content' >
            <h2 className='module_name'>{module.name}</h2>
            <div className='module_option'>
                {/* <Link to='/programs/preview' className="preview_btn" >Preview</Link> */}
                <Link 
                    to='/programs/editModule'
                    state={data}
                    className="edit_module"
                    >Edit</Link>
            </div>
           
            <div className='accordation_icon' onClick={showDescription}>
            {
                showDesc ? 
                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 15L12 9L18 15" stroke="#070708" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                </svg>
                :
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M6 9L12 15L18 9" stroke="#070708" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                </svg>
               
            }
            </div> 
           
        </div>
        {
            showDesc ?
             <div className='module_description'>
                <p>{module.summary}</p>
            </div> 
            :''
        }
    </div>
    );
}

export default ProgramModule;
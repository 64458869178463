import React from "react";

const Goals = () => {

    return (
        <>
           <h1>I am Goals tab</h1>
        </>
    )
}

export default Goals;
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss'

const Modal = ({ children, closeModal, center, right, mobileModal ,position}) => {

    return createPortal(
          <div className="modal " onClick={closeModal}>
            <div className={`modal-content ${mobileModal && 'mobile-modalContent' } ${ center ? 'appear-center' :''} ${ right ? 'appear-right' :''} ${position && position}`} onClick={(e)=>e.stopPropagation()} >
              {children}
            </div>
          </div>
        ,
        document.getElementById('modal-root')
    );

};

export default Modal;
import React from 'react'
import './Button.scss';

const Button = ({text,buttonType,onclick,children,size,textSize,fontWeight,color,disable,className}) =>{
    return (
        <>
          <button style={{color:color}} className={`callToActionBtn ${buttonType} ${size} ${textSize} ${fontWeight} ${disable && 'disable'} ${className}`} onClick={onclick} disabled={disable}>{children}{text}</button>
        </>
    )
}

export default Button;


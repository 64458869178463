import { useState } from 'react';
import Toast from '../../Toaster/Toast';
import Resource from '../../../services/resource.service';
import './AssistantList.scss';
import { beautifyPhoneNumber } from '../../../helpers/utils';
import addIcon from './../../../images/add.png';

const AssistantList = ({ assistant_list, renderAddAssistant }) => {
    console.log(assistant_list);
    const [renderItem, toggleRenderItem] = useState(true);

    function approveAssistant (e) {
        let id = e.target.id.replace('approve', '');
        id = parseInt(id, 10);
        let index = e.target.getAttribute('index');
        let data = {
            auth_id: id,
            type: Resource.ROLES.ASSISTANT,
            parent_id: e.target.getAttribute('parent_id'),
            status: 'approved'
        }
        
        Resource.updateStatus(data).then((response) => {
            if(response.success) {
                Toast.success("Success: Approved staff access successfully!!");
                assistant_list[index].status = data.status;
                assistant_list.sort((a, b) => (a.status == 'revoked' ? 1 : (b.status == 'revoked' ? -1 : (a.status == 'pending' ? -1 : 1))));
                toggleRenderItem(!renderItem);
            }
            else {
                Toast.error("Error updating the status");
            }
        });
    }

    function revokeAssistant (e) {
        let id = e.target.id.replace('revoke', '');
        id = parseInt(id, 10);
        let index = e.target.getAttribute('index');
        let data = {
            auth_id: id,
            type: Resource.ROLES.ASSISTANT,
            parent_id: e.target.getAttribute('parent_id'),
            status: 'revoked'
        }
        
        Resource.updateStatus(data).then((response) => {
            if(response.success) {
                Toast.success("Success: Revoked staff access successfully!!");
                assistant_list[index].status = data.status;
                assistant_list.sort((a, b) => (a.status == 'revoked' ? 1 : (b.status == 'revoked' ? -1 : (a.status == 'pending' ? -1 : 1))));
                toggleRenderItem(!renderItem);
            }
            else {
                Toast.error("Error updating the status");
            }
        });
    }

    function renderAssistantForm() {
        renderAddAssistant();
    }

    return (
        <div className="section support-staff">
            <div className='title-section'>
                <h3 className='title'>Support staff</h3>
                <svg  className="add-btn" onClick={renderAssistantForm} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M12 5V19" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                   <path d="M5 12H19" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div className="staff-list">
                {
                    assistant_list.map( (assistant, index) => {
                        return (
                            <div id={index} key={assistant.id} className='item'>
                                {/*<div className='field'>
                                    <div className='field-label'>Name:</div>
                                    <div className='field-value'>{assistant.first_name + ' ' + assistant.last_name}</div>
                                </div>
                                <div className='field'>
                                    <div className='field-label'>Phone number:</div>
                                    <div className='field-value'>{beautifyPhoneNumber(assistant.contact_number || assistant.username)}</div>
                                </div>
                                <div className='field'>
                                    <div className='field-label'>Status:</div>
                                    <div className={`field-value ${assistant.status}`}>{assistant.status.slice(0,1).toUpperCase() + assistant.status.slice(1)}</div>
                                </div>*/}
                                <div className='field'>
                                    <div className='name_status_group'>
                                        <h3 className='name-value'>{assistant.first_name + ' ' + assistant.last_name}</h3>
                                        <div className={`field-value ${assistant.status}`}>{assistant.status.slice(0,1).toUpperCase() + assistant.status.slice(1)}</div>
                                    </div>
                                    <div className='clinic-value'>{assistant.clinic_name}</div>
                                    <div className='phoneNumber-value'>{beautifyPhoneNumber(assistant.contact_number || assistant.username)}</div>
                                </div>
                                <div className='btn-wrap'>
                                {
                                    assistant.status == "approved" ? 
                                        <button className='revoke-btn' id={'revoke' + assistant.id} parent_id={assistant.doctor_id} index={index} onClick={(revokeAssistant)}>Revoke access</button> :

                                        (
                                            assistant.status == "pending" ? 
                                                <button className='revoke-btn' id={'revoke' + assistant.id} parent_id={assistant.doctor_id} index={index} onClick={(revokeAssistant)}>Revoke access</button>:''
                                        )
                                }
                                {
                                assistant.status != "approved" ?
                                    <button className='approve-btn' id={'approve' + assistant.id} parent_id={assistant.doctor_id} index={index} onClick={(approveAssistant)}>Approve access</button>:''
                                }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default AssistantList;
import React, { useState } from "react";
import { useEffect } from "react";
import { Link,useNavigate } from 'react-router-dom';
import editIcon from './../../images/edit.png';
import closeIcon from './../../images/close.png';
import Modal from "../Modal/Modal";
import './Table.scss';
import DoctorEditForm from '../EditForm/DoctorEditform/DoctorEditform';
import CaremanagerEditForm from '../EditForm/CaremanagerEditform/CaremanagerEditform';
import PatientEditForm  from '../EditForm/PatientEditform/PatientEditform';
import Resource from "../../services/resource.service";
import ProfileView from "../AdminComponents/ProfileView/ProfileView";
import AddAssistantForm from './../AdminComponents/AddAssistantForm/AddAssistantForm';
import Button from './../Button/Button';
import Toast from "../Toaster/Toast";

const Table = ({forceReload, reload, filterChange, currentTabIndex, tabs}) => {

    const [showModal, setShowModal] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [rowDetail, setRowDetail] = useState();
    const [tableView, setTableView] = useState();
    const [showProfileView, setShowProfileView] = useState(false);
    const [showAddAssistantForm, setShowAddAssistantForm] = useState(false);
    const [showNoDataDiv, setShowNoDataDiv] = useState(false);

    const navigate = useNavigate();

    const closeModal = () => {
        setShowModal(false);
        setShowEditForm(false);
    }

    function renderTableHeader() {
        return tabs[currentTabIndex].header.map((h, index) => {
           return <th key={index} field={h.field}>{h.name}</th>
        });
    }

    function renderEditForm() {
        setShowEditForm(true);
        setShowProfileView(false);
        setShowAddAssistantForm(false);
    }

    function showDetails(e) {
        let id = e.currentTarget.id;
        if(tabs[currentTabIndex].resource === 'clinics') {
            tabs[currentTabIndex].data.map((item) => {
                if(id == item.id) {
                    navigate('/clinic/'+id, {state: item})
                }
            })
        } else{
            tabs[currentTabIndex].data.map((item) => {
                if(id == item.id) {
                   setRowDetail(item);
                   setShowModal(true);
                }
            });
            setShowEditForm(false);
            setShowProfileView(true);
            setShowAddAssistantForm(false);
        }
    }

    function addAssistant() {

        //the following function is set in AddAssistantForm component
        rowDetail.registerAssistant().then((resp) => {
            if(resp) {
                Toast.success("✔ Staff added succesfully");
                setShowEditForm(false);
                setShowProfileView(true);
                setShowAddAssistantForm(false);
            }

        });
    }

    function userSave() {
        
        //the following function is set in PatientEditform component
        rowDetail.updateUser().then((resp) => {
            if(resp) {
                forceReload("✔ Profile updated successfully.");
                closeModal();
            }
            else {
                Toast.error("ERROR: Profile could not be updated!!");
            }
        })
    }

    function renderAddAssistantForm() {
        setShowEditForm(false);
        setShowProfileView(false);
        setShowAddAssistantForm(true);
    }
    
    function renderTableData(tab) {
        let result;
        if(tab.data.length > 0) {
            result = tab.data.map((val, index) => {
                return(
                    <tr id={val.id} key={val.id} className="data-row" onClick={showDetails}>
                        {
                            tab.header.map((h, idx) => {
                                if(h.field in val) {
                                    if(h.url) {
                                        return <td key={idx}><Link target="_blank" to={val.url}>{val[h.field]}</Link></td>
                                    }
                                    else {
                                        return <td key={idx}>{val[h.field]}</td>
                                    }
                                }
                                else {
                                    return <td key={idx}></td>
                                }
                            })
                        }
                    </tr>
                );
            })
            setShowNoDataDiv(false);
        }
        else {
            setShowNoDataDiv(true);
        }
        return result;
    }

    function DisplayEditForm() {
        if(tabs[currentTabIndex].resource === Resource.ROLES.DOCTOR) {
            return <DoctorEditForm rowDetail={rowDetail} />
        }
        else if(tabs[currentTabIndex].resource === Resource.ROLES.CARE_MANAGER) {
            return <CaremanagerEditForm rowDetail={rowDetail} />
        }  
        else {
            return <PatientEditForm rowDetail={rowDetail} />
        }  
    }


    useEffect(() => {
        if(currentTabIndex >= 0) {
            setTableView(renderTableData(tabs[currentTabIndex]));
        }
        return (() => {
            
        });

    }, [currentTabIndex, filterChange, reload]);

    return ( 
        <div className="table-container">
            <table id='table_data'>
                <tbody>
                    { currentTabIndex >= 0 ?
                    <>
                    <tr className="header">{renderTableHeader()}</tr>
                    {tableView}    
                    </> : <></>
                    }   
                </tbody>
            </table>
            {
                showNoDataDiv && 
                <div className="noDataDiv">
                   <h1 className="noDataContent">No {tabs[currentTabIndex].label} added</h1>
                </div> 
            }
            {
                showModal ?
                    <Modal  closeModal={closeModal} right={true}>
                        <div className="table_profileContainer">
                            <div className='table_profileHeader'>
                                <Link to="/home"><img src={closeIcon} onClick={closeModal} alt="img" className='close-btn'  /></Link>
                                {
                                    showEditForm && <Button text={'Save'} id='update-user' buttonType={'primary_btn'} onclick={() => userSave()} />
                                }
                                {
                                    /*showProfileView && (tabs[currentTabIndex].resource !== Resource.ROLES.DOCTOR) && <Button text={'Edit'} buttonType={'primary_btn'} onclick={renderEditForm} />*/
                                    showProfileView && <Button text={'Edit'} buttonType={'primary_btn'} onclick={renderEditForm} />
                                }
                                {
                                    showAddAssistantForm && <Button text={'Save'} id='save-register' buttonType={'primary_btn'} onclick={() => addAssistant()} />
                                }
                            </div>
                            {  
                                showEditForm && <DisplayEditForm /> 
                            }
                            {
                                showProfileView && <ProfileView currentTab={tabs[currentTabIndex]} rowData={rowDetail} renderAddAssistant={renderAddAssistantForm} />
                            }
                            {
                                showAddAssistantForm && <AddAssistantForm rowData={rowDetail}/>
                            }
                        </div>
                    </Modal>
                :
                ''
            }  
        </div>
    );
}   
export default Table;

import React,{useState} from 'react';
import { useEffect, useRef } from 'react';
import Resource from '../../../services/resource.service';
import { useAuth } from '../../../services/use-auth';
import Button from '../../Button/Button';
import Toast from '../../Toaster/Toast';
import './EditPatientForm.scss';

const EditPatientForm = ({callback, closeModal, patientsData}) => {

    const auth = useAuth();
    const [selectedGender, setSelectedGender] = useState(patientsData.patient_gender);
    const [doctors, setDoctors] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState(patientsData.consultation_payment_method);
    const [selectedDoctorName, setSelectedDoctorName] = useState();
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const onDoctorChange = (e) => {
        var index = e.target.value;
        setSelectedDoctorName(index);
    }

    const handleGenderRadioButton = (e) => {
        let currentValue = e.target.value;
        setSelectedGender(currentValue);
    }

    const setDimension = () => {
        setScreenSize(window.innerWidth);
    }

    useEffect(() => {
        console.log(patientsData);
        let clinicId = patientsData.clinic_id;
        Resource.getUsers(Resource.ROLES.DOCTOR, auth.user.id, {clinic_id: clinicId}).then((response) => {
            if(response.success)
                setDoctors(response.data);
        });

    }, [auth.user]);

    useEffect(() => {
        if(patientsData.doctor_id) {
            let elem = document.getElementById('p-doctorName');
            elem.value = patientsData.doctor_id;
        }
    }, [doctors]);

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    const submitPatient = (e) => {
        e.preventDefault();
        let Error = "This field cannot be empty";
        // let emailvalueError = "Please provide a valid email-id";
        let namevalueError = "Please provide a name";
        let phonevalueError = "Please provide a 10 digit valid phone number";
        let agevalueError = "Please provide a age between 18 and 99";
        let phoneformat = /^([6-9])(\d{9})$/;


        let patientname = document.getElementById('p-name');
        let phoneNumber = document.getElementById('p-phone');
        phoneNumber.value = phoneNumber.value.replace('+91', '');
        let doctorName = document.getElementById('p-doctorName');
        let age = document.getElementById('p-age');
        let feeCollected = document.getElementById('fee-collected');
        let genderRadioGroup = document.getElementById('genderRadioGroup');
        let paymentMethod = document.getElementById('p-paymentMethod');

        let nameerror = document.getElementById('patientnameerror');
        let phoneerror = document.getElementById('phoneerror');
        let doctorNameerror = document.getElementById('doctorNameerror');
        let ageerror = document.getElementById('ageerror');
        let gendererror = document.getElementById('gendererror');
        //let feeserror = document.getElementById('feeserror');
        let paymentMethoderror = document.getElementById('paymentMethoderror');

        if (patientname.value || phoneNumber.value || age.value || doctorName.value || paymentMethod.value || selectedGender !== '' ) {
            if (patientname.value.length > 0) {
                patientname.classList.remove('invalid');
                nameerror.style.display = "none";
                if (phoneNumber.value !== '' && phoneNumber.value.match(phoneformat)) {
                    phoneNumber.classList.remove('invalid');
                    phoneerror.style.display = "none";
                    if (age.value >= 9 ) {
                        ageerror.style.display = "none";
                        age.classList.remove("invalid");
                        if(selectedGender !== ''){
                            gendererror.style.display = 'none';
                            if (doctorName.value !== '') {
                                doctorNameerror.style.display = "none";
                                doctorName.classList.remove("invalid");
                                if (paymentMethod.value !== '') {
                                    paymentMethoderror.style.display = "none";
                                    paymentMethod.classList.remove("invalid");
                                
                                    let obj = {
                                        name:'',
                                        phone_number:'',
                                        age:'',
                                        sex:'',
                                        doctor: '',
                                        fees_collected:'',
                                        payment_method:'',
                                        clinic_id: patientsData.clinic_id,
                                        clinic_qms_id: patientsData.clinic_qms_id
                                    };
                                    obj.name = patientname.value;
                                    obj.phone_number = '+91' + phoneNumber.value.replace(/\D/g, "");
                                    obj.age = age.value;
                                    obj.doctor = selectedDoctorName;
                                    obj.sex = selectedGender;
                                    obj.fees_collected = feeCollected.value;
                                    obj.payment_method = paymentMethod.value;
                                        
                                    Resource.updateQueueRegister(obj).then((response) => {
                                        if(response.success) {
                                            Toast.success("Patient QMS (ID: " + response.lead_id + ") added successfully!!");
                                        }
                                        else if(response.warning) {
                                            Toast.warning(response.warning);
                                        }
                                        else {
                                            Toast.error("ERROR: Could not add patient. Try after sometime!!");
                                        }
                                        if(callback)
                                            callback(response)
                                    });
                                }
                                else {
                                    paymentMethod.classList.add('invalid');
                                    paymentMethoderror.style.display = "inline";
                                    paymentMethoderror.innerHTML = Error;
                                }     
                            }
                            else {
                                doctorName.classList.add('invalid');
                                doctorNameerror.style.display = "inline";
                                doctorNameerror.innerHTML = Error;
                            }
                        }
                        else{
                            gendererror.style.display = "inline";
                            gendererror.innerHTML = Error;
                        }
                    }
                    else {
                        ageerror.style.display = "inline";
                        age.classList.add("invalid");
                        ageerror.innerHTML = agevalueError;
                    }
                }
                else {
                    phoneerror.style.display = "inline";
                    phoneNumber.classList.add("invalid");
                    phoneerror.innerHTML = phonevalueError;
                }
            }
            else{
                nameerror.style.display = "inline";
                patientname.classList.add("invalid");
                nameerror.innerHTML = namevalueError;
            } 
        }
        else {
            nameerror.style.display = "inline";
            patientname.classList.add("invalid");
            nameerror.innerHTML = namevalueError;

            phoneNumber.classList.add('invalid');
            phoneerror.style.display = "inline";
            phoneerror.innerHTML = Error;

            ageerror.style.display = "inline";
            age.classList.add("invalid");
            ageerror.innerHTML = agevalueError;

            gendererror.style.display = "inline";
            gendererror.innerHTML = Error;

            doctorName.classList.add('invalid');
            doctorNameerror.style.display = "inline";
            doctorNameerror.innerHTML = Error;
            
            paymentMethod.classList.add('invalid');
            paymentMethoderror.style.display = "inline";
            paymentMethoderror.innerHTML = Error;
        }    
    }
    
    return (
        <div className="editPatientContainer">
                <div className='editPatientheader'>
                    <h1 className="title">Patient Details</h1>
                    <svg onClick={closeModal} className='close-btn' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M6 6L18 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                
                <form autoComplete="disabled"  className="registerForm">
        
                    <div className='form-element'>
                        <label>Patient Name</label>
                        <input type="text" id="p-name" className="inputField" placeholder="Enter patient's name" defaultValue={patientsData.patient_name} required />
                        <span className="field-error" id="patientnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>10-digit Phone Number</label>
                        <input autoComplete="disabled"  type="tel" id="p-phone" className="inputField" placeholder="Enter phone number" defaultValue={patientsData.patient_phone} required />
                        <span className="field-error" id="phoneerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Age</label>
                        <input type="number" id="p-age" className="inputField" placeholder="Enter patient's age" defaultValue={patientsData.patient_age} required />
                        <span className="field-error" id="ageerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Gender</label>
                            <div className='radioButtonGroup'>
                                <label>
                                    <input type="radio" id="male" defaultChecked={selectedGender} name="gender" value="M" onChange={(e)=>handleGenderRadioButton(e)} checked={selectedGender === "M"} />Male
                                </label>
                            </div>
                            <div className='radioButtonGroup'>
                                <label>
                                    <input type="radio" id="female" defaultChecked={selectedGender} name="gender" value="F" onChange={(e)=>handleGenderRadioButton(e)} checked={selectedGender === "F"} />Female
                                </label>
                            </div>
                        <span className="field-error" id="gendererror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Doctor</label>
                        <select id="p-doctorName" onChange={onDoctorChange} className="inputField" placeholder='Select Doctor' required>
                            <option value=''>-- Select Doctor --</option>
                            {
                                doctors && doctors.map((d, idx) => {
                                    return <option key={idx} value={d.doctor_id}>{'Dr. ' + d.first_name + ' ' + d.last_name}</option>
                                })
                            
                            }
                        </select>
                        <span className="field-error" id="doctorNameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Fee Collected</label>
                        <input type="text" id="fee-collected" className="inputField" defaultValue={patientsData.consultation_fee} />
                        <span className="field-error" id="feeserror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Payment Method</label>
                        <select id="p-paymentMethod" className="inputField" placeholder='Select Payment Method' value={selectedPayment} onChange={(e)=>setSelectedPayment(e.target.value)} defaultValue={selectedPayment}  required>
                            <option value="">-- Select Payment Method --</option>
                            <option value="cash">Cash</option>
                            <option value="upi">UPI</option>
                            <option value="credit/debit card">Credit/Debit Card</option>
                        </select>    
                        <span className="field-error" id="paymentMethoderror"></span>
                    </div>
                    
                    <div  className={screenSize <= 500 ? "bottomElement" : "patientEditBtn" }>
                        <Button text={"Submit"} buttonType={"primary_btn"} onclick={(e)=>submitPatient(e)}/>
                    </div>
                    
                </form>
        </div>
    );
}

export default EditPatientForm;
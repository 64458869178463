const useHandleEnter = () => {
    const handleKeyDown = (e, handleClick) => {
        if (e.keyCode === 13) {
            handleClick();
        }
    };
    return {
        handleKeyDown
    }
};

export default useHandleEnter;
import React, { useState, useEffect } from "react";
import "./InformationContainer.scss";
import InformationSidebar from "./InformationSidebar/InformationSidebar";
import InformationSidebarContent from "./InformationSidebarContent/InformationSidebarContent";

const InformationContainer = () => {
  const [activePage, setActivePage] = useState("Documents");
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  let navLabels = [
    "Documents", "Diet & Lifestyle Plans", "Journal", "Tasks", "Measurements & Vitals", "Medical History", "Diet & Lifestyle Habits" 
    ]

  function getElements(value) {
    setActivePage(value);
  }

  function showSelected(e) {
    setActivePage(e.target.value);
}

  const setDimension = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <div className="information_container">
        {screenSize > 550 ?
          <>
           <InformationSidebar
                getSidebarData={getElements}
                activeitem={activePage}
            />
            <InformationSidebarContent activeLinkContent={activePage} />
          </>: 
          <div className='information_container_mobile'>
            <div className='form-element'>
                    <select className="inputField" value={activePage} onChange={showSelected} required>
                        {
                            navLabels.map((navLabel) => {
                                return <option className={`nav-item ${activePage === navLabel ? "active-item" : ""}` } value={navLabel} onClick={showSelected} >{navLabel}</option>
                            })
                        }
                    </select>
            </div>
            <div className="information_content_mobile">
                <InformationSidebarContent activeLinkContent={activePage}/>
            </div>
           </div>
        }
    </div>
  );
};

export default InformationContainer;

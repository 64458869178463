import React, { useEffect, useState, useRef } from "react";
import { getDateRange } from "../../helpers/utils";
import Resource from "../../services/resource.service";
import './Filter.scss';

const Filter = ({callbackFilter, currentTabIndex, tabs})=>{

    let currentTab = tabs[currentTabIndex];
    const [show, setShow] = useState(false);
    const [filterItem, selectFilterItem] = useState({});
    const [filters, setFilters] = useState({});

    const [filterChange, toggleFilterChange] = useState(false);
    
    const filterList = useRef({});
    const filterRef = useRef();

    useEffect(() => {
        //var filterTypeIds = ['filterPractice', 'filterConcern'];
        let filterId = document.getElementById('filterPractice');
        if(filterId && !filterList.current.filterPractice) {
            Resource.getPractices().then((resp) => {
                filterList.current.filterPractice = resp.data;
            });
        }


        filterId = document.getElementById('filterConcern');
        if(filterId && !filterList.current.filterConcern) {
            /**
             * getDiagnosis Api needs to be built
             */
            filterList.current.filterConcern = [
                {'id': 'ibs','name': 'IBS'},
                {'id': 'type_2_diabetes', 'name': 'Type II Diabetes'},
                {'id': 'gi', 'name': 'GI'},
                {'id': 'pcos_pcod', 'name': 'PCOS / PCOD'},
                {'id': 'menopause', 'name': 'Menopause'},
                {'id': 'infertility', 'name': 'Infertility'}
            ];
        }

        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        });

    }, [show]);

    useEffect(() => {
        console.log(filterChange, 'filterch');
        callbackFilter(filterChange);
    }, [filterChange]);

    
    useEffect(() => {
        setFilters({});
        if(currentTabIndex >= 0)
            tabs[currentTabIndex].filter = {};

    }, [currentTabIndex]);


    function openFliter() {
        setShow(!show);
    }

    function filterItemClick(e) {
        let f = {};
        f[e.target.id] = true;
        selectFilterItem(f);

    }

    function selectSubItem(e) {
        var value = e.target.getAttribute('value');
        var option = e.target.className;

        if(option == 'date-option') {
            var dateRange = getDateRange(value);
            currentTab.filter.start_date = dateRange.start_date;
            currentTab.filter.end_date = dateRange.end_date;
            setFilters(prev => ({...prev, 'date':value}));
        }
        else if(option == 'practice-option') {
            setFilters(prev => ({...prev, 'practice':value}));
            currentTab.filter.practice = value;
        }
        else if(option == 'concern-option') {
            setFilters(prev => ({...prev, 'concern':value}));
            currentTab.filter.diagnosis_id = value;
        }
        else if(option == 'gender-option') {
            setFilters(prev => ({...prev, 'gender':value}));
            currentTab.filter.sex = value;
        }

        setShow(false);
        selectFilterItem({});
        toggleFilterChange(!filterChange);
    }

    function removeSelectedFilter(e) {
        let filterKey = e.target.getAttribute('data-id');
        let m = {};
        Object.keys(filters).map((k) => {
            if(k != filterKey)
                m[k] = filters[k];
        });

        switch(filterKey) {
            case 'date':
                delete currentTab.filter['start_date'];
                delete currentTab.filter['end_date'];
            case 'practice':
                delete currentTab.filter['practice'];
            case 'concern':
                delete currentTab.filter['diagnosis_id'];
            case 'gender':
                delete currentTab.filter['sex'];
        }

        setFilters(m);
        toggleFilterChange(!filterChange);
    }

    function RenderFilter() {
        if(currentTab) {
            switch(currentTab.resource) {
                case Resource.ROLES.DOCTOR:
                    return (
                        <ul className="list-items">
                            <li id='filterDate' className="listItem1" onClick={filterItemClick} >By Date</li>
                                {
                                    filterItem.filterDate ? //use the id value of li filter above
                                    <div className="sub-select">
                                        <ul className="sub-list" onClick={selectSubItem}>
                                            <li className='date-option' value='today'>Today</li>
                                            <li className='date-option' value='yesterday'>Yesterday</li>
                                            <li className='date-option' value='this_week'>This week</li>
                                            <li className='date-option' value='this_month'>This month</li>
                                            <li className='date-option' value='last_month'>Last month</li>
                                            <li className='date-option' value='last_3_months'>Last 3 months</li>
                                            <li className='date-option' value='last_6_months'>Last 6 months</li>
                                            <li className='date-option' value='custom_date'>Custom date</li>
                                        </ul>
                                    </div>
                                    :
                                    ''
                                }
                                <li id='filterPractice' className="listItem2" onClick={filterItemClick} >By Practice</li>
                                {
                                    filterItem.filterPractice ?
                                    <div className="sub-select item2-subselect">
                                        <ul className="sub-list" onClick={selectSubItem}>
                                            {
                                                filterList.current.filterPractice.map((p) => {
                                                    return <li key={p.id} className='practice-option' value={p.id}>{p.name}</li>
                                                })
                                            }
                                            {/*<li className='practice-option' value='OB/GYN'>OB/GYN</li>
                                            <li className='practice-option' value='Gastroenterologist'>Gastroenterologist</li>
                                            <li className='practice-option' value='Cardiologist'>Cardiologist</li>
                                            <li className='practice-option' value='Endocrinologist'>Endocrinologist</li>
                                            <li className='practice-option' value='General Physician'>General Physician</li>
                                            */}
                                        </ul>
                                    </div>
                                    :
                                    ''
                                }
                        </ul>
                    );
                case Resource.ROLES.CARE_MANAGER:
                    return (
                        <ul className="list-items">
                            <li id='filterDate' className="listItem1" onClick={filterItemClick} >By Date</li>
                                {
                                    filterItem.filterDate  ?
                                    <div className="sub-select">
                                        <ul className="sub-list" onClick={selectSubItem}>
                                            <li className='date-option' value='today'>Today</li>
                                            <li className='date-option' value='yesterday'>Yesterday</li>
                                            <li className='date-option' value='this_week'>This week</li>
                                            <li className='date-option' value='this_month'>This month</li>
                                            <li className='date-option' value='last_month'>Last month</li>
                                            <li className='date-option' value='last_3_months'>Last 3 months</li>
                                            <li className='date-option' value='last_6_months'>Last 6 months</li>
                                            <li className='date-option' value='custom_date'>Custom date</li>
                                        </ul>
                                    </div>
                                    :
                                    ''
                                }
                        </ul>
                    );
                case Resource.ROLES.PATIENT:
                    return (
                        <ul className="list-items">
                            <li id='filterDate' className="listItem1" onClick={filterItemClick} >By Date</li>
                                {
                                    filterItem.filterDate ?
                                    <div className="sub-select">
                                        <ul className="sub-list" onClick={selectSubItem}>
                                            <li className='date-option' value='today'>Today</li>
                                            <li className='date-option' value='yesterday'>Yesterday</li>
                                            <li className='date-option' value='this_week'>This week</li>
                                            <li className='date-option' value='this_month'>This month</li>
                                            <li className='date-option' value='last_month'>Last month</li>
                                            <li className='date-option' value='last_3_months'>Last 3 months</li>
                                            <li className='date-option' value='last_6_months'>Last 6 months</li>
                                            <li className='date-option' value='custom_date'>Custom date</li>
                                        </ul>
                                    </div>
                                    :
                                    ''
                                }
                                <li id='filterConcern' className="listItem2" onClick={filterItemClick} >By Concern</li>
                                {
                                    filterItem.filterConcern ?
                                    <div className="sub-select item2-subselect">
                                        <ul className="sub-list" onClick={selectSubItem}>
                                                {
                                                filterList.current.filterConcern.map((c) => {
                                                    return <li key={c.id} className='concern-option' value={c.id}>{c.name}</li>
                                                })
                                                }
                                            {/*<li className='concern-option' value='gi'>GI</li>
                                            <li className='concern-option' value='ibs'>IBS</li>
                                            <li className='concern-option' value='pcos_pcod'>PCOS / PCOD</li>
                                            <li className='concern-option' value='type_2_diabetes'>Diabetes</li>
                                            <li className='concern-option' value='obesity'>Obesity / Weight loss</li>
                                            <li className='concern-option' value='menopause'>Menopause</li>
                                            <li className='concern-option' value='infertility'>Infertility</li>*/}
                                        </ul>
                                    </div>
                                    :
                                    ''
                                }
                                <li id='filterGender' className="listItem3" onClick={filterItemClick} >By Gender</li>
                                {
                                    filterItem.filterGender ?
                                    <div className="sub-select item3-subselect">
                                        <ul className="sub-list" onClick={selectSubItem}>
                                            <li className='gender-option' value='M'>Male</li>
                                            <li className='gender-option' value='F'>Female</li>
                                            <li className='gender-option' value=''>Other</li>
                                        </ul>
                                    </div>
                                    :
                                    ''
                                }
                        </ul>
                    );
                case 'qms':
                    return (
                        <ul className="list-items">
                            <li id='filterDate' className="listItem1" onClick={filterItemClick} >By Date</li>
                                {
                                    filterItem.filterDate ?
                                    <div className="sub-select">
                                        <ul className="sub-list" onClick={selectSubItem}>
                                            <li className='date-option' value='today'>Today</li>
                                            <li className='date-option' value='yesterday'>Yesterday</li>
                                            <li className='date-option' value='this_week'>This week</li>
                                            <li className='date-option' value='this_month'>This month</li>
                                            <li className='date-option' value='last_month'>Last month</li>
                                            <li className='date-option' value='last_3_months'>Last 3 months</li>
                                            <li className='date-option' value='last_6_months'>Last 6 months</li>
                                            <li className='date-option' value='custom_date'>Custom date</li>
                                        </ul>
                                    </div>
                                    :
                                    ''
                                }
                        </ul>
                    );
            }
        }
        else {
            return <></>;
        }

    }

    function handleClickOutside(e) {
        if (filterRef.current && !filterRef.current.contains(e.target)) { 
            selectFilterItem({});
            setShow(false);
        }
    }

    return (
        <>
            <div className="filter-container">
                <button type="button" className="filter" onClick={openFliter}> 
                <svg className="filter-image" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.35419 3.95825H14.6459C14.7599 3.99824 14.8632 4.06379 14.948 4.14989C15.0328 4.23598 15.0967 4.34034 15.1349 4.45496C15.1731 4.56958 15.1846 4.69143 15.1684 4.81117C15.1523 4.9309 15.1089 5.04535 15.0417 5.14575L11.0834 9.49992V15.0416L7.91669 12.6666V9.49992L3.95836 5.14575C3.89114 5.04535 3.84779 4.9309 3.83163 4.81117C3.81548 4.69143 3.82694 4.56958 3.86515 4.45496C3.90336 4.34034 3.96729 4.23598 4.05206 4.14989C4.13683 4.06379 4.24018 3.99824 4.35419 3.95825Z" stroke="#5F6368" strokeWidth="1.3" strokeLinecap="round" stroke-linejoin="round"/>
                </svg>
                    Filter
                </button> 
                {
                    show ? 
                    <div className="select-content" ref = {filterRef}>
                        <RenderFilter />
                    </div> 
                    : 
                    ''
                }
            </div>
            {
                <div className="filter-tags">
                    {
                        Object.keys(filters).map((k, i) => {
                            if(k && filters[k])
                                return <div className='filter-selected' key={k}>
                                        {filters[k]}
                                        <svg  data-id={k} onClick={removeSelectedFilter}  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 6L6 18" stroke="#070708" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                <path d="M6 6L18 18" stroke="#070708" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                        })
                    }
                </div> 
                }
        </>
    );
}

export default Filter;
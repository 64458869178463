import React,{ useState } from 'react';
import { useEffect } from 'react';
import Resource from '../../../../services/resource.service';

const DoctorMyAccountEditForm = ({currentUser}) => {

    let clinicData = currentUser.clinics;
    const [practices, setPractices] = useState([]);
    useEffect(() => {
        currentUser.editFormData = {
            'first_name': currentUser.first_name,
            'last_name': currentUser.last_name,
            'email': currentUser.email,
            'metainfo': {
                'practice_id': currentUser.metainfo?.practice_id,
                'medical_council': currentUser.metainfo?.medical_council,
                'medical_registration_number': currentUser.metainfo?.medical_registration_number
            }
        };

        Resource.getPractices().then((response) => {
            if(response.success) {
                setPractices(response.data);
            }
        });

        return () => {
            delete currentUser.editFormData;
        }
    }, []);

    return (
       <>
            <div className='EditmyAccount_form registerForm'>
                <div className='form-element'>
                    <label>First Name</label>
                    <input type="text" defaultValue={currentUser.first_name} onChange={(e) => {currentUser.editFormData.first_name = e.target.value}}/>
                </div>
                <div className='form-element'>
                    <label>Last Name</label>
                    <input type="text" defaultValue={currentUser.last_name} onChange={(e) => {currentUser.editFormData.last_name = e.target.value}}/>
                </div>
                <div className='form-element'>
                    <label>Email</label>
                    <input type="text" defaultValue={currentUser.email} onChange={(e) => {currentUser.editFormData.email = e.target.value}}/>
                </div>
                <div className='form-element'>
                    <label>Clinic Name</label>
                    {
                    clinicData.map((clinic)=>{
                        return  <input idx={clinic.id} key={clinic.id} type="text" defaultValue={clinic.name} />
                    })
                }
                </div>
                <div className='form-element'>
                    <label>Practicing As</label>
                    <select id="practice" className="inputField" defaultValue={currentUser.metainfo?.practice_id} onChange={(e) => {currentUser.editFormData.metainfo.practice_id = e.target.value}} required>
                        
                        <option value="">-- Select Practice --</option>
                        {
                            practices.map((practice, idx) => {
                                return <option key={idx} selected={currentUser.metainfo?.practice_id == practice.id} value={practice.id}>{practice.name}</option>
                            })
                        }
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className='form-element'>
                    <label>Medical Council</label>
                    <select id="medicalCouncil" className="inputField" defaultValue={currentUser.metainfo?.medical_council} onChange={(e) => {currentUser.editFormData.metainfo.medical_council = e.target.value}} required>
                        <option value="NMC - National Medical Commission">NMC - National Medical Commission</option>
                        <option value="IMR - Indian Medical Register">IMR - Indian Medical Register</option>
                        <option value="State Medical Council">State Medical Council</option>
                        <option value="FOGSI">FOGSI</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className='form-element'>
                    <label>Medical Registration Number</label>
                    <input type="text" defaultValue={currentUser.metainfo?.medical_registration_number} onChange={(e) => {currentUser.editFormData.metainfo.medical_registration_number = e.target.value}} />
                </div>
            </div>
        </>
    )
}

export default DoctorMyAccountEditForm;
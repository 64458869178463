import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import { ProvideAuth } from './services/use-auth';

ReactDOM.render(
  <ProvideAuth>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ProvideAuth>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
serviceWorkerRegistration.pwaTrackingListeners();
//serviceWorkerRegistration.requestNotification();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();

import React,{useState,useEffect} from 'react';
import './UpcomingAppointment.scss';
import Button from '../Button/Button';

const UpcomingAppointment = () =>{
    const [seeMore,setSeeMore] = useState(true);
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const setAppointment = ()=>{

    }
    const cancelSchedule = ()=>{

    }
    const showMore = () => {
        setSeeMore(false)
    }
    
    const setDimension = () => {
        setScreenSize(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    return (
        <>
            <div className='upcoming_appointments_container'>
                <div className='appointment_header'>
                    <h4 className='appointments_heading'>Appointments</h4>
                    <Button text={'Set Appointment'} buttonType={'secondary_btn'} onclick={setAppointment}/>
                </div>
                <div className='appointments_container'>
                    <div className='appointment'>
                        <div className='appointment_row'>
                            <div className='appointment_date_day_time'>
                                <div className='appointment_date'>
                                    02.9.2022
                                </div>
                                <div className='appointment_day'>
                                    Wednesday
                                </div>
                                <div className='appointment_time'>
                                    7:00 AM - 8:00 AM
                                </div>
                            </div>
                            <Button text={'Cancel'} buttonType={'tertiary_btn'} onclick={cancelSchedule}/>
                        </div>  
                    </div>
                    {/* {
                        seeMore ?  <button className='seeMore_btn' onClick={showMore}> See More </button> : ''
                    }
                    */}
                </div>
               
            </div>
        </>
    )
}

export default UpcomingAppointment;
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from './../Header/Header';
import './ProgramForm.scss';
import Resource from '../../services/resource.service';
import Button from './../../components/Button/Button';

const ProgramForm = ({data}) => {
    const [showSaveChangeBtn,setShowSaveChangeBtn] = useState(false);

    let path = window.location.pathname;

    const saveChanges = (e) => {

        e.preventDefault();
        console.log('clicked')

        const body = {
            program_id: data.id,
            title: document.getElementById("program-name").value ,
            description: document.getElementById("program-description").value ,
            duration: parseInt(document.getElementById("program-duration").value, 10)
        }
        
        console.log(body);
        Resource.updateProgram(body);
    }

    useEffect(()=>{
        if (path.includes('/editProgram')) {
            setShowSaveChangeBtn(true);
        }
    }, []);

    return (
        <>
            <div className='programForm'>
                <form className='addProgramForm'>
                    <div className='form-element'>
                        <label>Program Name</label>
                        <input type="text" id="program-name" className="inputField" defaultValue={data && data.title} required />
                    </div>
                    <div className='form-element'>
                        <label>Program Description</label>
                        <textarea id="program-description" name="program-description" className="inputField" rows="4" cols="50" required >{data && data.description}</textarea>
                    </div>
                    <div className='form-element'>
                        <label>Program Duration</label>
                        <div className="duration-fields">
                            <input type="number" id="program-duration" className="inputField" defaultValue={data && data.duration}  required />
                            <select id="select-duration" className="inputField" placeholder='Duration type' required>
                                <option value="">Duration type</option>
                                <option value="days">Days</option>
                                <option value="weeks" selected>Weeks</option>
                                <option value="months">Months</option>
                            </select>
                        </div>
                    </div>
                    <div className='editProgramSave-button'>
                        {
                            showSaveChangeBtn &&
                            <Button text={'Save Changes'} onclick={saveChanges} buttonType={'primary_btn'} />      
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default ProgramForm;
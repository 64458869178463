import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './PatientDetails.scss';
import { useParams } from "react-router-dom";
import Resource from "../../../services/resource.service";
import Header from '../../Header/Header';
import AssistantPatientProfile from "../AssistantPatientProfile/AssistantPatientProfile";
import UpcomingAppointment from "../../UpcomingAppointment/UpcomingAppointment";
import { formatPatientData } from "../../../helpers/utils";
import { useAuth } from "../../../services/use-auth";

const PatientDetails = () => {

    let linkToUrl = '/';
    let location = useLocation();
    const navigate = useNavigate();
    const patientsData = location.state;
    const params = useParams();
    const [patientInfo, setPatientInfo] = useState();
    const auth = useAuth();

    useEffect(() => {
        if(!patientsData) {
            if(window.location.href.indexOf('/lead/') >= 0) {
                var lead_id = params.id;
                if(lead_id) {
                    Resource.getUserById('lead', lead_id).then((response) => {
                        if(response.success)
                            setPatientInfo(formatPatientData(response.data));
                        else
                            navigate('/home');
                    });
                }
            }
            else if(window.location.href.indexOf('/patient/') >= 0) {
                var patient_id = params.id;
                if(patient_id) {
                    Resource.getUserById('patient', patient_id).then((response) => {
                        if(response.success)
                            setPatientInfo(formatPatientData(response.data));
                        else
                            navigate('/home');
                    });
                }
            }
        }
        else {
            setPatientInfo(patientsData);
        }

    }, [auth.user]);

    return (
        patientInfo ?
        <>
            <div className="patient_details_component">
                <div className="patient_details_container">
                    <Header linkToUrl={linkToUrl} />
                    <div className="patient_details">
                        <div className="assistant_patient_profile">
                            <AssistantPatientProfile patientInfo={patientInfo}/>
                        </div>
                        <div className="assistant_patient_appointments">
                            <UpcomingAppointment />
                        </div>
                    </div>
                </div>
            </div>
        </> : ''
    )
}

export default PatientDetails;
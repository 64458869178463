import React from "react";
import { useState, useEffect } from 'react';
import PatientTabRow from "./../PatientTabRow/PatientTabRow";
import Search from "./../SearchComponent/Search/Search";
import edit from './../../../../images/edit.png';
import './PatientHomeTab.scss';

const PatientHomeTab = ({ patientQmsData, sendDate }) => {
    
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const setDimension = () => {
        setScreenSize(window.innerWidth);
    }

    // const changeDate = (e) => {
    //     let date = document.getElementsByClassName("date_picker");
    //     if(e.target.value === '') {
    //         setDateField(false);
    //     }
    //     else {
    //         setDateField(true);
    //         date.value = e.target.value;
    //         sendDate(date.value);
    //     }
    // }

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    return (
        <div className="patientHome">
            <div className='patient_row_component'>
                <Search patientsData={patientQmsData} sendDate={sendDate} ></Search>
                
                {/* {
                    patientQmsData.length > 0 ?
                    patientQmsData.map((patientRowData, idx) => {
                    return <PatientTabRow key={idx} patientsData={patientRowData} suggestionLookup={suggestionLookup} callbackLookup={callbackLookup} />
                        })
                        :
                        <div className="noDataDiv">
                        <h1 className="noDataContent">No Patients added</h1>
                    </div>
                } */}
            </div>
        </div>
    )

}

export default PatientHomeTab;
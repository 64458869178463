import React, { useEffect, useRef, useState } from 'react';
import './../../AddAssistantForm/AddAssistantForm.scss';
import Resource from '../../../../services/resource.service';
import Button from '../../../Button/Button';
import Toast from '../../../Toaster/Toast';
import { useAuth } from '../../../../services/use-auth';
import './AddAssistantModal.scss'
import { useLocation } from 'react-router-dom';

const AddAssistantModal = () => {
    
    const [practices, setPractices] = useState([]);
    const auth = useAuth();

    const [selectedAssistant,setSelectedAssistant] = useState(null);
    const [showSuggestionBox , setShowSuggestionBox] = useState(false);
    const [matchedData,setMatchedData] = useState([]);
    const [reload, setReload] = useState(false);
    
    const phoneInputRef = useRef();
    const firstNameInputRef = useRef();
    const suggestionRef = useRef();
    const lastNameInputRef = useRef();

    let location = useLocation();
    const clinicData = location.state;


    const registerAssistant = (e) => {
        e.preventDefault();
        var Error = "This field cannot be empty";
        var namevalueError = "Please provide a name";
        var phonevalueError = "Please provide a 10 digit valid phone number";
        let phoneformat = /^\+(91)([6-9])(\d{9})$|^([6-9])(\d{9})$/;

        var firstname = document.getElementById('assistant-fname');
        var lastname = document.getElementById('assistant-lname');
        var searchByClinic = document.getElementById('search-clinic');
        var phonenumber = document.getElementById('assistant-ph');

        var firstnameerror = document.getElementById('firstnameerror');
        var lastnameerror = document.getElementById('lastnameerror');
        var searchByClinicError = document.getElementById('searchByClinicError');
        var phoneerror = document.getElementById('phoneerror');

        if (firstname.value || lastname.value || searchByClinic.value || phonenumber.value) {
                       
            if (phonenumber.value !== '' && phonenumber.value.match(phoneformat)) {
                phoneerror.classList.remove('invalid');
                phoneerror.style.display = "none";

                if (firstname.value.length > 0) {
                    firstname.classList.remove('invalid');
                    firstnameerror.style.display = "none";

                    // if (searchByClinic.value !== '') {
                    //     searchByClinic.classList.remove('invalid');
                    //     searchByClinicError.style.display = "none";

                    if (lastname.value.length > 0) {
                        lastname.classList.remove('invalid');
                        lastnameerror.style.display = "none";
                        var obj = {
                            first_name: '',
                            last_name: '',
                            clinic_id: '',
                            assistant_phone_number: '',
                            all_doctors: ''
                        };
                        obj.first_name = firstname.value;
                        obj.last_name = lastname.value;
                        obj.assistant_phone_number = '+91' + phonenumber.value.replace(/\D/g, "");
                        obj.clinic_id = clinicData.id;
                        obj.all_doctors = true;
                        // obj.doctor_id = parseInt(searchByClinic.value, 10);
                        
                        console.log(obj);
                        console.log(clinicData.id)

                        Resource.createUser(Resource.ROLES.ASSISTANT, obj).then((resp) => {
                            if(resp.success) {
                                Toast.success("Assistant added successfully");
                            }
                        });
                    }
                    else {
                        lastname.classList.add("invalid");
                        lastnameerror.style.display = "inline";
                        lastnameerror.innerHTML = namevalueError;   

                        return new Promise( () => {return false} );
                    }
                }
                // else {
                //     searchByClinic.classList.add('invalid');
                //     searchByClinicError.style.display = "inline";
                //     searchByClinicError.innerHTML = Error;   

                //     return new Promise( () => {return false} );

                //     phoneerror.classList.add('invalid');
                //         phoneerror.style.display = "inline";
                //         phoneerror.innerHTML = phonevalueError;   

                //         return new Promise( () => {return false} );
                // }
                // }
                else {
                    firstname.classList.add("invalid");
                    firstnameerror.style.display = "inline";
                    firstnameerror.innerHTML = namevalueError;
                   
                return new Promise( () => {return false} );
                }
            }
            else {
                phoneerror.classList.add('invalid');
                phoneerror.style.display = "inline";
                phoneerror.innerHTML = phonevalueError;   

                return new Promise( () => {return false} );
            }
        }
        else {

            phoneerror.classList.add('invalid');
            phoneerror.style.display = "inline";
            phoneerror.innerHTML = Error;  

            firstnameerror.style.display = "inline";
            firstname.classList.add("invalid");
            firstnameerror.innerHTML = namevalueError;

            lastnameerror.style.display = "inline";
            lastname.classList.add("invalid");
            lastnameerror.innerHTML = namevalueError;

            // searchByClinicError.style.display = "inline";
            // searchByClinic.classList.add('invalid');
            // searchByClinicError.innerHTML = Error;

            

            return new Promise( () => {return false} );  
        }
        
    }

    function suggestionLookup(query) {
        let otype = 'assistant'
        let params = {
            q: query,
            otype: otype
        }
        return Resource.autocomplete(params);
    }

    function callbackLookup(item) {
        if(item) {
            console.log(item.type);
            console.log(item.id);
            Resource.getUserById(item.type, item.id).then((response) => {
                if(response.success) {
                    console.log(response.data, "data response");
                    // tabs[activeTabRef].data = [resposne.data];
                    setSelectedAssistant(response.data);
                    // setDoctorId(response.data.id);
                    setReload(!reload);
                }
                else {
                    Toast.error("ERROR: User not found");
                }
            });
        }
    }

    const onChangePhoneInput = (e) => {
        let value = e.target.value;
        if(value.length > 0){
            setShowSuggestionBox(true);
            suggestionLookup(value).then((response) => {
                if(response.success) {
                    setMatchedData(response.data.matches.slice(0, 10));
                }
            })
        } else{
            setShowSuggestionBox(false);
            if(callbackLookup)
                callbackLookup(null);
        }
    }

    function handleClickOutside(e) {
        if (suggestionRef.current && !suggestionRef.current.contains(e.target)) { 
            setShowSuggestionBox(false);
        }
    }

    function getSelectedSearchValue(item) {
        setShowSuggestionBox(false);
        if(callbackLookup) {
            callbackLookup(item);
        }
    }
 
    useEffect(()=>{
        if(selectedAssistant !== null){
            phoneInputRef.current.value = selectedAssistant.contact_number;
            firstNameInputRef.current.value = selectedAssistant.first_name;
            lastNameInputRef.current.value = selectedAssistant.last_name;
        }
        return ()=>{

        }
    },[selectedAssistant]);

    useEffect(() => {
        Resource.getPractices().then((response) => {
            if(response.success)
                setPractices(response.data);
        });
    }, [auth.user]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        });
    },[showSuggestionBox]);

    return (
        <div className='assistant-modal'>
            <div className='container'>
            
                    <form autoComplete="disabled"  className="registerForm addAssistant-form">
                        
                        {/*
                        <div className='form-element'>
                            <select id="search-clinic" className="inputField" placeholder='Select clinic' required>
                                <option value="">-- Select Clinic --</option>
                                {
                                    rowData?.clinics && rowData.clinics.map((c) => {
                                        return <option value={c.doctor_id}>{c.clinic_name}</option>
                                    })
                                }
                            </select>
                            <span className="field-error" id="searchByClinicError"></span>
                        </div> */}


                        <div className='form-element phonenumber_input'>
                            <label>10-digit Phone Number</label>
                            <input autoComplete="disabled"  type="tel" id="assistant-ph" ref={phoneInputRef} className="inputField" placeholder="Enter phone number" onChange={(e)=>onChangePhoneInput(e)}/>
                            <span className="field-error" id="phoneerror"></span>
                            {
                                showSuggestionBox ? 
                                <div className="suggestionBox" ref={suggestionRef}>
                                    {
                                        matchedData.length > 0 && matchedData.map((item)=>{
                                            return  (
                                                <>
                                                    <div className="searchSuggestion" onClick={()=>getSelectedSearchValue(item)}>
                                                        <span className="searchValue">
                                                            {item.name}
                                                        </span>
                                                        <span className="searchType">
                                                            {item.username} 
                                                        </span>
                                                    </div>
                                                </>
                                            )
                                        })    
                                    }
                                </div>
                                :
                                ' '
                            }
                        </div>

                        <div className='form-element'>
                            <label>First Name</label>
                            <input type="text" id="assistant-fname" ref={firstNameInputRef} className="inputField" placeholder="First name" required />
                            <span className="field-error" id="firstnameerror"></span>
                        </div>

                        <div className='form-element'>
                            <label>Last Name</label>
                            <input type="text" id="assistant-lname" ref={lastNameInputRef} className="inputField" placeholder="Last name" required />
                            <span className="field-error" id="lastnameerror"></span>
                        </div>

                        <Button text={'Add Assistant'} buttonType={'primary_btn'} onclick={registerAssistant} ></Button>   
                    </form>
            </div>

        </div>
    );

};

export default AddAssistantModal;
import React,{ useState } from 'react';

const CaremanagerMyAccountEditForm = ({currentUser}) => {

    const [selectOption1, setSelectOption1] = useState({value: currentUser.metainfo.specialization});
    const [selectOption2, setSelectOption2] = useState({value: currentUser.metainfo.medical_council});
    
    function selectedOption(e) {
        setSelectOption1(e.target.value);
        setSelectOption2(e.target.value);
    }

    function handleRadioButton(e) {
        let currentValue = e.target.value;
        console.log(currentValue);
        // if(currentValue === 'yes') {
        //     setShowUploadField(true);
        // } 
        // else {
        //     setShowUploadField(false);
        // }
    }

    return (
       <>
            <div className='EditmyAccount_form registerForm'>
                <div className='form-element'>
                    <label>First Name</label>
                    <input type="text" defaultValue={currentUser.first_name} />
                </div>
                <div className='form-element'>
                    <label>last Name</label>
                    <input type="text" defaultValue={currentUser.last_name} />
                </div>
                <div className='form-element'>
                    <label>10-digit Phone Number</label>
                    <input type="tel" defaultValue={currentUser.contact_number} />
                </div>
                <div className='form-element'>
                    <label>AYUSH Registered ?</label>
                    <div className='radioButtonGroup'>
                        <label for="yes">
                            <input type="radio" id="ayushRegistered" name="yes/no" value="yes" onChange={(e)=>handleRadioButton(e)}/>Yes
                        </label>
                    </div>
                    <div className='radioButtonGroup'>
                        <label for="no">
                            <input type="radio" id="ayushNotRegistered" name="yes/no" value="no" onChange={(e)=>handleRadioButton(e)}/> No
                        </label>
                    </div>
                    <span className="field-error" id="ayushRegisterederror"></span>
                </div>
            </div>
        </>
    )
}

export default CaremanagerMyAccountEditForm;
import React from 'react';
import './DietAndLifestyleHabits.scss';

const DietAndLifestyleHabits = () => {

    return (
        <div className="Diet_and_lifestyle_habits_container">
            <div className='Diet_and_lifestyle_habits'>DietAndLifestyleHabits</div>
        </div>
    )

}

export default DietAndLifestyleHabits;
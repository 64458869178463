import React, {useEffect, useRef, useState} from "react";
import logo from './../../images/md+.png';
import Button from './../Button/Button';
import PrescriptionUpload from './../Upload/PrescriptionUpload';
import { useAuth } from "../../services/use-auth";
import  './SelfCheckin.scss'
import { useLocation, useParams } from "react-router-dom";
import Resource from "../../services/resource.service";

const SelfCheckin = () => {

    const params = useParams();
    const clinic_code = params.code;
    const auth = useAuth();
    console.log(auth,"auth");
    const healthConditionsListArray = ['PCOD','PCOS','Skin and Hair issues','Ulcer','Endometriosis','Fibroids'];
    const checkedConditionArray = [];
    const [doctorsList, setDoctorsList] = useState([]);
    const [selectedDoctorId, setSelectedDoctorId] = useState(-1);
    const currentClinicRef = useRef();
    const [healthConditionsList, setHealthConditionsList] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false); 

    useEffect(() => {
        Resource.getUsers(Resource.ROLES.DOCTOR, null, {'clinic_code': clinic_code}).then((response) => {
            if (response.success) {
                setDoctorsList(response.data);
            }
        });
    }, []);

    useEffect(()=> {
        let practiceId;
        for(let i = 0; i < doctorsList.length; i++) {
            if(doctorsList[i].id == selectedDoctorId) {
                for(let c = 0; c < doctorsList[i].clinics.length; c++) {
                    if(doctorsList[i].clinics[c].clinic_code == clinic_code)
                        currentClinicRef.current = doctorsList[i].clinics[c];
                }
                practiceId = doctorsList[i].metainfo.practice_id;
                break;
            }
        }

        Resource.getDiagnosis({practice_id: practiceId}).then( (response) => {
            if(response.success) {
                setHealthConditionsList(response.data);
            }
        });
    }, [selectedDoctorId])

    const changeChecked = (checkedCondition) => {
        checkedConditionArray.push(checkedCondition);
    }

    const submitSelfCheckinForm = (e) => {
        e.preventDefault();
        
        let phoneformat = /^([6-9])(\d{9})$/;
        var Error = "This field cannot be empty";
        var phoneError = "Please provide a 10 digit valid phone number";
        var doctorNameerror = "Please select a doctor";
        var healthConditionError = "Please check atleast one health condition";
        
        var patientName = document.getElementById('patient-name');
        var phoneNumber = document.getElementById('phone-number');
        let doctorName = document.getElementById('doctor-name');
        let healthConditionCheckbox = document.getElementById('multiCheck');
        // let mediaUpload = document.getElementById('mediaUpload');

        var patientnameerror = document.getElementById('patientnameerror');
        var phoneerror = document.getElementById('phoneerror');
        var doctornameerror = document.getElementById('doctornameerror');
        var healthConditionerror = document.getElementById('healthConditionerror');
        // var fileerror = document.getElementById('fileerror');

        if (patientName.value || phoneNumber.value || checkedConditionArray.length && isDisabled) {
            if (patientName.value.length > 0) {
                patientName.classList.remove('invalid');
                patientnameerror.style.display = "none";

                if (phoneNumber.value !== '' && phoneNumber.value.match(phoneformat)) {
                    phoneNumber.classList.remove('invalid');
                    phoneerror.style.display = "none";
                    
                    if (doctorName.value !== '' || isDisabled === true ) {
                        doctorName.classList.remove('invalid');
                        doctornameerror.style.display = "none";

                        if(checkedConditionArray.length !== 0) {
                            healthConditionCheckbox.classList.remove('invalid');
                            healthConditionerror.style.display = "none";

                            let selfCheckinData = {};
                            
                            selfCheckinData.name = patientName.value;
                            selfCheckinData.phone_number = phoneNumber.value;
                            selfCheckinData.doctor_id = doctorName.value;
                            selfCheckinData.diagnosis = checkedConditionArray;
                            selfCheckinData.clinic_id = currentClinicRef.current.clinic_id;
                            console.log(selfCheckinData);
                            
                            setShowSuccessMsg(true);
                            // Resource.updateSelf(obj).then((resp) => {
                            //     if(resp.success) {
                            //         auth.getConfig({role: role}).then((resp) => {
                            //             Toast.success("Registration form submitted successfully!!");  
                            //         });
                            //     }
                            // });
                        }
                        else {
                            healthConditionerror.style.display = "inline";
                            healthConditionCheckbox.classList.add('invalid');
                            healthConditionerror.innerHTML = healthConditionError;
                        }
                    }
                    else {
                        doctornameerror.style.display = "inline";
                        doctorName.classList.add("invalid");
                        doctornameerror.innerHTML = doctorNameerror;
                    }
                }    
                else {
                    phoneerror.style.display = "inline";
                    phoneNumber.classList.add("invalid");
                    phoneerror.innerHTML = phoneError;
                }     
            }
            else {
                patientnameerror.style.display = "inline";
                patientName.classList.add("invalid");
                patientnameerror.innerHTML = Error;
            }
        }
        else {
            patientnameerror.style.display = "inline";
            patientName.classList.add("invalid");
            patientnameerror.innerHTML = Error;

            phoneerror.style.display = "inline";
            phoneNumber.classList.add("invalid");
            phoneerror.innerHTML = phoneError;

            if(!isDisabled){
                doctornameerror.style.display = "inline";
                doctorName.classList.add('invalid');
                doctornameerror.innerHTML = Error;
            }

            healthConditionerror.style.display = "inline";
            healthConditionCheckbox.classList.add('invalid');
            healthConditionerror.innerHTML = healthConditionError;

            // fileerror.style.display = "inline";
            // mediaUpload.classList.add('invalid');
            // fileerror.innerHTML = Error;
        }    
    }
    
    useEffect(() => {
        doctorsList.length === 1 ? setIsDisabled(true) : setIsDisabled(false)
    },[]);

    return (
        <>
            <div className="selfcheckinIntro">
                <div className="container">
                    <div className='selfCheckin-logo'>
                        <img className='logo_image' src={logo} alt="logoImage"></img>
                    </div>
                    <div className='selfCheckin-title'>
                        <h1 className="title">Register for a 20-Minute FREE Health Counselling</h1>
                    </div>
                    
                    { 
                    !showSuccessMsg &&
                    <form className=" registerForm selfCheckin-form">              
                        <div className='form-element'>
                            <label>Patient Name</label>
                            <input type="text" id="patient-name" className="inputField" placeholder="Enter patient name" required />
                            <span className="field-error" id="patientnameerror"></span>
                        </div>

                        <div className='form-element'>
                            <label>10-digit Phone Number</label>
                            <input type="tel" id="phone-number" className="inputField"  placeholder="+91   Enter phone number" required />
                            <span className="field-error" id="phoneerror"></span>
                        </div>

                        <div className='form-element'>
                            <label>Doctor Name</label>
                            <select value={selectedDoctorId} onChange={(e)=>setSelectedDoctorId(e.target.value)} defaultValue={selectedDoctorId} id="doctor-name" className="inputField" placeholder='Select doctor' disabled={isDisabled} required >
                                {
                                    doctorsList.length === 1 ? 
                                    <>
                                    {
                                        <option value={doctorsList[0].id}>{'Dr. ' + doctorsList[0].first_name + ' ' + doctorsList[0].last_name}</option>
                                    }
                                    </>
                                    :
                                    <>
                                        <option value="">-- Select Doctor --</option>
                                        {
                                            doctorsList.map((doctor,index) => {
                                                return <option key={index} value={doctor.id}> {'Dr. ' + doctor.first_name + ' ' + doctor.last_name}</option>
                                            })
                                        }
                                    </>
                                }
                            </select>
                            <span className="field-error" id="doctornameerror"></span>
                        </div>

                        <div className="healthConditionsMultipleCheck">
                            {/*<h2 className="healthConditionsMultipleCheckTitle">Health Conditions</h2>*/}
                            <label>Select Health Condition/s</label>
                            <div className="form-element" id="multiCheck">
                                {
                                    healthConditionsList.map((condition) => {                
                                        return <div key={condition.id} className="checkboxGroup" >
                                            <input
                                                id={condition.id}
                                                type="checkbox"
                                                // checked={checkedHealthCondition}
                                                onClick={() => {changeChecked(condition)}}/>
                                            <label htmlFor={condition.id} >
                                                {condition.name}
                                            </label>
                                        </div>
                                    })
                                }
                            </div>
                            <span className="field-error" id="healthConditionerror"></span>
                        </div>

                        <div className='form-element'>
                            <label>Upload Prescription (if you have)</label>
                            <div id='mediaUpload'>
                                <PrescriptionUpload id="prescription-file" />
                            </div>
                            <span className="field-error" id="fileerror"></span>
                        </div>

                        <Button text={'Submit'} buttonType={'primary_btn'} onclick={submitSelfCheckinForm} size={'fluid'}></Button>
                    </form> 
                    }
                    {
                        showSuccessMsg && 
                        <div class="booking-success">
                            <h2>Success</h2>
                            <p>Your FREE health counselling has been booked. You will get a confirmed time slot message soon.</p>
                        </div>
                    }
                </div> 
            </div>
        </>
    )
}

export default SelfCheckin;
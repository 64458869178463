import React from 'react';
import './Timeline.scss';

const Timeline = () => {

    return (
        <div className="time_line">
            <div className='timeline_circle'>
                <div className='outer_circle'>
                    <div className='inner_circle'></div>
                </div>
                <div className='timeline_line'></div>
            </div>
            <div className='timeline_content_box'>
                
            </div>
        </div>
    )

}

export default Timeline;
function beautifyPhoneNumber(phone) {
    phone = phone?.replace(' ', '');
    if(phone && phone.length >= 10) {
        var ph = phone.slice(-10, -5) + ' ' + phone.slice(-5);
        var code = phone.replace(phone.slice(-10), '');
        if(code.trim()) {
            return '(' + code + ') ' + ph;
        }
        else {
            return ph;
        }
    }
    else {
        return phone;
    }
}

function getDateRange(v) {
    const today = new Date();
    let s = new Date(today);  
    let e = new Date(today);
    let weekDay = s.getDay();
    let sdt_str = '';
    let edt_str = '';

    switch(v) {
        case 'today':
            sdt_str = s.getFullYear() + '-' + (s.getMonth()+1) + '-' + s.getDate();
            return {
                start_date: sdt_str + 'T00:00:00',
                end_date: sdt_str + 'T23:59:59'
            }
        case 'yesterday':
            let yesterday = new Date(new Date().setDate(s.getDate() - 1));
            sdt_str = yesterday.getFullYear() + '-' + (yesterday.getMonth()+1) + '-' + yesterday.getDate();

            return {
                start_date: sdt_str + 'T00:00:00',
                end_date: sdt_str + 'T23:59:59'
            }
            
        case 'this_week':

            if (weekDay === 0) {
                s.setDate(s.getDate() - 6);
            } else if (weekDay === 1) {
                e.setDate(e.getDate() + 7 - e.getDay());
            } else if (weekDay >= 1) {
                s.setDate(s.getDate() - s.getDay() + 1);
                e.setDate(e.getDate() + 7 - e.getDay());
            }

            sdt_str = s.getFullYear() + '-' + (s.getMonth()+1) + '-' + s.getDate();
            edt_str = e.getFullYear() + '-' + (e.getMonth()+1) + '-' + e.getDate();
            
            return {
                start_date: sdt_str + 'T00:00:00',
                end_date: edt_str + 'T23:59:59'
            }

        case 'last_week':
            
            if (weekDay === 0) {
                s.setDate(s.getDate() - 13);
            } else if (weekDay === 1) {
                e.setDate(e.getDate() - e.getDay());
            } else if (weekDay >= 1) {
                s.setDate(s.getDate() - s.getDay() - 6);
                e.setDate(e.getDate() - e.getDay());
            }

            sdt_str = s.getFullYear() + '-' + (s.getMonth()+1) + '-' + s.getDate();
            edt_str = e.getFullYear() + '-' + (e.getMonth()+1) + '-' + e.getDate();
            
            return {
                start_date: sdt_str + 'T00:00:00',
                end_date: edt_str + 'T23:59:59'
            }

        case 'this_month':
            s = new Date(s.getFullYear(), s.getMonth(), 1);
            e = new Date(e.getFullYear(), e.getMonth()+1, 0);
            
            sdt_str = s.getFullYear() + '-' + (s.getMonth()+1) + '-' + s.getDate();
            edt_str = e.getFullYear() + '-' + (e.getMonth()+1) + '-' + e.getDate();
            
            return {
                start_date: sdt_str + 'T00:00:00',
                end_date: edt_str + 'T23:59:59'
            }

        case 'last_month':
            s = new Date(s.getFullYear(), s.getMonth()-1, 1);
            e = new Date(e.getFullYear(), e.getMonth(), 0);
            
            sdt_str = s.getFullYear() + '-' + (s.getMonth()+1) + '-' + s.getDate();
            edt_str = e.getFullYear() + '-' + (e.getMonth()+1) + '-' + e.getDate();
            
            return {
                start_date: sdt_str + 'T00:00:00',
                end_date: edt_str + 'T23:59:59'
            }

        case 'last_3_months':
            s = new Date(s.getFullYear(), s.getMonth()-3, 1);
            e = new Date(e.getFullYear(), e.getMonth(), 0);
            
            sdt_str = s.getFullYear() + '-' + (s.getMonth()+1) + '-' + s.getDate();
            edt_str = e.getFullYear() + '-' + (e.getMonth()+1) + '-' + e.getDate();
            
            return {
                start_date: sdt_str + 'T00:00:00',
                end_date: edt_str + 'T23:59:59'
            }

        case 'last_6_months':
            s = new Date(s.getFullYear(), s.getMonth()-6, 1);
            e = new Date(e.getFullYear(), e.getMonth(), 0);
            
            sdt_str = s.getFullYear() + '-' + (s.getMonth()+1) + '-' + s.getDate();
            edt_str = e.getFullYear() + '-' + (e.getMonth()+1) + '-' + e.getDate();
            
            return {
                start_date: sdt_str + 'T00:00:00',
                end_date: edt_str + 'T23:59:59'
            }
    }
}

function formatPatientData(d) {
    var p = {};
    if(d) {
        if(d.lead_id) {
            p.status = 'New';
            p.remarks = 'Patient has not paid yet';
            p.id = d.lead_id;
            p.doctor_name = 'Dr. ' + d.program_info.doctor_first_name + ' ' + d.program_info.doctor_last_name;
        }
        else {
            p.id = d.auth_id;
            p.doctor_name = 'Dr. ' + d.doctor_first_name + ' ' + d.doctor_last_name;
            p.care_manager_name = '-';
            if(d.care_manager_first_name || d.care_manager_last_name)
                p.care_manager_name = 'Dr. ' + d.care_manager_first_name + ' ' + d.care_manager_last_name;

            switch(d.status) {
                case 10:
                    p.status = 'Paid';
                    p.remarks = 'Program has not started yet';
                    break;
                case 20:
                    p.status = 'Active';
                    p.remarks = 'Patient is on the program';
                    break;
                case 30:
                    p.status = 'On hold';
                    p.remarks = 'You have put the patient on hold';
                    break;
                case 40:
                    p.status = 'Halt';
                    p.remarks = 'Patient has asked to pause the program';
                    break;
                case 100:
                    p.status = 'Completed';
                    p.remarks = 'Patient has completed the program as suggested';
                    break;
                case 999:
                    p.status = 'Quit';
                    p.remarks = 'Patient has quit the program in between';
                    break;
                default:
                    p.status = 'Active';
            }
            
            p.program_start_date = (d.program_info && d.program_info.program_start_date) || '-';

        }
        
        p.name = d.first_name;
        if(p.name && d.last_name)
            p.name += ' ' + d.last_name;
            
        //p.program_title = (d.program_info && d.program_info.program_title) || '-';
        //p.program_duration = (d.program_info && d.program_info.program_duration) || '-';
        
        p.program_info = d.program_info || {};
        
        if(p.program_info.referred_date) {
            var dt = new Date(p.program_info.referred_date);
            p.referred_date = dt.toDateString();
        }
        else {
            p.referred_date = '-';
        }

        p.age = d.age;
        p.sex = d.sex;
    }
    return p;
}

export {
    beautifyPhoneNumber,
    getDateRange,
    formatPatientData
}
import React, { useState } from 'react';
import MedicalCertificateUpload from '../../Upload/MedicalCertificateUpload';
import ProfilePicUpload from '../../Upload/ProfilePicUpload';
import './CaremanagerEditform.scss';

const CaremanagerEditForm = ({rowDetail}) => {
    console.log(rowDetail);
    const [selectSpeciality, setSelectSpeciality] = useState({value: rowDetail.specialization});
    const [showUploadField , setShowUploadField] = useState(false);

    function handleRadioButton(e) {
        let currentValue = e.target.value;
        console.log(currentValue);
        if(currentValue === 'yes') {
            setShowUploadField(true);
        } 
        else {
            setShowUploadField(false);
        }
    }
    
    function selectedSpeciality(e) {
        setSelectSpeciality(e.target.value);
    }

    return (
        
            <div className="container">
                
                <form autoComplete="disabled"  className="editCaremanager-form registerForm">
                    <div className='form-element'>
                        <label>Profile Photo</label>
                        <ProfilePicUpload id="cm-image" assigned_to={rowDetail.id}/>
                        <span className="field-error" id="imageerror"></span>
                    </div>
                     
                    <div className='form-element'>
                        <label>First Name</label>
                        <input type="text" id="cm-fname" className="inputField" defaultValue={rowDetail.first_name} required />
                        <span className="field-error" id="nameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Last Name</label>
                        <input type="text" id="cm-lname" className="inputField" defaultValue={rowDetail.last_name} required />
                        <span className="field-error" id="nameerror"></span>
                    </div>
                     
                    <div className='form-element'>
                        <label>Practice as</label>
                        <select id="cm-speciality" className="inputField" value={selectSpeciality.value} onChange={selectedSpeciality} required>
                            <option value="Naturopathy">Naturopathy</option>
                            <option value="Ayurveda">Ayurveda</option>
                            <option value="Functional Medicine">Functional Medicine</option>
                            <option value="Homeopathy">Homeopathy</option>
                            <option value="Others">Others</option>
                        </select>
                        <span className="field-error" id="specialityerror"></span>
                    </div>
                     
                    <div className='form-element'>
                        <label>10-digit Phone Number</label>
                        <input autoComplete="disabled"  type="tel" id="cm-phonenumber" className="inputField" defaultValue={rowDetail.contact_number} required />
                        <span className="field-error" id="phoneerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>AYUSH Registered ?</label>
                        <div className='radioButtonGroup'>
                            <label for="yes">
                                <input type="radio" id="ayushRegistered" name="yes/no" value="yes" onChange={(e)=>handleRadioButton(e)}/>Yes
                            </label>
                        </div>
                        <div className='radioButtonGroup'>
                            <label for="no">
                                <input type="radio" id="ayushNotRegistered" name="yes/no" value="no" onChange={(e)=>handleRadioButton(e)}/> No
                            </label>
                        </div>
                        <span className="field-error" id="ayushRegisterederror"></span>
                    </div>
                    {
                        showUploadField &&
                        <div className='form-element'>
                            <label>Upload Certificate</label>
                            <MedicalCertificateUpload assigned_to={rowDetail.id} id="cm-file" />
                            <span className="field-error" id="fileerror"></span>
                        </div>
                    }
                     
                </form>
                
            </div>
       
    );
}
export default CaremanagerEditForm;
import React from 'react';
import { beautifyPhoneNumber } from '../../../../helpers/utils';

const AdminMyAccountForm = ({currentUser}) => {
    
    return (
        <>
            <div className='myAccount_name'>
                <div className='first_name'>
                    <span>First Name</span>
                    <h3>{currentUser.first_name}</h3>
                </div>
                <div className='last_name'>
                    <span>Last Name</span>
                    <h3>{currentUser.last_name}</h3>
                </div>
            </div>
            <div className='email'>
                <span>Email</span>
                <h3>{currentUser.email}</h3>
            </div>
            <div className='phone_number'>
                <span>Contact Number</span>
                <h3>{beautifyPhoneNumber(currentUser.contact_number)}</h3>
            </div>
            <div className='phone_number'>
                <span>Username</span>
                <h3>{currentUser.username}</h3>
            </div>
        </>
    )
}

export default AdminMyAccountForm;
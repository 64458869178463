import React,{useEffect,useState,useRef} from 'react';
import closeIcon from './../../../images/close.png';
import { Link } from 'react-router-dom';
import { register } from '../../../serviceWorkerRegistration';
import Button from '../../Button/Button';
import Resource from '../../../services/resource.service';
import Toast from '../../Toaster/Toast';
import './AssistantEditForm.scss'

const AssistantEditForm = ({rowDetail,showVisitingSchedule}) => {
    
    const [schedules,setSchedules] = useState([]);

    const dayRef = useRef();
    const dayErrorRef = useRef();
    const startTimeRef = useRef();
    const startTimeErrorRef = useRef();
    const startTimePeriodRef = useRef();
    const endTimeRef = useRef();
    const endTimeErrorRef = useRef();
    const endTimePeriodRef = useRef();

    const Time = [
        '-',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12'
    ];

    const registerAssistant = () => {
        var Error = "This field cannot be empty";
        // var emailvalueError = "Please provide a valid email-id";
        var namevalueError = "Please provide a name";
        var phonevalueError = "Please provide a 10 digit valid phone number";
        // var agevalueError = "Please provide a age between 18 and 99";
        let phoneformat = /^([6-9])(\d{9})$/;


        var firstname = document.getElementById('assistant-fname');
        var lastname = document.getElementById('assistant-lname');
        var searchByClinic = document.getElementById('search-clinic');
        var phonenumber = document.getElementById('assistant-ph');

        var firstnameerror = document.getElementById('firstnameerror');
        var lastnameerror = document.getElementById('lastnameerror');
        var searchByClinicError = document.getElementById('searchByClinicError');
        var phoneerror = document.getElementById('phoneerror');

        if (firstname.value || lastname.value || searchByClinic.value || phonenumber.value) {
            if (firstname.value.length > 0) {
                firstname.classList.remove('invalid');
                firstnameerror.style.display = "none";

                if (lastname.value.length > 0) {
                    lastname.classList.remove('invalid');
                    lastnameerror.style.display = "none";

                    if (searchByClinic.value !== '') {
                        searchByClinic.classList.remove('invalid');
                        searchByClinicError.style.display = "none";

                        if (phonenumber.value !== ''&& phonenumber.value.match(phoneformat)) {
                            var obj = {
                                first_name: '',
                                last_name: '',
                                doctor_id: '',
                                assistant_phone_number: ''
                            };
                            obj.first_name = firstname.value;
                            obj.last_name = lastname.value;
                            obj.assistant_phone_number = '+91' + phonenumber.value.replace(/\D/g, "");
                            obj.doctor_id = parseInt(searchByClinic.value, 10);
                            
                            console.log(obj);
    
                            return Resource.createUser(Resource.ROLES.ASSISTANT, obj).then((resp) => {
                                if(resp.success) {
                                    return true;
                                }
                            });
                        }
                        else {
                            phoneerror.classList.add('invalid');
                            phoneerror.style.display = "inline";
                            phoneerror.innerHTML = phonevalueError;   

                            return new Promise( () => {return false} );
                        }
                    }
                    else {
                        searchByClinic.classList.add('invalid');
                        searchByClinicError.style.display = "inline";
                        searchByClinicError.innerHTML = Error;   

                        return new Promise( () => {return false} );
                    }
                }
                else {
                    lastname.classList.add("invalid");
                    lastnameerror.style.display = "inline";
                    lastnameerror.innerHTML = namevalueError;   

                    return new Promise( () => {return false} );
                }
            }
            else {
                firstname.classList.add("invalid");
                firstnameerror.style.display = "inline";
                firstnameerror.innerHTML = namevalueError;
                   
                return new Promise( () => {return false} );
            }
        }
        else {
            firstnameerror.style.display = "inline";
            firstname.classList.add("invalid");
            firstnameerror.innerHTML = namevalueError;

            lastnameerror.style.display = "inline";
            lastname.classList.add("invalid");
            lastnameerror.innerHTML = namevalueError;

            searchByClinicError.style.display = "inline";
            searchByClinic.classList.add('invalid');
            searchByClinicError.innerHTML = Error;

            phoneerror.classList.add('invalid');
            phoneerror.style.display = "inline";
            phoneerror.innerHTML = Error;  

            return new Promise( () => {return false} );  
        }
        
    }

    const addSchedule = (e)=>{
        e.preventDefault();
        let day = dayRef.current.value;
        let startTime = startTimeRef.current.value;
        let endTime = endTimeRef.current.value;
        let startTimewithPeriod = startTime + startTimePeriodRef.current.value;
        let endTimewithPeriod = endTime + endTimePeriodRef.current.value;

        let dayError = dayErrorRef.current;
        let startTimeError = startTimeErrorRef.current;
        let endTimeError = endTimeErrorRef.current;

        
        if(day !== 'Select day' || startTime !=='-' || endTime !== '-' ){
            if(day !== 'Select day'){
                dayErrorRef.current.style.display = 'none';
                if(startTime !=='-'){
                    startTimeErrorRef.current.style.display = 'none';

                    if(endTime !== '-'){
                        endTimeErrorRef.current.style.display = 'none';
                        let schedule = {
                            'day': day,
                            'startTime': startTimewithPeriod,
                            'endTime': endTimewithPeriod,
                        };
                
                        setSchedules([...schedules,schedule]);
                        dayRef.current.value = 'Select day';
                        startTimeRef.current.value = '-';
                        endTimeRef.current.value = '-';
                        startTimePeriodRef.current.value = 'AM';
                        endTimePeriodRef.current.value = 'AM'; 
                        dayErrorRef.current.style.display = 'none';
                        startTimeErrorRef.current.style.display = 'none';
                        endTimeErrorRef.current.style.display = 'none';
                    }
                    else{
                        endTimeErrorRef.current.style.display = 'block';
                        endTimeError.innerHTML = 'select end Time';
                    }
                }
                else{
                    startTimeErrorRef.current.style.display = 'block';
                    startTimeError.innerHTML = 'select Start Time';
                }
            }else{
                dayErrorRef.current.style.display = 'block';
                dayError.innerHTML = 'select day';
            }
         
        }else{
            
            dayErrorRef.current.style.display = 'block';
            startTimeErrorRef.current.style.display = 'block';
            endTimeErrorRef.current.style.display = 'block';
            dayError.innerHTML = 'select day';
            startTimeError.innerHTML = 'select Start Time';
            endTimeError.innerHTML = 'select end Time';
        }
    }

    const deleteSchedule = (index)=>{
        let duplicateSchedules = [...schedules];
        duplicateSchedules.splice(index,1);
        setSchedules(duplicateSchedules);
    }

    useEffect(() => {
        rowDetail.registerAssistant = registerAssistant;

        return () => {
            delete rowDetail.registerAssistant;
        };
    });

    return (
       <div className='assistant_modal_container'>
    
            <form autoComplete="disabled"  className="registerForm addAssistant-form">
                
                <div className='form-element'>
                    <label>Add staff to </label>
                    <h3>Dr. {rowDetail.first_name + ' ' + rowDetail.last_name}</h3>
                </div>
                <div className='form-element'>
                    <select id="search-clinic" className="inputField" placeholder='Select clinic' required>
                        <option value="">-- Select Clinic --</option>
                        {
                            rowDetail?.clinics && rowDetail.clinics.map((c) => {
                                return <option value={c.doctor_id}>{c.clinic_name}</option>
                            })
                        }
                    </select>
                    <span className="field-error" id="searchByClinicError"></span>
                </div>

                <div className='form-element'>
                    <label>First Name</label>
                    <input type="text" id="assistant-fname" className="inputField" defaultValue={rowDetail.first_name} required />
                    <span className="field-error" id="firstnameerror"></span>
                </div>

                <div className='form-element'>
                    <label>Last Name</label>
                    <input type="text" id="assistant-lname" className="inputField" defaultValue={rowDetail.last_name} required />
                    <span className="field-error" id="lastnameerror"></span>
                </div>

                <div className='form-element'>
                    <label>10-digit Phone Number</label>
                    <input autoComplete="disabled"  type="tel" id="assistant-ph" className="inputField" defaultValue={rowDetail.contact_number} required />
                    <span className="field-error" id="phoneerror"></span>
                </div>
                {
                        showVisitingSchedule &&
                        <div className='visiting_schedule'>
                            <h3 className='visitingSchedule_title'>Visiting Schedule</h3>
                            <div className='scheduled_time'>
                                {
                                   schedules.length > 0 && schedules.map((schedule,index)=>{
                                        return( 
                                            <div className='schedule_component'>
                                                <div className='schedule_day'>
                                                    { schedule.day }
                                                </div>
                                                <div className='schedule_time'>
                                                    {schedule.startTime} - {schedule.endTime}
                                                </div>
                                                <svg onClick={()=>deleteSchedule(index)} className='close-btn' width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 6L6 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                    <path d="M6 6L18 18" stroke="#1c1e1f" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='visiting_day_and_time'>
                                <div className='form-element visiting_day'>
                                    <label>Day</label>
                                    <select ref={dayRef} id="d-visitingDay" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                        <option value="Select day">Select day</option>
                                        <option value="Sunday">Sunday</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                    </select>
                                    <span className="field-error" ref={dayErrorRef} id="visitindDayerror"></span>
                                </div>
                                <div className='time_schedular'>
                                    <div className='form-element time_schedular_container'>
                                        <label>Start Time</label>
                                        <div className='start_time_div'>
                                            <select ref={startTimeRef} id="d-visitingStartTime" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                                {
                                                    Time.map((time) => {
                                                        return <option value={time}>{time}</option>
                                                    })
                                                }
                                            </select>
                                            <select ref={startTimePeriodRef} id="d-visiting" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                        </div>
                                        <span className="field-error" ref={startTimeErrorRef} id="visitindDayerror"></span>
                                    </div>
                                    <div className='form-element time_schedular_container'>
                                        <label>End Time</label>
                                        <div className='end_time_div'>
                                            <select ref={endTimeRef} id="d-visitingEndTime" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                            {
                                                Time.map((time) => {
                                                    return <option value={time}>{time}</option>
                                                })
                                            }
                                            </select>
                                            <select ref={endTimePeriodRef} id="d-visiting" className="inputField"  onChange={(e) => {rowDetail.editFormData.metainfo.visiting_schedule = e.target.value}} required>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                        </div>
                                        <span className="field-error" ref={endTimeErrorRef} id="visitindDayerror"></span>
                                    </div>
                                </div>
                                <Button text={'Add Schedule'} onclick={addSchedule} buttonType={'secondary_btn'} size={'fluid'} />
                            </div>
                        </div>
                        
                    }
            </form>
       </div>
    );

};

export default AssistantEditForm;
import './Home.scss';
import React, { useEffect, useState,useRef } from "react";
import Tabcontainer from '../../Tabcontainer/Tabcontainer';
import Resource from "../../../services/resource.service";
import MainContent from './MainContent/MainContent';
import { useAuth } from '../../../services/use-auth';


const AdminHome = () => {
    
    const auth = useAuth();
    var tabs = [
        {
            'label': 'Patients',
            'role': Resource.ROLES.PATIENT,      //Going to be deprecated soon
            'resource': Resource.ROLES.PATIENT,
            'filter': {},
            'data': [],
            'fetch': async (f) => {
                let response = await Resource.getUsers(Resource.ROLES.PATIENT, null, f);
                if(response.success) {
                    for(let i = 0; i < response.data.length; i++) {
                        let patient = response.data[i];
                        if(patient.lead_id) {
                            patient['status'] = 'Lead';
                            patient.id = patient.lead_id;
                            patient.url = '/lead/' + patient.lead_id;
                        }
                        else {
                            patient['status'] = 'Active';
                            patient.url = '/patient/' + patient.id;
                        }
                        
                        patient.name = patient.first_name || '';
                        if(patient.name && patient.last_name)
                            patient.name += ' ' + patient.last_name;

                        if(patient.program_info) {
                            patient.program_referred = patient.program_info.program_title;
                            patient.program_duration = patient.program_info.program_duration + ' Weeks';
                            if(patient.program_info.payment_schedule) {
                                if(patient.program_info.payment_schedule == '3m')
                                    patient.payment_schedule = '3-Month';
                                else
                                    patient.payment_schedule = 'Monthly';
                            }
                            patient.program_quote = patient.program_info.currency + ' ' + patient.program_info.quote;
                            patient.program_discount = patient.program_info.currency + ' ' + patient.program_info.discount;
                        }

                        patient.doctor_name = (patient.doctor_first_name || '') + ' ' + (patient.doctor_last_name || '');
                        if(patient.doctor_name.trim())
                            patient.doctor_name = 'Dr. ' + patient.doctor_name.trim()
                        
                        patient.care_manager_name = (patient.care_manager_first_name || '') + ' ' + (patient.care_manager_last_name || '');
                        if(patient.care_manager_name.trim())
                            patient.care_manager_name = 'Dr. ' + patient.care_manager_name.trim()

                    }
                    return response.data;
                }
                else {
                    return [];
                }
            },
            'header': [
                {
                    'name': 'ID',
                    'field': 'id',
                    'url': true
                },
                {
                    'name': 'Status',
                    'field': 'status'
                },
                {
                    'name': 'Name',
                    'field': 'name'
                },
                {
                    'name': 'Program Referred',
                    'field': 'program_referred'
                },
                {
                    'name': 'Payment Schedule',
                    'field': 'payment_schedule'
                },
                {
                    'name': 'Quoted Price',
                    'field': 'program_quote'
                },
                {
                    'name': 'Discount',
                    'field': 'program_discount'
                },
                {
                    'name': 'Doctor',
                    'field': 'doctor_name'
                },
                {
                    'name': 'Care Manager',
                    'field': 'care_manager_name'
                }
            ]
        },
        {
            'label': 'Doctors',
            'role': Resource.ROLES.DOCTOR,      //Going to be deprecated soon
            'resource': Resource.ROLES.DOCTOR,
            'filter': {},
            'data': [],
            'fetch': async (f) => {
                let response = await Resource.getUsers(Resource.ROLES.DOCTOR, null, f);
                if(response.success) {
                    for(let i = 0; i < response.data.length; i++)
                    {

                        response.data[i].clinic_name = '';
                        if(response.data[i].clinics) {
                            for(let j in response.data[i].clinics)
                                response.data[i].clinic_name += response.data[i].clinics[j].clinic_name + '; ';
                            response.data[i].clinic_name = response.data[i].clinic_name.slice(0, -2);
                        }

                        if(response.data[i].metainfo && response.data[i].metainfo.hasOwnProperty('practice_id'))
                            response.data[i].practice_id = response.data[i].metainfo.practice_id;
                        else
                            response.data[i].practice_id = '';

                    }
                    return response.data;
                }
                else {
                    return [];
                }
            },
            'header': [
                {
                    'name': 'ID',
                    'field': 'id'
                },
                {
                    'name': 'Phone number',
                    'field': 'username'
                },
                {
                    'name': 'First Name',
                    'field': 'first_name'
                },
                {
                    'name': 'Last Name',
                    'field': 'last_name'
                },
                {
                    'name': 'Clinic Name',
                    'field': 'clinic_name'
                },
                {
                    'name': 'Practicing As',
                    'field': 'practice_id'
                }
            ]
        },
        {
            'label': 'Care Managers',
            'role': Resource.ROLES.CARE_MANAGER,        //Going to be deprecated soon
            'resource': Resource.ROLES.CARE_MANAGER,
            'filter': {},
            'data': [],
            'fetch': async (f) => {
                let response = await Resource.getUsers(Resource.ROLES.CARE_MANAGER, null, f);
                if(response.success) {
                    return response.data;
                }
                else {
                    return [];
                }
            },
            'header': [
                {
                    'name': 'ID',
                    'field': 'id'
                },
                {
                    'name': 'Phone number',
                    'field': 'username'
                },
                {
                    'name': 'First Name',
                    'field': 'first_name'
                },
                {
                    'name': 'Last Name',
                    'field': 'last_name'
                }
            ]
        },
        {
            'label': 'Clinics',
            'role': 'clinics',      //Going to be deprecated soon
            'resource': 'clinics',
            'filter': {},
            'data': [],
            'fetch':async () => {
                let response = await Resource.getClinics();
                if(response.success) {
                    for(let i = 0; i < response.data.length; i++)
                    {
                        if(response.data[i].metainfo && response.data[i].metainfo.hasOwnProperty('city'))
                            response.data[i].city = response.data[i].metainfo.city;
                        else
                            response.data[i].city = '';

                        if(response.data[i].metainfo && response.data[i].metainfo.hasOwnProperty('whatsapp_number'))
                            response.data[i].whatsapp_number = response.data[i].metainfo.whatsapp_number;
                        else
                            response.data[i].whatsapp_number = '';

                        if(response.data[i].metainfo && response.data[i].metainfo.hasOwnProperty('incentives'))
                            response.data[i].incentives = response.data[i].metainfo.incentives + "%";
                        else
                            response.data[i].incentives = '';

                    }
                    return response.data;
                }
                else {
                    return [];
                }
            },
            'header': [
                {
                    'name': 'ID',
                    'field': 'id'
                },
                {
                    'name': 'Clinic Name',
                    'field': 'name'
                },
                {
                    'name': 'Address',
                    'field': 'address'
                },
                {
                    'name': 'Phone number',
                    'field': 'contact_number'
                },
                {
                    'name': 'City',
                    'field': 'city'
                },
                {
                    'name': 'Whatsapp number',
                    'field': 'whatsapp_number'
                },
                {
                    'name': 'Incentives',
                    'field': 'incentives'
                }
            ]
        },
        {
            'label': 'QMS',
            'role': 'clinics',      //Going to be deprecated soon
            'resource': 'qms',
            'filter': {
            },
            'data': [],
            'fetch': async (f) => {
                
                console.log(f);
                let response = await Resource.getUsers('visitor', auth.user.id, f);
                console.log(response);
                if(response.success) {
                    for(let i = 0; i < response.data.length; i++)
                    {
                        response.data[i].url = '/clinic/' + response.data[i].clinic_id;
                    }
                    return response.data;
                }
                else {
                    return [];
                }
            },
            'header': [
                {
                    'name': 'Sr#',
                    'field': 'clinic_qms_id'
                },
                {
                    'name': 'Patient Name',
                    'field': 'patient_name'
                },
                {
                    'name': 'Patient Number',
                    'field': 'patient_phone'
                },
                {
                    'name': 'Patient Age',
                    'field': 'patient_age'
                },
                {
                    'name': 'Gender',
                    'field': 'patient_gender'
                },
                {
                    'name': 'Clinic ID',
                    'field': 'clinic_id'
                },
                {
                    'name': 'Doctor Name',
                    'field': 'doctor_name'
                },
                {
                    'name': 'Token #',
                    'field': 'token_number'
                },
                {
                    'name': 'Date',
                    'field': 'date'
                }
            ]
        }
    ]

    var setMainContentTabIndex = null;
    const activeTabRef = useRef(-1);

    function fetchActiveTab(index) {
        if(index >= 0)
        {
            let activeTab = tabs[index];
            if(activeTab.fetch !== '' ){
                activeTab.fetch(activeTab.filter).then((data) => {
                    activeTab.data = data;
                    activeTabRef.current = index;
                    setMainContentTabIndex(activeTabRef.current);
                }); 
            }
            else{
                // activeTab.data = [
                //     {
                //         id:'1',
                //         clinic_name:'Saro',
                //         address:'xyz',
                //         phone_number:'1234567890',
                //         city:'bangalore',
                //         whatsapp_number:'1234567890',
                //         incentives:'30%'
                //     }
                // ];
                activeTabRef.current = index;
                setMainContentTabIndex(activeTabRef.current);
            }
      
        }
    }

    function updateMainContentTabIndex(setCurrentTabIndex) {
        setMainContentTabIndex = setCurrentTabIndex;
    }

    let labelsArray = tabs.map((tab)=>{
        return tab.label
    });

    useEffect(() => {
        if(activeTabRef.current >= 0) {
            fetchActiveTab(activeTabRef.current);
        }
        else
            fetchActiveTab(0);
            
    }, [auth.user]);

    return (
        <div className="home-container">
            <Tabcontainer className={'leftTab'} setActiveTab={fetchActiveTab} tabs={labelsArray}/>
            <MainContent updateMain={updateMainContentTabIndex} tabs={tabs}/>
        </div>
    )

}    

export default AdminHome;
import './../Home.scss';
import React, { useEffect, useState,useRef } from "react";
import Table from './../../../Table/Table';
import Modal from './../../../Modal/Modal';
import Doctormodal from './../../../Modal/Doctormodal/Doctormodal';
import Caremanagermodal from './../../../Modal/Caremanagermodal/Caremanagermodal';
import Resource from "./../../../../services/resource.service";
import Search from './../../../Search/Search';
import Filter from './../../../Filter/Filter';
import Toast from './../../../Toaster/Toast';
import AddPatientForm from '../../AddPatientForm/AddPatientForm';
import AddClinicForm from '../../../Modal/ClinicModal/AddClinicForm';
import { useAuth } from '../../../../services/use-auth';


const MainContent = ({updateMain, tabs, forceRelad}) => {

    const auth = useAuth();
    const [showModal,setShowModal] = useState(false);
    const [activeTabRef, setActiveTabRef] = useState(-1);
    
    useEffect(() => {
        updateMain(setActiveTabRef);
    }, [updateMain]);

    const closeModal =() => {
        setShowModal(false)
    }

    const [showUser, setShowuser] = useState(false);
    const [selectValue, setSelectValue] = useState();
    const [filterChange, setFilterChange] = useState(null);
    const [reload, setReload] = useState(false);
    const [fetchedData, setFetchedData] = useState(-1);
    
    const addRef = useRef();

    useEffect(() => {
        if(activeTabRef >= 0) {
            let activeTab = tabs[activeTabRef];
            if(activeTab.fetch !== ''){
                activeTab.fetch(activeTab.filter).then((data) => {
                    activeTab.data = data;
                    setFetchedData(activeTabRef);
                });
            }
            else {
                activeTab.data = [
                    {
                        id:'1',
                        clinic_name:'Saro',
                        address:'xyz',
                        phone_number:'1234567890',
                        city:'bangalore',
                        whatsapp_number:'1234567890',
                        incentives:'30%',
                        doctors:[{
                            id:'1',
                            first_name:'Fname1',
                            last_name:'Lname1',
                            contact_number:'123',
                            visitingSchedule:'11am - 7pm',
                            practice:'general_physician'
                        },{
                            id:'2',
                            first_name:'Fname2',
                            last_name:'Lname2',
                            contact_number:'456',
                            visitingSchedule:'9am - 4pm',
                            practice:'general_physician'
                        },{
                            id:'3',
                            first_name:'Fname3',
                            last_name:'Lname3',
                            contact_number:'789',
                            visitingSchedule:'10am - 5pm',
                            practice:'other'
                        }],
                        assistants:[{
                            id:'1',
                            first_name:'Fname1',
                            last_name:'Lname1',
                            contact_number:'123',
                            visitingSchedule:'11am - 7pm',
                        },{
                            id:'2',
                            first_name:'Fname2',
                            last_name:'Lname2',
                            contact_number:'456',
                            visitingSchedule:'9am - 4pm',
                        },{
                            id:'3',
                            first_name:'Fname3',
                            last_name:'Lname3',
                            contact_number:'789',
                            visitingSchedule:'10am - 5pm',
                        }]
                    }
                ];
                setFetchedData(activeTabRef);
            }
        }
    
    }, [activeTabRef, auth.user]);


    function showUserlist() {
        setShowuser(!showUser);
    }

    function showSelecteduser(e) {
        let selectedValue = (activeTabRef >= 0 ? tabs[activeTabRef].resource : '');
        setSelectValue(selectedValue);
        setShowModal(true);
        setShowuser(false);
    }
    
    const addPatientCallback = (data) => {
        Resource.referPatient(data, 'v2').then((response) => {
            if(response.success) {
                Toast.success("Patient Lead (ID: " + response.lead_id + ") added successfully!!");
            }
            else if(response.warning) {
                Toast.warning(response.warning);
            }
            else {
                Toast.error("ERROR: Could not add patient lead. Try after sometime!!");
            }
            closeModal();
        });
    }

    function handleClickOutside(e) {
    
        if(showUser) {
            if (!addRef.current.contains(e.target)) {
                setShowuser(false);
            }
        }
    }

    function suggestionLookup(query) {
        var otype;
        otype = tabs[activeTabRef].resource;
        if(tabs[activeTabRef].resource == Resource.ROLES.PATIENT)
            otype = 'patient;lead';

        var params = {
            q: query,
            otype: otype
        }

        return Resource.autocomplete(params);
    }

    function callbackLookup(item) {
        if(item) {
            Resource.getUserById(item.type, item.id).then((resposne) => {
                if(resposne.success) {
                    tabs[activeTabRef].data = [resposne.data];
                    setReload(!reload);
                }
                else {
                    Toast.error("ERROR: User not found");
                }
            });
        }
    }

    function forceReload(message) {
        if(activeTabRef >= 0) {
            tabs[activeTabRef].fetch(tabs[activeTabRef].filter).then((data) => {
                tabs[activeTabRef].data = data;  
                setReload(!reload);
                Toast.success(message || "Success");
            });
        }
    }

    function callbackFilter(filterFlag) {
        if(activeTabRef >= 0) {
            let activeTab = tabs[activeTabRef];
            activeTab.fetch(activeTab.filter).then((data) => {
                activeTab.data = data;
                setFilterChange(filterFlag);
            });
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        });
    },[showUser]);

    return <div className="tab-content" >
                <div className="content-header">
                    {
                        activeTabRef !== 3 ?
                            <>
                                <Search clear={activeTabRef} suggestionLookup={suggestionLookup} callbackLookup={callbackLookup}></Search>
                                <Filter callbackFilter={callbackFilter} currentTabIndex={activeTabRef} tabs={tabs} />
                            </>
                         :''
                    }
                   
                
                    <div className="add-container">
                        <button type="button" className="add-button" onClick={showSelecteduser} >
                            <svg className="add-image" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5 3.95825V15.0416" stroke="#5F6368" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                            <path d="M3.95834 9.5H15.0417" stroke="#5F6368" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                            </svg>
                            Add {activeTabRef >= 0 ? tabs[activeTabRef].resource.replace('_', ' ') : ''}
                        </button>
                        {
                            showModal ?
                                <Modal  closeModal={closeModal} right={'right'}>
                                    <div className='adduserForm'>
                                    {
                                        selectValue === Resource.ROLES.DOCTOR && <Doctormodal closeModal={closeModal} />
                                    }
                                    {
                                        selectValue === Resource.ROLES.CARE_MANAGER && <Caremanagermodal closeModal={closeModal} />

                                    }
                                    {
                                        selectValue === Resource.ROLES.PATIENT && <AddPatientForm callback={addPatientCallback} closeModal={closeModal} />
                                    }
                                    {
                                        selectValue === 'clinics' && <AddClinicForm closeModal={closeModal} />
                                    }
                                    </div>
                                </Modal>
                            :''
                        }  
                    </div>
                </div>
                <Table forceReload={forceReload} reload={reload} filterChange={filterChange} currentTabIndex={fetchedData} tabs={tabs} />
            </div>
}

export default MainContent;
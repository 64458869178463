import React, { useState } from 'react';
import closeIcon from './../../../images/close.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/use-auth';
import MedicalCertificateUpload from '../../Upload/MedicalCertificateUpload';
import Resource from '../../../services/resource.service';
import Button from '../../Button/Button';
import './../Caremanagermodal/Caremanagermodal.scss';
import Toast from '../../Toaster/Toast';

const Caremanagermodal = ({closeModal}) => {

    // function uploadImage(e) {
    //     console.log(e.target.files);
    // }

    const [showUploadField , setShowUploadField] = useState(false);
    const [certificateId, setCertificateId] = useState();
    const [assignedToId, setAssignedToID] = useState();

    const auth = useAuth();
    // const location = useLocation();
    // const role = location.state.role;

    const certificateUpload = (docData) => {
        if(docData.document_id){
            setCertificateId(docData.document_id);
            closeModal();
        }
    }

    function handleRadioButton(e) {
        let currentValue = e.target.value;
        if(currentValue === 'yes') {
            // setShowUploadField(true);
        } 
        else {
            setShowUploadField(false);
        }
    }

    function addCaremanager(e) {
        e.preventDefault();

        let phoneformat = /^\+(91)([6-9])(\d{9})$|^([6-9])(\d{9})$/;
        var Error = "This field cannot be empty";
        // var emailvalueError = "Please provide a valid email-id";
        var namevalueError = "Please provide a name";
        var radiovalueError = "Please check an option";
        var phonevalueError = "Please provide a 10 digit valid phone number";
        // var agevalueError = "Please provide a age between 18 and 99";
        var firstname = document.getElementById('cm-fname');
        var lastname = document.getElementById('cm-lname');
        var email = document.getElementById('cm-email');
        var speciality = document.getElementById('cm-speciality');
        var ayushRegistered = document.getElementById('ayushRegistered');
        var ayushNotRegistered = document.getElementById('ayushNotRegistered');

        // var doctor = document.getElementById('cm-doctor');
        // var patient = document.getElementById('cm-patient');

        var firstnameerror = document.getElementById('firstnameerror');
        var lastnameerror = document.getElementById('lastnameerror');
        var emailerror = document.getElementById('emailerror');
        var phoneerror = document.getElementById('phoneerror');
        var phoneNumber = document.getElementById('cm-ph');
        var specialityerror = document.getElementById('specialityerror');
        var fileerror = document.getElementById('fileerror');
        var ayushRegisterederror = document.getElementById('ayushRegisterederror');

        // var doctorerror = document.getElementById('doctorerror');
        // var patienterror = document.getElementById('patienterror');

        if (firstname.value || lastname.value || speciality.value ) {
            
            if (firstname.value.length > 0) {
                firstname.classList.remove('invalid');
                firstnameerror.style.display = "none";

                if (lastname.value.length > 0) {
                    lastname.classList.remove('invalid');
                    lastnameerror.style.display = "none";

                    if (speciality.selectedIndex !== -1 ) {
                        specialityerror.style.display = "none";
                        speciality.classList.remove("invalid");

                        if (phoneNumber.value.replace('+91', '') && phoneNumber.value.replace('+91', '').match(phoneformat)) {
                            phoneNumber.classList.remove('invalid');
                            phoneerror.style.display = "none";

                            if ((ayushRegistered.checked == true) || (ayushNotRegistered.checked == true)) {
                                ayushRegistered.classList.remove('invalid');
                                ayushNotRegistered.classList.remove('invalid');
                                ayushRegisterederror.style.display = "none";

                                if ((showUploadField && certificateId) || !showUploadField) {

                                    if(showUploadField)
                                        fileerror.style.display = "none";
                                    
                                    var obj = {
                                        first_name:'',
                                        last_name:'',
                                        email:'',
                                        contact_number:'',
                                        phone_number:'',
                                        metainfo: {},
                                        role: ''
                                        
                                    };
                                    obj.first_name = firstname.value;
                                    obj.last_name = lastname.value;
                                    obj.email = email.value;
                                    obj.contact_number = '+91' + phoneNumber.value.replace('+91', '').replace(/\D/g, "");
                                    obj.phone_number = '+91' + phoneNumber.value.replace('+91', '').replace(/\D/g, "");
                                    obj.metainfo.specialisation = speciality.value;
                                    obj.metainfo.ayush_registered = ayushRegistered.checked ? true : false;
                                    obj.metainfo.ayush_certificate_id = certificateId;
                                    obj.role = Resource.ROLES.CARE_MANAGER;
                                    
                                    console.log(obj,'obj');
                                    Resource.createUser(Resource.ROLES.CARE_MANAGER, obj).then((resp) => {
                                        if(resp.success) {
                                            if(obj.metainfo.ayush_registered){
                                            Toast.success("Care Manager created!! Upload Certificate file");
                                            setShowUploadField(true);
                                            setAssignedToID(resp.data.id);
                                            }
                                            else{
                                            Toast.success("Care Manager created!!");
                                            closeModal();
                                            }
                                            console.log(resp.data);
                                       }
                                    })
                                    // closeModal();
                                }
                                else {
                                    fileerror.style.display = "inline";
                                    fileerror.innerHTML = Error;
                                }
                            }
                            else {
                                ayushRegistered.classList.add('invalid');
                                ayushNotRegistered.classList.add('invalid');
                                ayushRegisterederror.style.display = "inline";
                                ayushRegisterederror.innerHTML = radiovalueError;
                            }
                        }
                        else {
                            phoneerror.style.display = "inline";
                            phoneNumber.classList.add("invalid");
                            phoneerror.innerHTML = phonevalueError;
                        }
                    }
                    else {
                        specialityerror.style.display = "inline";
                        speciality.classList.add("invalid");
                        specialityerror.innerHTML = namevalueError;
                    }
                }
                else {
                    lastnameerror.style.display = "inline";
                    lastname.classList.add("invalid");
                    lastnameerror.innerHTML = Error;
                }
            }
            else {
                firstnameerror.style.display = "inline";
                firstname.classList.add("invalid");
                firstnameerror.innerHTML = Error;
            }
        }
        else {
            firstnameerror.style.display = "inline";
            firstname.classList.add("invalid");
            firstnameerror.innerHTML = namevalueError;

            lastnameerror.style.display = "inline";
            lastname.classList.add("invalid");
            lastnameerror.innerHTML = namevalueError;

            specialityerror.style.display = "inline";
            speciality.classList.add("invalid");
            specialityerror.innerHTML = Error;

            phoneerror.style.display = "inline";
            phoneNumber.classList.add("invalid");
            phoneerror.innerHTML = phonevalueError;

            ayushRegistered.classList.add('invalid');
            ayushNotRegistered.classList.add('invalid');
            ayushRegisterederror.style.display = "inline";
            ayushRegisterederror.innerHTML = radiovalueError;

            fileerror.style.display = "inline";
            fileerror.innerHTML = Error;
        }     
    }
    return (
       <div className='caremanager-modal'>
           <div className="add-caremanager container">
                <div className='addCaremanagerContainer'>
                    <h1 className="title">Caremanager Form</h1>
                    <Link to="/home"><img src={closeIcon} onClick={closeModal} alt="img" className='close-btn'  /></Link>
                </div>
                
                <form className="registerForm addCaremanager-form">
                
                    <div className='form-element'>
                        <label>First Name</label>
                        <input type="text" id="cm-fname" className="inputField" placeholder="Enter care manager's first name" required />
                        <span className="field-error" id="firstnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Last Name</label>
                        <input type="text" id="cm-lname" className="inputField" placeholder="Enter care manager's last name" required />
                        <span className="field-error" id="lastnameerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>Email</label>
                        <input type="email" id="cm-email" className="inputField" placeholder="Enter care manager's email address" />
                        <span className="field-error" id="emailerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>10-digit Phone Number</label>
                        <input type="tel" id="cm-ph" className="inputField" placeholder="Enter phone number" />
                        <span className="field-error" id="phoneerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>Specialization</label>
                        <select id="cm-speciality" className="inputField" placeholder='Select specialization' required>
                            <option value="">-- Select Specialisation --</option>
                            <option value="Naturopathy">Naturopathy</option>
                            <option value="Ayurveda">Ayurveda</option>
                            <option value="Functional Medicine">Functional Medicine</option>
                            <option value="Homeopathy">Homeopathy</option>
                            <option value="Others">Others</option>
                        </select>
                        <span className="field-error" id="specialityerror"></span>
                    </div>

                    <div className='form-element'>
                        <label>AYUSH Registered ?</label>
                        <div className='radioButtonGroup' onClick={() => {document.getElementById('ayushRegistered').checked = true;}}>
                            <label for="yes">
                                <input type="radio" id="ayushRegistered" name="yes/no" value="yes" onChange={(e)=>handleRadioButton(e)}/>Yes
                            </label>
                        </div>
                        <div className='radioButtonGroup' onClick={() => {document.getElementById('ayushNotRegistered').checked = true;setShowUploadField(false);}}>
                            <label for="no">
                                <input type="radio" id="ayushNotRegistered" name="yes/no" value="no" onChange={(e)=>handleRadioButton(e)}/> No
                            </label>
                        </div>
                        <span className="field-error" id="ayushRegisterederror"></span>
                    </div>

                    {
                        showUploadField &&
                        <div className='form-element'>
                            <label>Upload Certificate</label>
                            <MedicalCertificateUpload id="cm-file" assigned_to={assignedToId} callback={certificateUpload} fileRole={Resource.ROLES.CARE_MANAGER}/>
                            <span className="field-error" id="fileerror"></span>
                        </div>
                    }
                    {/* <div className='form-element'>
                        <label>Doctor Name</label>
                        <input type="text" id="cm-doctor" className="inputField" placeholder="Enter doctor's name" required />
                        <span className="field-error" id="doctorerror"></span>
                    </div>
                    
                    <div className='form-element'>
                        <label>Patient</label>
                        <input type="text" id="cm-patient" className="inputField" placeholder="Enter patient" required />
                        <span className="field-error" id="patienterror"></span>
                    </div> */}
                    {!showUploadField &&
                    <Button text={'Add Caremanager'} buttonType={'primary_btn'} onclick={addCaremanager} ></Button>
}
                </form>
            </div>
       </div>
    );

};

export default Caremanagermodal;
import React, { useEffect,useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from './../Header/Header';
import './ProgramDetails.scss';
import ProgramModule from '../ProgramModule/PorgramModule';
import Resource from '../../services/resource.service';
import { useAuth } from '../../services/use-auth';

const ProgramDetails = () => {

    const auth = useAuth();
    let linkToUrl = '/programs';

    let location = useLocation();
    const [programData, setProgramData] = useState(location.state);
    const [modules, setModules] = useState([]);

    function fetchedCompleted(modules) {
        setModules(modules);
    }

    useEffect(()=> {
        Resource.getProgramById(programData.id).then((response) => {
            if(response.success) {
                fetchedCompleted(response.data.modules);
            }
            else {
                console.log("Error getting the list of modules");
            
            }
        });
    },[]);

    // data that contains where to go back and data need to load the page where it goes back
    const data = {
        pageData:programData,
        backTo:'/programs/programdetails'
    }

    return (
        <>
            <Header linkToUrl={linkToUrl} background={"headerBackground"} />
            <div className='program-details'>
                <div className='programDetails'>
                   <h4 className='programHeading'>Program</h4>
                   <h2 className='programTitle'>{programData.title}</h2>
                   <p className='programSubtitle'>{programData.subtitle}</p>
                   <p className='programDescription'>{programData.description}</p>
                </div>
                {
                    auth.user.role == Resource.ROLES.ADMIN &&
                    <>
                    <div className='Count'>
                        <div className='modules'>
                            <h3 className='modulesTitle'>Modules</h3>
                            <h4 className='modulesCount'>{programData.module_count}</h4>
                        </div>
                        <div className='clients'>
                            <h3 className='clientsTitle'>Clients Registered</h3>
                            <h4 className='clientsCount'>{programData.client_count || "-"}</h4>
                        </div>
                    </div>
                    <div className='modulesHeader'>
                        <h2 className='modulesHeading'>Modules</h2>
                        <Link 
                            to='/programs/addModules' 
                            state={data}
                            className="addModulesButton" >Add Modules</Link>
                    </div>
                    <div className='modules_container'>
                        {
                            modules.map((module)=>{
                                return  <ProgramModule programDetailsData={programData} key= {module.id} module={module}/>
                            })
                        }
                    
                    </div>
                    </>
                }
            </div>
        </>
    );
}
export default ProgramDetails;
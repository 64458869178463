import React from 'react';
import Button from './../../../../../../components/Button/Button';
import './DietLifestylePlans.scss';

const DietLifestylePlans = () => {

    return (
        <div className="diet_lifestyle_plans_container">
            <div className='diet_lifestyle_plans'>DietLifestylePlans</div>
            <Button text={'Upload Plan'} buttonType={'primary_btn'} />
        </div>
    )

}

export default DietLifestylePlans;
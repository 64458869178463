import { useEffect, useState } from 'react';
import './Tabcontainer.scss';

const Tabcontainer = ({setActiveTab, tabs, className}) => {
    
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    let tabClass = '';

    function updateTabChange(index) {
        setCurrentTabIndex(index);
        setActiveTab(index);
    }
    return (
        <div className="Tabcontainer">
            <ul className={className}>
                {
                    // tabs.map((tab, index)=>{
                    //     return <Tab key={index} index={index} label={tab.label} onClick={changeActiveTab} activeTab={activeTab}></Tab>
                    // })
                    tabs.map((tab, index) => {
                        tabClass = ''
                        if(currentTabIndex == index)
                            tabClass = 'active'

                        return <li key={index} index={index} className={`tab ${tabClass}`} onClick={() => updateTabChange(index)}>{tab}</li>
                    })
                }
            </ul>
           
        </div>
    );
}

export default Tabcontainer;
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import ProgramForm from '../../../components/ProgramForm/ProgramForm';
import './EditProgram.scss';

const EditProgram = () => {
    let headerTitle = "Edit Program overview";
    let linkToUrl = "/programs";
    
    let location = useLocation();
    const programData = location.state;

return(
    <>
        <Header headerTitle={headerTitle} linkToUrl={linkToUrl}  background={"headerBackground"}  />
        <ProgramForm data={programData}/>
    </>
);
}

export default EditProgram;
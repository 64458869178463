import React from 'react';

const AdminMyAccountEditForm = ({currentUser}) => {

    return (
       <>
            <div className='EditmyAccount_form registerForm'>
                <div className='form-element'>
                    <label>First Name</label>
                    <input type="text" defaultValue={currentUser.first_name} />
                </div>
                <div className='form-element'>
                    <label>Last Name</label>
                    <input type="text" defaultValue={currentUser.last_name} />
                </div>
                <div className='form-element'>
                    <label>Email</label>
                    <input type="text" defaultValue={currentUser.email} />
                </div>
                <div className='form-element'>
                    <label>Username</label>
                    <input type="text" defaultValue={currentUser.username} />
                </div>
            </div>
        </>
    )
}

export default AdminMyAccountEditForm;
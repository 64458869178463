import React from 'react';
import './InformationSidebarContent.scss';
import Documents from './components/Documents/Documents';
import DietLifestylePlans from './components/DietLifestylePlans/DietLifestylePlans';
import DietAndLifestyleHabits from './components/DietAndLifestyleHabits/DietAndLifestyleHabits'
import Journal from './components/Journal/Journal'
import MeasurementsAndVitals from './components/MeasurementsAndVitals/MeasurementsAndVitals'
import MedicalHistory from './components/MedicalHistory/MedicalHistory'
import Tasks from './components/Tasks/Tasks'

const InformationSidebarContent = ({activeLinkContent}) => {

    let data = activeLinkContent;

    function SwitchCase(data) {
        switch(data) {
            case 'Documents':
              return <Documents />;
            case 'Diet & Lifestyle Plans':
              return <DietLifestylePlans />;
            case 'Diet & Lifestyle Habits':
              return <DietAndLifestyleHabits />;
            case 'Journal':
              return <Journal />;
            case 'Measurements & Vitals':
              return <MeasurementsAndVitals />;
            case 'Medical History':
              return <MedicalHistory />;
            case 'Tasks':
              return <Tasks />;
        }
    }

    return (
        <div className='information_sidebar_content'>
            {
               SwitchCase(data)
            }
        </div>
    )

}

export default InformationSidebarContent;
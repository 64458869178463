import React, {useEffect, useState, createContext} from "react";
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import logo from './../../images/md+.png';
import OtpInputCard from "./components/OtpInputCard";
import ResendOTP from './components/ResendOTP';
import './GetOtp.scss';

import { ProvideAuth, useAuth } from './../../services/use-auth';
import PrivateRoute from './../../helpers/PrivateRoute';
import authenticationService from './../../services/authentication.service';
import Resource from "../../services/resource.service";

export const handleContext = createContext();


const GetOtp = (props) => {

  const [sessionId, setSessionId] = useState(props.sessionId);
  const auth = useAuth();
  const navigate = useNavigate();

  const verifyOtp = ()=> {
    var input = document.getElementsByTagName('input');
    var otp = '';
    for (let i = 0; i < input.length; i++) {
      otp += input[i].value;
    }
    
    auth.signinUsingOtp(props.phoneNumber, otp, sessionId).then(user => {
      if(user.role) {
        if([Resource.ROLES.DOCTOR, Resource.ROLES.ADMIN, Resource.ROLES.CARE_MANAGER, Resource.ROLES.ASSISTANT].includes(user.role)) {
          console.log('User role: ', user.role);
          if(user.role == Resource.ROLES.DOCTOR && user.metainfo.practice_id && user.active) {
            navigate('/home', {'replace': true});
          }
          else if(user.role == Resource.ROLES.ASSISTANT && user.doctors && user.doctors.length > 0 && user.active) {
            navigate('/home', {'replace': true});
          }
          else if(user.role == Resource.ROLES.CARE_MANAGER && user.active) {
            navigate('/home', {'replace': true});
          }
          else if(user.role == Resource.ROLES.ADMIN && user.active) {
            navigate('/home', {'replace': true});
          }
          else {
            navigate('/register', {'replace': true});
          }
        }
        else {
          navigate('/select-role', {'replace': true});
        }
      }
      else {
        navigate('/select-role', {'replace': true});
      }
      
    });
  }; 

  const handleKeyDown = () => {
    verifyOtp();
  }

  return (
    <div className="get_otp">
      <div className="logo">
        <img className='logo_image' src={logo} alt="logoImage"></img>
      </div>
    <handleContext.Provider value={handleKeyDown}>
      <OtpInputCard
        autoFocus
        otplength={4}
        otpType="number"
        disabled={false}
        resendOTP={{}}
      />
    </handleContext.Provider>
      <ResendOTP className='otp_timer_and_resend_container' handelResendClick={() => requestOTP()} />

      <button className="login-btn" onClick={verifyOtp}>VERIFY OTP</button>
      <div className="back_btn">
        <Link onClick={() => window.location.reload()} to='/' className="link">‹ Back</Link>
      </div>
    </div>
  );

  function requestOTP() {
    let phone = props.phoneNumber;
    let role = 'doctor';
    if(phone.length < 13) {
        alert("Error: Invalid phone number");
    }
    else {
        authenticationService.generateOTP(phone, role).then(data => {
            console.log(data);
            setSessionId(data.otp_session_id);
            //setCounter(59);
        });
    }
  }
}

export default GetOtp;
import React from 'react';
import { beautifyPhoneNumber } from '../../../../helpers/utils';

const AssistantMyAccountForm = ({currentUser}) => {
    
    let clinicData = {};
    for(let i = 0; i < currentUser.clinics.length; i++)
        if(! (currentUser.clinics[i].id in clinicData) )
            clinicData[currentUser.clinics[i].id] = currentUser.clinics[i];

    return (
        <>
            <div className='myAccount_name'>
                <div className='first_name'>
                    <span>First Name</span>
                    <h3>{currentUser.first_name}</h3>
                </div>
                <div className='last_name'>
                    <span>Last Name</span>
                    <h3>{currentUser.last_name}</h3>
                </div>
            </div>
            <div className='clinic_name'>
                <span>Clinic Name</span>
                {
                    Object.keys(clinicData).map((clinic_id)=>{
                        return  <h3 key={clinic_id}>{clinicData[clinic_id].name}</h3>
                    })
                }
            </div>
            <div className='phone_number'>
                <span>Contact Number</span>
                <h3>{beautifyPhoneNumber(currentUser.contact_number)}</h3>
            </div>
            <div className='phone_number'>
                <span>Username</span>
                <h3>{currentUser.username}</h3>
            </div>
        </>
    )
}

export default AssistantMyAccountForm;
import Resource from '../../services/resource.service';
import {QRCode} from 'react-qrcode-logo';
import mlogo from '../../images/mlogo.png';
import './ClinicQRCode.scss';
import { useState } from 'react';

const ClinicQRCode = ({ clinics, clinicId }) => {
    const [qrSize, setQrSize] = useState(200);

  const handleIncreaseSize = () => {
    setQrSize((prevSize) => (prevSize * 2 <= 1000 ? prevSize * 2 : 1000));
  };

  const handleDecreaseSize = () => {
    setQrSize((prevSize) => (prevSize / 2 >= 200 ? prevSize / 2 : 200));
  };

  return (
    <div className='clinicQRCode'>
      <QRCode
        value={Resource.PATIENT_QR_HOST + '/register-patient-' + clinics[clinicId].code}
        size={qrSize}
        fgColor='#e63f61'
        eyeColor='#007050'
        logoImage={mlogo}
        removeQrCodeBehindLogo={true}
        qrStyle='square'
        logoPaddingStyle='circle'
        eyeRadius={5}
      />
      <div className='qrControls'>
        <button className='zoomIn' onClick={handleIncreaseSize}>+</button>
        <button className='zoomOut' onClick={handleDecreaseSize}>-</button>
      </div>
    </div>
  );
};
  
export default ClinicQRCode;
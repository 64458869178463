import React from "react";
import useResendOTP from "./Hooks/useResendOTP";
import './../GetOtp.scss';

const ResendOTP = ({ renderTime, renderButton, style, className, ...props }) => {
  const { remainingTime, handelResendClick } = useResendOTP(props);
  return (
    <div id=""
      className={className || ""}
      data-testid="otp-resend-root"
      style={{
        display: "flex",
        justifyContent: "space-between",
        ...style
      }}
    >
      {renderTime ? (
        renderTime(remainingTime)
      ) : (
        <span className="otp_timer">0.{remainingTime}s</span>
      )}
      {renderButton ? (
        renderButton({
          disabled: remainingTime !== 0,
          onClick: handelResendClick,
          remainingTime
        })
      ) : (
        <button className="reSend-btn" disabled={remainingTime !== 0} onClick={handelResendClick} type="button">
          Resend OTP
        </button>
      )}
    </div>
  );
}

ResendOTP.defaultProps = {
  maxTime: 60,
  timeInterval: 1000,
  style: {}
};

export default ResendOTP;
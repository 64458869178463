import React,{useEffect} from 'react';
import { Routes, Navigate } from 'react-router-dom';
import { useAuth } from '../services/use-auth';

const PrivateRoute = ({children}) => {
    let auth = useAuth();
    
    return auth && auth.user ? (
      children
    ) : (
      <Navigate to="/" replace={true} />
    );
}

export default PrivateRoute;

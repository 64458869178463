import React from 'react';
import { useState, useEffect } from 'react';
import Modal from "../../../Modal/Modal";
import Button from '../../../Button/Button';
import editIcon from './../../../../images/edit.png';
import EditPatientForm from './../../EditPatientForm/EditPatientForm';
import './PatientTabRow.scss';
import { beautifyPhoneNumber } from '../../../../helpers/utils';
import Resource from '../../../../services/resource.service';
import PrognosisReport from '../../../PrognosisReport/PrognosisReport';

const PatientTabRow = ({ patientsData }) => {

    console.log(patientsData);
    const [showPaymentPopup, setShowPaymentPopup] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState("Cash");
    const [showEditForm, setShowEditForm] = useState(false);

    const openPaymentOptions = () => {
        setShowPaymentPopup(true);
    }

    const closeModal = () => {
        let Error = "This field cannot be empty";
        let feesField = document.getElementById('fee-collected');
        let feesError = document.getElementById('feeserror');
        if (feesField.value !== '') {
            feesField.classList.remove('invalid');
            feesError.style.display = "none";
            setShowPaymentPopup(false);
        }
        else {
            feesField.classList.add('invalid');
            feesError.style.display = "inline";
            feesError.innerHTML = Error;
        }
        //setShowPaymentPopup(false);
        setShowEditForm(false);
    }

    const handleRadioButton = (e) => {
        let currentValue = e.target.value;
        setSelectedPaymentOption(currentValue);
    }

    const setDimension = () => {
        setScreenSize(window.innerWidth);
    }

    const openEditForm = () => {
        setShowEditForm(true);
    }

    const fetchPrognosisReport = () => {
        return Resource.fetchPrognosisReport(patientsData.clinic_qms_id);
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);
    
    return (
        <div className='patientHomeTab'>
            <div className="patientTabRow" onClick={openPaymentOptions}>
                <div className='patientRowDetails'>
                    <div className="patientRowDetailsTop">
                        <h4 className='patientToken'>Token #{patientsData.token_number}</h4>
                        <div className='date'>{new Date(patientsData.date).toDateString()}</div>
                        <div onClick={openEditForm} className='editImage'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1c1e1f" fill="none" strokeLinecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                <line x1="16" y1="5" x2="19" y2="8" />
                            </svg> 
                        </div>
                    </div>
                    <div className="patientDetailsInfo">
                        <div className='Detail'>
                            <h5 className='title'>Name</h5>
                            <h2 className='value'>{patientsData.patient_name}<span> ({patientsData.patient_gender} / {patientsData.patient_age}y)</span></h2>
                        </div>
                        <div className='Detail'>
                            <h5 className='title'>Doctor</h5>
                            <h2 className='value'>{patientsData.doctor_name || '-'}</h2>
                        </div>
                        <div className='Detail'>
                            <h5 className='title'>Phone</h5>
                            <h2 className='value'>{beautifyPhoneNumber(patientsData.patient_phone) || '-'}</h2>
                        </div>
                        <div className='Detail'>
                            <h5 className='title'>Fees</h5>
                            <h2 className='value'>{patientsData.consultation_fee ? '₹' + patientsData.consultation_fee : '-'}</h2>
                        </div>
                        {
                            patientsData.is_prognosis_report_ready ? 
                            <div>
                                <PrognosisReport htmlData='' fetchHtmlData={fetchPrognosisReport}/>
                            </div> :  ''
                        }
                        {/* <h1 className='patientName'>{patientsData.patient_name}<span> ({patientsData.patient_gender} - {patientsData.patient_age}y)</span></h1>
                        <h5 className='doctorName'>{patientsData.doctor_name || '-'}</h5>
                        <h5 className='patientPhone'>{beautifyPhoneNumber(patientsData.patient_phone) || '-'}</h5> */}
                    </div>
                </div>
                {
                    showEditForm ? (
                        <Modal
                            closeModal={closeModal}
                            {...(screenSize <= 480
                                ? { mobileModal: true }
                                : { right: "right" })}
                            >
                            <div className="addPatientForm">
                                <EditPatientForm closeModal={closeModal} patientsData={patientsData} />
                            </div>
                        </Modal>
                    ) : (
                    ""
                    )
                }       
            </div>
            {/* {
                showPaymentPopup && 
                <Modal {...(screenSize <= 500 && {position:'bottom'})} closeModal={closeModal}>
                    <div className='paymentOptionContainer'>
                        <div className='paymentOptions'>
                            <h4 className="paymentOptionTitle">Select Payment Option</h4>
                            <div className='radioButtonGroup'>
                                <label>
                                    <input type="radio" id="cash" name="paymentOption" value="cash" onChange={(e)=>handleRadioButton(e)} checked={selectedPaymentOption === "cash"} />Cash
                                </label>
                            </div>
                            <div className='radioButtonGroup'>
                                <label>
                                    <input type="radio" id="paymentLink" name="paymentOption" value="paymentLink" onChange={(e)=>handleRadioButton(e)} checked={selectedPaymentOption === "paymentLink"} />UPI
                                </label>
                            </div>
                        </div>
                        <div className='form-element'>
                            <label>Fee Collected</label>
                            <input type="text" id="fee-collected" className="inputField"  required />
                            <span className="field-error" id="feeserror"></span>
                        </div>
                        <div className='submit_btn'>
                           <Button buttonType={'primary_btn'} text={'Submit'} onclick={closeModal}/>
                        </div>
                    </div>
                </Modal>
            } */}
        </div>
    )

}

export default PatientTabRow;
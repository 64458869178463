import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import binIcon from './../../../images/bin.png';
import maximizeIcon from './../../../images/maximaize.png';
import './TextEditor.scss';

const TextEditor = ({handleRemove, index}) => {
    const [value, setValue] = useState("");

    let modules = {
        toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
        ],
        [{ align: "center" }, { align: "right" }],
        ["clean"]
        ]
    };

    let formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "align",
    ];

    function showValue(value) {
        setValue(value);
        console.log(value, "value");
    }

    function showFullScreen() {
        console.log("clicked");
    }

    return (
        <div className="textEditorContainer" id={index}>
            <div className="toolBar">
                <svg className="maximize_btn" onClick={showFullScreen} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 4H20V8" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    <path d="M14 10L20 4" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    <path d="M8 20H4V16" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    <path d="M4 20L10 14" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                </svg>
                <svg className="delete_btn" onClick={() => {handleRemove(index)}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 7H20" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    <path d="M10 11V17" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    <path d="M14 11V17" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    <path d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                    <path d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7" stroke="#1C1E1F" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <ReactQuill  theme="snow"  onChange={showValue} modules={modules} formats={formats} />
        </div>
    )
}

export default TextEditor;
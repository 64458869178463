import React from 'react';
import './MedicalHistory.scss';

const MedicalHistory = () => {

    return (
        <div className="medical_history_Container">
            <div className='MedicalHistory'>MedicalHistory</div>
        </div>
    )

}

export default MedicalHistory;